import React, { useEffect, useState, useRef, useCallback } from "react";
import { TTestSetupProps } from "./TestSetup.types";
import CodeEditor from "../../common/CodeEditor/CodeEditor";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import TabView from "../../common/TabView/TabView";
import DataTable from "react-data-table-component";
import { toast } from "react-toastify";
import playIcon from "../../../../assets/images/play-white.svg";
import zipIconWhite from "../../../../assets/images/zip.svg";
import downloadIconGrey from "../../../../assets/images/download_grey.svg";
import playBordIcon from "../../../../assets/images/play-bordered.svg";
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import deleteIconWhite from "../../../../assets/images/trash-white.svg";
import saveIcon from "../../../../assets/images/save.svg";
import folder from "../../../../assets/images/folder.svg";
import folderOpen from "../../../../assets/images/folder-open.svg";
import DeleteModal from "../../common/DeleteModal/DeleteModal";

import LaunchingModal from "../../session/Setup/LaunchingModal/LaunchingModal";

import testsApi from "../../../../services/tests";
import dataSourcesApi from "../../../../services/data-sources";
import useApi from "../../../../services/Base/useApi";

import { useNavigate, useSearchParams } from "react-router-dom";

const TestSetup = (props: TTestSetupProps) => {
  //=====================================
  //STATE
  //======================================
  const [deleteModal, setDeleteModal] = useState(false);
  const [launchingModal, setLaunchingModal] = useState(false);
  const [testId, setTestId] = useState("");
  const [setupTests, setSetupTests] = useState([]);
  const [testData, setTestData] = useState<any>({});
  const [loading, setLoading] = useState(true);
  const [dataOptions, setDataOptions] = useState({
    FALSE_ALARM: [],
    HIT_RATE: [],
    WEIGHTS: [],
    DETECTIONS: [],
    ISDK: [],
  });
  const [weights, setWeights] = useState([]);
  const paths = useRef({
    hitRateDataPath: "",
    falseAlarmDataPath: "",
  });
  const [defaultIni, setDefaultIni] = useState("");
  const [iniFiles, setIniFiles] = useState<Array<string>>([]);

  //=====================================
  //HOOKS
  //======================================
  const getTestDataApi = useApi(testsApi.getTest);
  const getSetupTestsApi = useApi(testsApi.getSetupTests);
  const getDataOptionsApi = useApi(dataSourcesApi.getDataSource);
  const saveTestApi = useApi(testsApi.putSaveTest);
  const deleteTestApi = useApi(testsApi.deleteTest);
  const getWeightsApi = useApi(dataSourcesApi.getWeights);
  const getIniFileApi = useApi(dataSourcesApi.getIniFile);
  const startTestApi = useApi(testsApi.postStartTest);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (testId) {
      setLoading(true);
      getTestDataApi.request(testId);
    }
  }, [testId]);

  useEffect(() => {
    getSetupTestsApi.request();
  }, []);

  useEffect(() => {
    //set data results
    if (getTestDataApi.error) {
      toast.error("Test not found.");
      navigate("/admin/test/overview");
    } else if (
      getTestDataApi.loading === false &&
      !getTestDataApi.error &&
      getTestDataApi.data
    ) {
      setData(getTestDataApi.data);
      getDataOptionsApi.request(fetchAwsDirectoryPaths(getTestDataApi.data["mode"]))
      // paths.current={
      //   hitRateDataPath:getTestDataApi['data']['hitRateDataPath'],
      //   falseAlarmDataPath:getTestDataApi['data']['falseAlarmDataPath']
      // }
      setLaunchingModal(getTestDataApi.data["status"] === 1 ? true : false);
      setLoading(false);
    }
  }, [getTestDataApi.loading, getTestDataApi.error, getTestDataApi.data]);

  useEffect(() => {
    //set setup sessions dropdown options
    if (
      getSetupTestsApi.loading === false &&
      !getSetupTestsApi.error &&
      getSetupTestsApi.data
    ) {
      setSetupTests(getSetupTestsApi.data);
      if (searchParams.get("id")) {
        //@ts-ignore
        setTestId(searchParams.get("id"));
      } else if (getSetupTestsApi.data[0]) {
        setTestId(getSetupTestsApi.data[0]["_id"]);
        navigate(`/admin/test/setup?id=${getSetupTestsApi.data[0]["_id"]}`);
      } else {
        setLoading(false);
      }
    }
  }, [getSetupTestsApi.loading, getSetupTestsApi.error, getSetupTestsApi.data]);

  useEffect(() => {
    //set data options
    if (
      getDataOptionsApi.loading === false &&
      !getDataOptionsApi.error &&
      getDataOptionsApi.data
    ) {
      setDataOptions(getDataOptionsApi.data);
    }
  }, [
    getDataOptionsApi.loading,
    getDataOptionsApi.error,
    getDataOptionsApi.data,
  ]);

  useEffect(() => {
    //handle saved session
    if (saveTestApi.loading === false) {
      if (saveTestApi.error) {
        toast.error(saveTestApi.error);
        saveTestApi.clearError();
      } else {
        toast.success("Test saved");
      }
    }
  }, [saveTestApi.loading, saveTestApi.error, saveTestApi.data]);

  useEffect(() => {
    if (deleteTestApi.loading === false) {
      if (deleteTestApi.error) {
        toast.error(deleteTestApi.error);
        deleteTestApi.clearError();
      } else {
        toast.success("Test deleted");
        navigate("/admin/test/overview");
      }
    }
  }, [deleteTestApi.loading, deleteTestApi.error, deleteTestApi.data]);

  useEffect(() => {
    if (
      getWeightsApi.loading === false &&
      !getWeightsApi.error &&
      getWeightsApi.data
    ) {
      setWeights(getWeightsApi.data);
    }
  }, [getWeightsApi.loading, getWeightsApi.error, getWeightsApi.data]);


  useEffect(() => {
    if (
      getIniFileApi.loading === false &&
      !getIniFileApi.error &&
      getIniFileApi.data
    ) {
      setDefaultIni(getIniFileApi.data);
      setIniFiles([getIniFileApi.data])
    }
  }, [getIniFileApi.loading, getIniFileApi.error, getIniFileApi.data]);

  useEffect(() => {
    //handle after start training
    if (startTestApi.loading === false) {
      if (startTestApi.error) {
        toast.error(
          "There was an error launching your test. Check instance limit."
        );
        setTestData({ ...testData, status: 0 });
        startTestApi.clearError();
      } else {
        if (startTestApi["data"] && startTestApi["data"]["message"]) {
          toast.success(startTestApi["data"]["message"]);
        }
        navigate("/admin/test/overview");
      }
    }
  }, [startTestApi.loading, startTestApi.error, startTestApi.data]);

  //=====================================
  //HANDLERS
  //======================================
  const fetchAwsDirectoryPaths = (mode: string): string =>{
    switch(mode){
      case 'Sensors':
        return 'aws_s3_directories_test_sensors'
      case 'Denoiser':
        return 'aws_s3_directories_test_denoiser'
      default:
        return 'aws_s3_directories_test'
    }
  }
  const setData = (data: any) => {
    
    let isdkConfig: any = { };

    if (data.mode !== "Denoiser"){
      isdkConfig = { ...data.isdkConfig };
      delete isdkConfig.aggressiveness;
    }else{
      isdkConfig = { "aggressiveness": data.isdkConfig.aggressiveness}
    }
  
    setTestData({ ...data, isdkConfig });
  };

  // save session
  const saveTest = () => {
    const names = extractNames(testData.weightsFilePaths);
    const { hitRateDataPath, falseAlarmDataPath } = paths.current;
    const data = {
      ...testData,
      weightsFilePaths: names,
      hitRateDataPath,
      falseAlarmDataPath,
    };
    setTestData(data);
    saveTestApi.request(testId, data);
  };
  // delete session
  const deleteTest = () => {
    deleteTestApi.request(testId);
  };

  //get weights
  const getWeights = (folderName: string) => {
    getWeightsApi.request(folderName, testData.mode);
    getIniFileApi.request(folderName, testData.mode);
  };


  //start test
  const startTest = () => {
    if (testData.weightsFilePaths.length > 24) {
      toast.error("Up to 24 weight files are allowed.");
    } else {
      const names = extractNames(testData.weightsFilePaths);
      startTestApi.request(testId, { ...testData, weightsFilePaths: names, iniFiles });
      setTestData({ ...testData, status: 1 });
      setLaunchingModal(true);
    }
  };

  const extractNames = (data: Array<any>): Array<string> => {
    let names: Array<string> = [];
    data.map((item: any) => names.push(item.name));
    return names;
  };

  const removeIniFIle = (index: number) => {
    if(iniFiles.length > 1){
      setIniFiles(prevIniFiles => prevIniFiles.filter((_, idx) => idx !== index));
    }else{
      toast.error("You must have at least 1 ini file.")
    }
  }

  // //handle input change
  // const handleOnSelectedRowsChange = (selectedRows:Array<any> ,path: string) => {
  //   if(selectedRows.length > 0){
  //     //@ts-ignore
  //     paths.current[path] = selectedRows[0].name
  //   }else{
  //     //@ts-ignore
  //     paths.current[path] = ""
  //   }
  // }

  const handleOnSelectedRowsChange = (
    selectedRows: Array<any>,
    path: string
  ) => {
    if (selectedRows.length > 0) {
      setTestData({ ...testData, [path]: selectedRows[0].name });
    } else {
      setTestData({ ...testData, [path]: "" });
    }
  };

  const testSetupWeightDT = () => {
    const columns = [
      {
        name: "",
        button: true,
        cell: (row: any) => (
          <img
            onClick={(e) => {
              getWeights(row.name);
              setTestData({ ...testData, weightsDataPath: row.name });
            }}
            src={folder}
            className="tblZipIcon"
            alt="icon"
          />
        ),
      },
      {
        name: "Name",
        selector: (row: any) => row.name,
      },
      {
        name: "Last Modified",
        selector: (row: any) => row.modified,
      },
    ];
    return (
      <DataTable
        columns={columns}
        data={dataOptions.WEIGHTS}
        customStyles={DTStyles}
      />
    );
  };

  const testSetupWeightListDT = () => {
    const columns = [
      {
        name: "Name",
        selector: (row: any) => row.name,
      },
      {
        name: "Last Modified",
        selector: (row: any) => row.modified,
      },
    ];
    return (
      <div>
        <button
          onClick={() => {
            setWeights([]);
            setDefaultIni("");
            setIniFiles([""]);
            setTestData({
              ...testData,
              weightsDataPath: "",
              weightsFilePaths: [],
            });
          }}
          className="btnRed btnCustom btnSmall"
        >
          Clear
        </button>
        <h5 style={{ marginBottom: 3, marginTop: 7 }}>
          {testData.weightsDataPath}
        </h5>
        <p style={{ marginTop: 0, marginBottom: 0 }}>
          {
            testData.mode == "Denoiser"
              ? "Select 1 bin file"
              : "Select up to 24 bin files"
          }
        </p>
        <p style={{ marginTop: 0, fontWeight: 700 }}>
          {testData.weightsFilePaths.length} bins selected
        </p>
        <DataTable
          columns={columns}
          data={weights}
          customStyles={DTStyles}
          selectableRows={true}
          selectableRowsSingle={ testData.mode === "Denoiser" ? true : false }
          selectableRowsHighlight
          onSelectedRowsChange={({ selectedRows }: { selectedRows: any }) =>
            setTestData({ ...testData, weightsFilePaths: selectedRows })
          }
        />
      </div>
    );
  };

  //render table
  const testSetupTableDT = (data: Array<string>, path: string) => {
    //columns for tables
    const columnsForTable = [
      {
        name: "Name",
        selector: (row: any) => row.name,
      },
      {
        name: "Last Modified",
        selector: (row: any) => row.modified,
      },
    ];

    //pre select rows
    //const rowSelectCritera = (row:any) => row.name === testData[path];

    return (
      <div>
        <DataTable
          columns={columnsForTable}
          data={data}
          customStyles={DTStyles}
          selectableRows={true}
          selectableRowsSingle
          selectableRowsHighlight
          onSelectedRowsChange={({ selectedRows }) =>
            handleOnSelectedRowsChange(selectedRows, path)
          }
        />
      </div>
    );
  };

  const renderTabView = () => {
    let headers: any = [];
    let body: any = [];

    if (testData.type == "detection") {
      headers = [
        ...headers,
        { title: "Detections" },
        { title: "Weights" },
        testData.mode != "Denoiser" ? { title: "Ini Files" } : null,
        { title: "Configuration" },
      ].filter(item => item !== null);
      
      body = [
        <div>
          {testSetupTableDT(dataOptions.DETECTIONS, "detectionsDataPath")}
        </div>,
        <div>
          {weights.length > 1 ? testSetupWeightListDT() : testSetupWeightDT()}
        </div>,
        <div>
        {
          weights.length > 1 
            ? <div>
              <div className="m-b-20">
                <button
                  onClick={() => setIniFiles([...iniFiles, defaultIni])}
                  className="btnBlack btnCustom btnSmall"
                >+ Add Ini File</button>
              </div>
              
                {
                  iniFiles.map((iniFile: string, index: number) => (
                    <div className="m-b-20">
                      <div className="sectionHead" style={{marginBottom:0}}>
                        <div className="flex-align-center">
                          <p className="font-bold">INI FILE #{index+1}</p>
                        </div>
                        <div className="btnHolder flex-align-center">
                        <button
                          onClick={() => removeIniFIle(index)}
                          className="btnRed btnCustom btnSmall"
                        >Delete Ini File</button>
                        </div>
                      </div>
                      
                      <textarea 
                      style={{width:'100%', padding:10, lineHeight:1.5, borderRadius:'5', border: '1px solid #f3f3f3', outline: 'none'}} 
                      rows={10} value={iniFile} 
                      //@ts-ignore
                      onChange={(e: any) => setIniFiles(iniFiles.map((file: string, idx: number) => idx === index ? e.target.value : file))}/>
                    </div>
                    
                  ))
                } 
              </div>
            : <div> You need to select your weights first.</div>
        }
      </div>,
        <div>
          <h4>Version : </h4>
          <select
            onChange={(e) => {
              setTestData({ ...testData, isdkDataPath: e.target.value });
            }}
            className="customSelect m-l-10"
            name="sessionName"
            id=""
          >
            {dataOptions.ISDK.map((isdk: { name: string }) => (
              <option
                key={isdk.name}
                value={isdk.name}
                selected={isdk.name === testData.isdkDataPath}
              >
                {isdk.name}
              </option>
            ))}
          </select>
          <h4>Avanced Options : </h4>
          <CodeEditor
            onCodeChange={(newCode: string) =>
              setTestData({ ...testData, isdkConfig: JSON.parse(newCode) })
            }
            code={JSON.stringify(testData.isdkConfig)}
          />
        </div>,
      ];
      if (testData.mode == "Denoiser") body.splice(2,1);
    } else if (testData.type == "roc") {
      headers = [
        ...headers,
        { title: "Hit Rate" },
        { title: "False Alarm" },
        { title: "Weights" },
        { title: "Ini Files" },
        { title: "Configuration" },
      ];
      body = [
        <div>{testSetupTableDT(dataOptions.HIT_RATE, "hitRateDataPath")}</div>,
        <div>
          {testSetupTableDT(dataOptions.FALSE_ALARM, "falseAlarmDataPath")}
        </div>,
        <div>
          {weights.length > 1 ? testSetupWeightListDT() : testSetupWeightDT()}
        </div>,
        <div>
          {
            weights.length > 1 
              ? <div>
                
                
                  {
                    iniFiles.map((iniFile: string, index: number) => (
                      <div className="m-b-20">
                        <div className="sectionHead" style={{marginBottom:0}}>
                          <div className="flex-align-center">
                            <p className="font-bold">INI FILE #{index+1}</p>
                          </div>
                          <div className="btnHolder flex-align-center">
                         
                          </div>
                        </div>
                        
                        <textarea 
                        style={{width:'100%', padding:10, lineHeight:1.5, borderRadius:'5', border: '1px solid #f3f3f3', outline: 'none'}} 
                        rows={10} value={iniFile} 
                        //@ts-ignore
                        onChange={(e: any) => setIniFiles(iniFiles.map((file: string, idx: number) => idx === index ? e.target.value : file))}/>
                      </div>
                      
                    ))
                  } 
                </div>
              : <div> You need to select your weights first.</div>
          }
        </div>,
        <div>
          <h4>Version : </h4>
          <select
            onChange={(e) => {
              setTestData({ ...testData, isdkDataPath: e.target.value });
            }}
            className="customSelect m-l-10"
            name="sessionName"
            id=""
          >
            {dataOptions.ISDK.map((isdk: { name: string }) => (
              <option
                key={isdk.name}
                value={isdk.name}
                selected={isdk.name === testData.isdkDataPath}
              >
                {isdk.name}
              </option>
            ))}
          </select>
          <h4>Avanced Options : </h4>
          <CodeEditor
            onCodeChange={(newCode: string) =>
              setTestData({ ...testData, isdkConfig: JSON.parse(newCode) })
            }
            code={JSON.stringify(testData.isdkConfig)}
          />
        </div>,
      ];
    }

    if (!testData.type) {
      return (
        <div>
          <p style={{ textAlign: "center", fontWeight: 600 }}>
            Select which type of Testing you would like to run.
          </p>
          <div className="customColumn-12 text-center">
            <button
              onClick={() => setTestData({ ...testData, type: "detection" })}
              className="btnBlack btnCustom modalButton"
            >
              Detections
            </button>
            <button
              onClick={() => setTestData({ ...testData, type: "roc" })}
              className="btnYellow btnCustom modalButton"
            >
              ROC
            </button>
          </div>
        </div>
      );
    } else {
      return <TabView footer={false} header={headers} body={body} />;
    }
  };

  const startClassName = () => {
    const {
      type,
      hitRateDataPath,
      falseAlarmDataPath,
      detectionsDataPath,
      weightsFilePaths,
    } = testData;
    if (type === "detection") {
      return detectionsDataPath && weightsFilePaths.length > 0
        ? "btnCustom btnGreen"
        : "btnCustom btnDisabled";
    } else {
      return hitRateDataPath &&
        falseAlarmDataPath &&
        weightsFilePaths.length > 0
        ? "btnCustom btnGreen"
        : "btnCustom btnDisabled";
    }
  };

  if (testId) {
    return (
      <div>
        <div className="sectionHead">
          <div className="flex-align-center">
            <h2 className="sectionHeading">Test : </h2>
            <select
              onChange={(e) => {
                setTestId(e.target.value);
                navigate(`/admin/test/setup?id=${e.target.value}`);
              }}
              className="customSelect m-l-10"
              name="sessionName"
              id=""
            >
              {setupTests.map((test: { _id: string; name: string }) => (
                <option
                  key={test._id}
                  value={test._id}
                  selected={test._id === testId}
                >
                  {test.name}
                </option>
              ))}
            </select>
          </div>
          <div className="btnHolder flex-align-center">
            {/*
                        <a onClick={saveTest} className="btnCustom btnBordered">
                            <img src={saveIcon} alt="save" />
                            Save
                        </a>
                        */}
            {testData.status !== 1 && (
              <a
                onClick={() => setDeleteModal(true)}
                className="btnCustom btnRed"
              >
                <img src={deleteIconWhite} alt="delete" />
                Delete
              </a>
            )}
          </div>
        </div>
        {!loading ? (
          testData.status == 0 ? (
            <div className="globalCard">
              {renderTabView()}
              {testData.type && (
                <div className="flex-justify-end">
                  <div className="flex-align-center">
                    <div className="btnHolder flex-align-center">
                      <a onClick={startTest} className={startClassName()}>
                        {" "}
                        <img src={playIcon} alt="play" />
                        Start
                      </a>
                    </div>
                  </div>
                </div>
              )}
              {deleteModal && (
                <DeleteModal
                  title="Test"
                  subtitle="Are you sure you want to delete this test? Doing this will permenatly destroy your progress."
                  onCancel={() => setDeleteModal(false)}
                  onDelete={deleteTest}
                />
              )}
            </div>
          ) : (
            <LaunchingModal
              title={"Test Launching"}
              subtitle={
                "Your test is currently launching, this will take a few minutes."
              }
            />
          )
        ) : (
          <div className="loading">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    );
  } else if (!loading) {
    return (
      <div className="no-items">
        <h2>No Tests in Setup</h2>
        <p>Tests that are in setup will appear here.</p>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default TestSetup;
