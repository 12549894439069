import React, { useEffect, useState } from "react";
import { TTestReviewProps } from "./TestReview.types";
import CodeEditor from "../../common/CodeEditor/CodeEditor";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import TabView from "../../common/TabView/TabView";
import checkedBordIcon from "../../../../assets/images/checked-green-bordered.svg";
import crossIcon from "../../../../assets/images/cross-bordered-red.svg";
import downloadIcon from "../../../../assets/images/download-white.svg";
import clockIcon from "../../../../assets/images/clock-white.svg";
import deleteIconWhite from "../../../../assets/images/trash-white.svg";
import DataTable from "react-data-table-component";
import zipIconWhite from "../../../../assets/images/zip.svg";
import downloadIconGrey from "../../../../assets/images/download_grey.svg";
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import ConfusionMatrix from "../../common/ConfusionMatrix/ConfusionMatrix";
import { toast } from "react-toastify";
import DeleteModal from "../../common/DeleteModal/DeleteModal";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slideNext from "../../../../assets/images/slide-next.svg";
import slidePrev from "../../../../assets/images/slick-prev.svg";

import testsApi from "../../../../services/tests";
import useApi from "../../../../services/Base/useApi";

import { useNavigate, useSearchParams } from "react-router-dom";

import RocCurves from "../../session/RocCurves/RocCurves";

import { millisToTimeString, getDuration } from "../../../../utils/helpers";

const TestReview = (props: TTestReviewProps) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  //===================================
  //STATE
  //===================================
  const [deleteModal, setDeleteModal] = useState(false);
  const [testId, setTestId] = useState("");
  const [loading, setLoading] = useState(true);
  const [reviewTests, setReviewTests] = useState([]);
  const [testData, setTestData] = useState<any>({});
  const [rocHtml, setRocHtml] = useState("");
  const [isdkOutput, setIsdkOutput] = useState("");
  const [rocData, setRocData] = useState([]);
  const [rocMax, setRocMax] = useState([]);
  const [confusionMatix, setConfusionMatrix] = useState([]);
  const [detections, setDetections] = useState("");

  //=====================================
  //HOOKS
  //======================================
  const getTestDataApi = useApi(testsApi.getReviewTest);
  const getReviewTestsApi = useApi(testsApi.getReviewTests);
  const deleteTestApi = useApi(testsApi.deleteReviewTest);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (testId) {
      setLoading(true);
      initilize();
      getTestDataApi.request(testId);
    }
  }, [testId]);

  useEffect(() => {
    getReviewTestsApi.request();
  }, []);

  useEffect(() => {
    //set data results
    const { loading, error, data } = getTestDataApi;
    if (getTestDataApi.error) {
      toast.error("Test not found.");
      navigate("/admin/test/overview");
    } else if (loading === false && !error && data) {
      setConfusionMatrix(data["data"]["confusionMatrix"]);
      setRocData(data["data"]["rocData"]);
      setRocMax(data["data"]["rocMax"]);
      if (data["data"]["isdkOutput"])
        setIsdkOutput(
          "data:application/zip;base64," + data["data"]["isdkOutput"]
        );
      setTestData(data["test"]);
      setLoading(false);
    }
  }, [getTestDataApi.loading, getTestDataApi.error, getTestDataApi.data]);

  useEffect(() => {
    //set setup sessions dropdown options
    if (
      getReviewTestsApi.loading === false &&
      !getReviewTestsApi.error &&
      getReviewTestsApi.data
    ) {
      setReviewTests(getReviewTestsApi.data);
      if (searchParams.get("id")) {
        //@ts-ignore
        setTestId(searchParams.get("id"));
      } else if (getReviewTestsApi.data[0]) {
        setTestId(getReviewTestsApi.data[0]["_id"]);
        navigate(`/admin/test/review?id=${getReviewTestsApi.data[0]["_id"]}`);
      } else {
        setLoading(false);
      }
    }
  }, [
    getReviewTestsApi.loading,
    getReviewTestsApi.error,
    getReviewTestsApi.data,
  ]);

  useEffect(() => {
    if (deleteTestApi.loading === false) {
      if (deleteTestApi.error) {
        toast.error(deleteTestApi.error);
        deleteTestApi.clearError();
      } else {
        toast.success("Test deleted");
        navigate("/admin/test/overview");
      }
    }
  }, [deleteTestApi.loading, deleteTestApi.error, deleteTestApi.data]);

  //=====================================
  //HANDLERS
  //======================================

  // delete test
  const deleteTest = () => {
    deleteTestApi.request(testId, testData.name);
  };

  const initilize = () => {
    setRocData([]);
    setIsdkOutput("");
    setConfusionMatrix([]);
  };

  const renderTabView = () => {
    let headers: any = [];

    if (testData.type === "detection") {
      headers = [{ title: "Status" }];
    } else {
      headers = [
        { title: "Status" },
        { title: "ROC Curves" },
        { title: "Confusion Matrix" },
      ];
    }

    const extractRocData = (data: any) => {
      for (const key in data) {
        return data[key];
      }
    };

    const extractRocTitle = (data: any): string => {
      for (const key in data) {
        return key;
      }

      return "";
    };

    let timeElapsed = getDuration(testData.startTime, testData.endTime);

    let body = [
      <div className="wnBody">
        <div className="flex-justify-center">
          <div className="reviewSessionWrap">
            {testData.status == 5 ? (
              <div className="flex-justify-center alertTop">
                <img src={crossIcon} alt="checked" className="alertImg" />
                <span className="alertTxt">Oh snap!</span>
              </div>
            ) : (
              <div className="flex-justify-center alertTop">
                <img src={checkedBordIcon} alt="checked" className="alertImg" />
                <span className="alertTxt">That’s it!</span>
              </div>
            )}
            {isdkOutput && (
              <div className="flex-justify-center btnWrap statusbtnWrap">
                <a
                  href={isdkOutput}
                  download={`${testData.name}_test_out.zip`}
                  className="btnCustom btnfadeBlue btnBig"
                >
                  <img src={downloadIcon} alt="doanload" />
                  Inference Output
                </a>
              </div>
            )}

            <a href="#" className="bigBadge badgeBlue m-t-100">
              <img src={clockIcon} alt="clock" />
              Time Elapsed : {millisToTimeString(timeElapsed)}
            </a>
          </div>
        </div>
      </div>,
    ];

    if (testData.type === "roc") {
      body = [
        ...body,
        <div className="sliderWrapper">
          <Slider {...sliderSettings}>
            {rocData.map((data: any, i: number) => (
              <>
                <RocCurves
                  title={extractRocTitle(data)}
                  max={rocMax[i]}
                  data={extractRocData(data)}
                />
              </>
            ))}
          </Slider>
        </div>,
        <div className="sliderWrapper">
          <Slider {...sliderSettings}>
            {confusionMatix.map((data: any, i: number) => (
              <ConfusionMatrix
                title={extractRocTitle(data)}
                data={extractRocData(data)}
              />
            ))}
          </Slider>
        </div>,
      ];
    }

    return <TabView footer={false} header={headers} body={body} />;
  };

  if (testId) {
    return (
      <div>
        <div className="sectionHead">
          <div className="flex-align-center">
            <h2 className="sectionHeading">Test : </h2>
            <select
              onChange={(e) => {
                setTestId(e.target.value);
                navigate(`/admin/test/review?id=${e.target.value}`);
              }}
              className="customSelect m-l-10"
              name="sessionName"
              id=""
            >
              {reviewTests.map((test: { _id: string; name: string }) => (
                <option
                  key={test._id}
                  value={test._id}
                  selected={test._id === testId}
                >
                  {test.name}
                </option>
              ))}
            </select>
          </div>
          <div className="btnHolder flex-align-center">
            <a
              onClick={() => setDeleteModal(true)}
              className="btnCustom btnRed"
            >
              <img src={deleteIconWhite} alt="delete" />
              Delete
            </a>
          </div>
        </div>
        {!loading ? (
          <div className="globalCard">
            {renderTabView()}
            {deleteModal && (
              <DeleteModal
                title="Test"
                subtitle="Are you sure you want to delete this test? Doing this will permenatly destroy your progress."
                onCancel={() => setDeleteModal(false)}
                onDelete={deleteTest}
              />
            )}
          </div>
        ) : (
          <div className="loading">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    );
  } else if (!loading) {
    return (
      <div className="no-items">
        <h2>No Tests in Review</h2>
        <p>Tests that are in review will appear here.</p>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default TestReview;
