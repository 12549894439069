import axios from "axios";

// default API configuration


export const protectedClient = axios.create({
  //baseURL: 'http://localhost:3000/api',
  baseURL: 'https://api.aondevices.com/api',
  headers: {'Authorization': 'Bearer ' + localStorage.getItem('token') }
});

export const client = axios.create({
  //baseURL: 'http://localhost:3000/api',
  baseURL: 'https://api.aondevices.com/api'
});
