import { protectedClient } from "./Base/client";

// Sessions Main ------

// GET all sessions and queued sessions
const getSessions = (skip: number) => protectedClient.get("/session", { params: { skip: skip } });

// POST create sessions
const postSession = (data: unknown) => protectedClient.post("/session", data);

// Sessions Setup ------

// GET session info
const getSession = (sessionId: string) => protectedClient.get(`/session/${sessionId}`);

// GET monitor session info
const getMonitorSession = (sessionId: string) => protectedClient.get(`/session/monitor/${sessionId}`);

// GET review session info
const getReviewSession = (sessionId: string) => protectedClient.get(`/session/review/${sessionId}`);

// GET setup Sessions
const getSetupSessions = () => protectedClient.get("/session/list?type=setup");

// POST save session Setup
const putSaveSession = (sessionId: string, data: unknown) => protectedClient.put(`/session/${sessionId}`, data);

// DELETE session
const deleteSession = (sessionId: string) => protectedClient.delete(`/session/${sessionId}`);

// DELETE review session
const deleteReviewSession = (sessionId: string, name:string) => protectedClient.delete(`/session/review/${sessionId}?name=${name}`);

// POST start session
const postStartSession = (sessionId: string, data: unknown) => protectedClient.post(`/session/start/${sessionId}`, data);

// Session Monitor ------

// GET monitor sessions
const getMonitorSessions = () => protectedClient.get("/session/list?type=monitor");

// Session Review ------

// GET review sessions
const getReviewSessions = () => protectedClient.get("/session/list?type=review");

// GET Completed sessions
const getCompletedSessions = () => protectedClient.get("/session/list?type=completed");


export default {
  getSessions,
  postSession,
  getSession,
  getReviewSession,
  getMonitorSession,
  getSetupSessions,
  putSaveSession,
  deleteSession,
  deleteReviewSession,
  postStartSession,
  getMonitorSessions,
  getReviewSessions,
  getCompletedSessions
}
