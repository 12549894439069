import React, { useEffect, useState } from "react";
import { TReviewProps } from "./Review.types";
import { toast } from "react-toastify";
import Wizard from "../../common/Wizard/Wizard";
import { WizardNavigationTabStates } from "../../common/Wizard/WizardNavigation/WizardNavigation.types";
import { TWizardBodyType } from "../../common/Wizard/Wizard.types";
import checkedGreen from "../../../../assets/images/checked-green-fill.svg";
import playIcon from "../../../../assets/images/play-white.svg";
import TabView from "../../common/TabView/TabView";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import CodeEditor from "../../common/CodeEditor/CodeEditor";
import checkedBordIcon from "../../../../assets/images/checked-green-bordered.svg";
import crossIcon from "../../../../assets/images/cross-bordered-red.svg";
import downloadIcon from "../../../../assets/images/download-white.svg";
import clockIcon from "../../../../assets/images/clock-white.svg";
import deleteIconWhite from "../../../../assets/images/trash-white.svg";
import DataTable from "react-data-table-component";
import zipIconWhite from "../../../../assets/images/zip.svg";
import downloadIconGrey from "../../../../assets/images/download_grey.svg";
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import ConfusionMatrix from "../../common/ConfusionMatrix/ConfusionMatrix";
import DeleteModal from "../../common/DeleteModal/DeleteModal";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slideNext from "../../../../assets/images/slide-next.svg";
import slidePrev from "../../../../assets/images/slick-prev.svg";

import sessionsApi from "../../../../services/sessions";
import useApi from "../../../../services/Base/useApi";
import { useNavigate, useSearchParams } from "react-router-dom";

import LineGraphCarousel from "../LineGraphCarousel/LineGraphCarousel";
import RocCurves from "../RocCurves/RocCurves";
import {
  millisToTimeString,
  sortTSDKStats,
  base64ToFile,
  getDuration,
} from "../../../../utils/helpers";

const Review = (props: TReviewProps) => {
  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
  };

  //===================================
  //STATE
  //===================================
  const [deleteModal, setDeleteModal] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [reviewSessions, setReviewSessions] = useState([]);
  const [sessionData, setSessionData] = useState<any>({
    dakTime: { start: new Date(), end: new Date() },
  });
  const [dakLogs, setDakLogs] = useState("");
  const [dakRejected, setDakRejected] = useState("");
  const [tsdkLogs, setTsdkLogs] = useState("");
  const [weights, setWeights] = useState("");
  const [rocHtml, setRocHtml] = useState("");
  const [rocMax, setRocMax] = useState([]);
  const [rocData, setRocData] = useState([]);
  const [tsdkOutput, setTsdkOutput] = useState("");
  const [isdkOutput, setIsdkOutput] = useState("");
  const [confusionMatix, setConfusionMatrix] = useState([]);
  const [tsdkStats, setTsdkStats] = useState<any>({});

  //===================================
  //HOOKS
  //===================================
  const getSessionDataApi = useApi(sessionsApi.getReviewSession);
  const getReviewSessionsApi = useApi(sessionsApi.getReviewSessions);
  const deleteSessionApi = useApi(sessionsApi.deleteReviewSession);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (sessionId) {
      setLoading(true);
      initilize();
      getSessionDataApi.request(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    getReviewSessionsApi.request();
  }, []);

  useEffect(() => {
    //set data results
    const { loading, error, data } = getSessionDataApi;
    if (error) {
      toast.error("Session not found.");
      navigate("/admin/session/overview");
    } else if (loading === false && !error && data) {
      if (data["data"]["rocHtml"])
        setRocHtml(base64ToFile(data["data"]["rocHtml"], "text/html"));
      if (data["data"]["dakLogs"])
        setDakLogs("data:application/zip;base64," + data["data"]["dakLogs"]);
      if (data["data"]["dakRejected"])
        setDakRejected(
          "data:application/zip;base64," + data["data"]["dakRejected"]
        );
      if (data["data"]["tsdkLogs"])
        setTsdkLogs("data:application/zip;base64," + data["data"]["tsdkLogs"]);
      if (data["data"]["tsdkOutput"])
        setTsdkOutput(
          "data:application/zip;base64," + data["data"]["tsdkOutput"]
        );
      if (data["data"]["isdkOutput"])
        setIsdkOutput(
          "data:application/zip;base64," + data["data"]["isdkOutput"]
        );
      setConfusionMatrix(data["data"]["confusionMatrix"]);
      setRocData(data["data"]["rocData"]);
      setRocMax(data["data"]["rocMax"]);
      setSessionData(data["session"]);
      setTsdkStats(sortTSDKStats(data["session"]["outputStats"]));
      setActiveIndex(0);
      setLoading(false);
    }
  }, [
    getSessionDataApi.loading,
    getSessionDataApi.error,
    getSessionDataApi.data,
  ]);

  useEffect(() => {
    //set setup sessions dropdown options
    if (
      getReviewSessionsApi.loading === false &&
      !getReviewSessionsApi.error &&
      getReviewSessionsApi.data
    ) {
      setReviewSessions(getReviewSessionsApi.data);
      if (searchParams.get("id")) {
        //@ts-ignore
        setSessionId(searchParams.get("id"));
      } else if (getReviewSessionsApi.data[0]) {
        setSessionId(getReviewSessionsApi.data[0]["_id"]);
        navigate(
          `/admin/session/review?id=${getReviewSessionsApi.data[0]["_id"]}`
        );
      } else {
        setLoading(false);
      }
    }
  }, [
    getReviewSessionsApi.loading,
    getReviewSessionsApi.error,
    getReviewSessionsApi.data,
  ]);

  useEffect(() => {
    if (deleteSessionApi.loading === false) {
      if (deleteSessionApi.error) {
        toast.error(deleteSessionApi.error);
        deleteSessionApi.clearError();
      } else {
        toast.success("Session deleted");
        navigate("/admin/session/overview");
      }
    }
  }, [deleteSessionApi.loading, deleteSessionApi.error, deleteSessionApi.data]);

  //===================================
  //HANDLERS
  //===================================

  // delete session
  const deleteSession = () => {
    deleteSessionApi.request(sessionId, sessionData.name);
  };

  const initilize = () => {
    setRocHtml("");
    setDakLogs("");
    setDakRejected("");
    setTsdkStats({});
    setTsdkLogs("");
    setTsdkOutput("");
    setIsdkOutput("");
    setSessionData({});
    setConfusionMatrix([]);
  };

  const wizardNavigationTabState = (status: number) => {
    switch (status) {
      case 0:
        return WizardNavigationTabStates.default;
      case 1:
        return WizardNavigationTabStates.current;
      case 2:
        return WizardNavigationTabStates.completed;
      case 3:
        return WizardNavigationTabStates.failed;
      default:
        return WizardNavigationTabStates.default;
    }
  };

  const items = [
    {
      state: wizardNavigationTabState(sessionData.dakStatus),
      title: "<b>Augment</b>",
      jump: false,
    },
    {
      state: wizardNavigationTabState(sessionData.tsdkStatus),
      title: "<b>Train</b>",
      jump: false,
    },
  ];

  if (sessionData.runIsdk) {
    items.push({
      state: wizardNavigationTabState(sessionData.isdkStatus),
      title: "<b>Test</b>",
      jump: false,
    });
  }

  const getFirstNormalView = () => {
    const headers = [
      {
        title: "Status",
      },
    ];

    let timeElapsed = getDuration(
      sessionData.dakStartTime,
      sessionData.dakEndTime
    );

    const body = [
      <div className="wnWrapper">
        <div className="wnHead">
          {/* <h2 className="wnHeading">Review Session</h2> */}
        </div>
        <div className="wnBody">
          <div className="flex-justify-center">
            <div className="reviewSessionWrap">
              {sessionData.dakStatus == 3 ? (
                <div className="flex-justify-center alertTop">
                  <img src={crossIcon} alt="checked" className="alertImg" />
                  <span className="alertTxt">Oh snap!</span>
                </div>
              ) : (
                <div className="flex-justify-center alertTop">
                  <img
                    src={checkedBordIcon}
                    alt="checked"
                    className="alertImg"
                  />
                  <span className="alertTxt">That’s it!</span>
                </div>
              )}
              <div className="flex-justify-center btnWrap statusbtnWrap">
                <a
                  href={dakLogs}
                  download={`${sessionData.name}_augment_logs.zip`}
                  className="btnCustom btnfadeBlue btnBig"
                >
                  <img src={downloadIcon} alt="doanload" />
                  Log File
                </a>
                {dakRejected && (
                  <a
                    href={dakRejected}
                    download={`${sessionData.name}_augment_rejected.zip`}
                    className="btnCustom btnBlack"
                  >
                    <img src={clockIcon} alt="clock" />
                    Rejected Files Document
                  </a>
                )}
              </div>
              <a href="#" className="bigBadge badgeBlue m-t-100">
                <img src={clockIcon} alt="clock" />
                Time Elapsed : {millisToTimeString(timeElapsed)}
              </a>
            </div>
          </div>
        </div>
      </div>,
    ];
    const { dakStatus, tsdkStatus } = sessionData;

    return (
      <TabView
        title="Step 1: Augment"
        header={headers}
        body={body}
        onNext={
          dakStatus === 2 && tsdkStatus >= 2
            ? () => {
                setActiveIndex(1);
              }
            : null
        }
      />
    );
  };

  const getSecondTabView = () => {
    const headers = [
      {
        title: "Status",
      },
      {
        title: "Metrics",
      },
    ];

    // <div>line chart will be placed here</div>,
    // <div>{sessionReviewOutputDT()}</div>

    let timeElapsed = getDuration(
      sessionData.tsdkStartTime,
      sessionData.tsdkEndTime
    );

    const body = [
      <div className="wnBody">
        <div className="flex-justify-center">
          <div className="reviewSessionWrap">
            {sessionData.tsdkStatus == 3 ? (
              <div className="flex-justify-center alertTop">
                <img src={crossIcon} alt="checked" className="alertImg" />
                <span className="alertTxt">Oh snap!</span>
              </div>
            ) : (
              <div className="flex-justify-center alertTop">
                <img src={checkedBordIcon} alt="checked" className="alertImg" />
                <span className="alertTxt">That’s it!</span>
              </div>
            )}
            <div className="flex-justify-center btnWrap statusbtnWrap">
              <a
                href={tsdkLogs}
                download={"tsdk_logs.zip"}
                className="btnCustom btnfadeBlue btnBig"
              >
                <img src={downloadIcon} alt="doanload" />
                Log File
              </a>
              {tsdkOutput && (
                <a
                  href={tsdkOutput}
                  download={`${sessionData.name}_train_out.zip`}
                  className="btnCustom btnBlack"
                >
                  <img src={downloadIcon} alt="clock" />
                  Training Output
                </a>
              )}
            </div>
            <a href="#" className="bigBadge badgeBlue m-t-100">
              <img src={clockIcon} alt="clock" />
              Time Elapsed : {millisToTimeString(timeElapsed)}
            </a>
          </div>
        </div>
      </div>,
      <LineGraphCarousel data={tsdkStats} />,
    ];

    const { tsdkStatus, isdkStatus } = sessionData;

    return (
      <TabView
        title="Step 2: Train"
        header={headers}
        body={body}
        onBack={() => setActiveIndex(0)}
        onNext={
          tsdkStatus === 2 && isdkStatus >= 2
            ? () => {
                setActiveIndex(2);
              }
            : null
        }
      />
    );
  };

  const getThirdTabView = () => {
    const headers = [
      {
        title: "Status",
      },
      {
        title: "ROC Curves",
      },
      {
        title: "Confusion Matrix",
      },
    ];

    const extractRocData = (data: any) => {
      for (const key in data) {
        return data[key];
      }
    };

    const extractRocTitle = (data: any) => {
      for (const key in data) {
        return key;
      }
    };

    let timeElapsed = getDuration(
      sessionData.isdkStartTime,
      sessionData.isdkEndTime
    );

    const body = [
      <div className="wnBody">
        <div className="flex-justify-center">
          <div className="reviewSessionWrap">
            {sessionData.isdkStatus == 3 ? (
              <div className="flex-justify-center alertTop">
                <img src={crossIcon} alt="checked" className="alertImg" />
                <span className="alertTxt">Oh snap!</span>
              </div>
            ) : (
              <div className="flex-justify-center alertTop">
                <img src={checkedBordIcon} alt="checked" className="alertImg" />
                <span className="alertTxt">That’s it!</span>
              </div>
            )}

            <div className="flex-justify-center btnWrap statusbtnWrap">
              {isdkOutput && (
                <a
                  href={isdkOutput}
                  download={`${sessionData.name}_test_out.zip`}
                  className="btnCustom btnfadeBlue btnBig"
                >
                  <img src={downloadIcon} alt="doanload" />
                  Inference Output
                </a>
              )}
            </div>
            <a href="#" className="bigBadge badgeBlue m-t-100">
              <img src={clockIcon} alt="clock" />
              Time Elapsed : {millisToTimeString(timeElapsed)}
            </a>
          </div>
        </div>
      </div>,
      <div className="sliderWrapper">
        <Slider {...sliderSettings}>
          {rocData.map((data: any, i: number) => (
            <>
              <RocCurves
                title={extractRocTitle(data)}
                max={rocMax[i]}
                data={extractRocData(data)}
              />
            </>
          ))}
        </Slider>
      </div>,
      <div className="sliderWrapper">
        <Slider {...sliderSettings}>
          {confusionMatix.map((data: any, i: number) => (
            <ConfusionMatrix
              title={extractRocTitle(data)}
              data={extractRocData(data)}
            />
          ))}
        </Slider>
      </div>,
    ];
    return (
      <TabView
        title="Step 3: Test"
        header={headers}
        body={body}
        onBack={() => setActiveIndex(1)}
      />
    );
  };

  const firstStepBody = {
    type: TWizardBodyType.normal,
    content: getFirstNormalView(),
  };
  const secondStepBody = {
    type: TWizardBodyType.tab,
    content: getSecondTabView(),
  };
  const thirdStepBody = {
    type: TWizardBodyType.tab,
    content: getThirdTabView(),
  };
  const totalBody = [firstStepBody, secondStepBody, thirdStepBody];

  if (sessionId) {
    return (
      <div>
        <div className="sectionHead">
          <div className="flex-align-center">
            <h2 className="sectionHeading">Session : </h2>
            <select
              onChange={(e) => {
                setSessionId(e.target.value);
                navigate(`/admin/session/review?id=${e.target.value}`);
              }}
              className="customSelect m-l-10"
              name="sessionName"
              id=""
            >
              {reviewSessions.map((session: { _id: string; name: string }) => (
                <option
                  key={session._id}
                  value={session._id}
                  selected={session._id === sessionId}
                >
                  {session.name}
                </option>
              ))}
            </select>
          </div>
          <div className="btnHolder flex-align-center">
            <a
              onClick={() => setDeleteModal(true)}
              className="btnCustom btnRed"
            >
              <img src={deleteIconWhite} alt="delete" />
              Delete
            </a>
          </div>
        </div>
        {!loading ? (
          <>
            <Wizard header={items} body={totalBody} activeIndex={activeIndex} />
            {deleteModal && (
              <DeleteModal
                title="Session"
                subtitle="Are you sure you want to delete this session? Doing this will permenatly destroy your progress."
                onCancel={() => setDeleteModal(false)}
                onDelete={deleteSession}
              />
            )}
          </>
        ) : (
          <div className="loading">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    );
  } else if (!loading) {
    return (
      <div className="no-items">
        <h2>No Sessions in Review</h2>
        <p>Sessions that are in review will appear here.</p>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Review;
