export enum WizardNavigationTabStates {
    default ,
    completed ,
    current,
    failed,
    error
}

export type TWizardNavigationTabItems = {
    state:WizardNavigationTabStates,
    title:string,
    jump?:boolean
}

export type TWizardNavigationStateProps = {
    items:Array<TWizardNavigationTabItems>;
};

export type TWizardNavigationDispatchProps = {};

export type TWizardNavigationProps = TWizardNavigationStateProps & TWizardNavigationDispatchProps;

export type TWizardNavigationHook = {};
