import { useState } from "react";
import axios from "axios";

export default (apiFunc: Function) => {

  // API base hook
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);

  const request = async(...args: any[]) => {
    setLoading(true);
    try {
      const result = await apiFunc(...args);
      setData(result.data);
    }
    catch(err: any) {
      if(err.response.status === 401){
        localStorage.removeItem("token");
        window.location.reload();
      }
      let message = "Unexpected Error";
      if(err.response){
        message = err.response.data.message;
      }
      setError(message);
      // console.log(err.response.data.message)
      // let message = "Unknown Error";
      // if(axios.isAxiosError(err))
      // {
      //   // handle axios errors
      //   setError("Axios Error");
      // }
      // else if(err.response && err.response.data) message = err.response.data.message || "Unexpected Error";
    }
    finally {
      setLoading(false);
    }
  };

  const clearError = () =>{
    setError("")
  }

  const clearData = () => {
    setData(null)
  }

  return{
    data,
    error,
    loading,
    request,
    clearError,
    clearData
  }
}
