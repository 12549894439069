import React from "react";
import { TLaunchingModalProps } from "./LaunchingModal.types";

const LaunchingModal = (props: TLaunchingModalProps) => {
  let title = props.title;
  let subtitle = props.subtitle;
  return (
    <div className="no-items">
      <h2>{title}</h2>
      <p>{subtitle}</p>
      <div className="loading" style={{ height: 75 }}>
        <div className="loading-spinner"></div>
      </div>
    </div>
  );
};

export default LaunchingModal;
