import React, { useEffect,useState } from "react";
import { TLineGraphCarouselProps } from "./LineGraphCarousel.types";

import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import slideNext from "../../../../assets/images/slide-next.svg";
import slidePrev from "../../../../assets/images/slick-prev.svg"

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

const LineGraphCarousel = (props: TLineGraphCarouselProps) => {
  const data = props.data

  const sliderSettings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
    }

  const accuracyLineOptions = {
      responsive: true,
      // height: 305,
      // width: 610,
      scales: {
          y: {
              title: {
                  display: true,
                  text: 'Percentage'
              }
          },
          x: {
              title: {
                  display: true,
                  text: 'Epoch'
              }
          }
      },
      plugins: {
          legend: {
              position: 'bottom' as const,
          },
          title: {
              display: true,
              text: 'Accuracy',
          },
      },
  };

  const lossLineOptions = {
      responsive: true,
      scales: {
          y: {
              title: {
                  display: true,
                  text: 'Loss'
              }
          },
          x: {
              title: {
                  display: true,
                  text: 'Epoch'
              }
          }
      },
      plugins: {
          legend: {
              position: 'bottom' as const,
          },
          title: {
              display: true,
              text: 'Loss',
          },
      },
  };

  const learningRateLineOptions = {
      responsive: true,
      scales: {
          y: {
              title: {
                  display: true,
                  text: 'Rate'
              }
          },
          x: {
              title: {
                  display: true,
                  text: 'Epoch'
              }
          }
      },
      plugins: {
          legend: {
              position: 'bottom' as const,
          },
          title: {
              display: true,
              text: 'Learning Rate',
          },
      },
  };

  const getAccuracyLineData = (key: string) =>{
    let accuracyData = [
        {
            label:"Training",
            borderColor:"#1A50BA",
            data:data[key]['data'].map( (item: any) =>{
                return [item.epoch,item.acc*100]
            })
        },
        {
            label:"Validation",
            borderColor:"#FFC300",
            data:data[key]['data'].map( (item:any) =>{
                return [item.epoch,item.val_acc*100]
            })
        }
    ];

    return {
        labels: data[key]['labels'],
        datasets: accuracyData
    };
  }

  const getLossLineData = (key: string) =>{
    let lossData = [
        {
            label:"Training",
            borderColor:"#1A50BA",
            data:data[key]['data'].map( (item: any) =>{
                return [item.epoch,item.loss*100]
            })
        },
        {
            label:"Validation",
            borderColor:"#FFC300",
            data:data[key]['data'].map( (item:any) =>{
                return [item.epoch,item.val_loss*100]
            })
        }
    ];

    return {
        labels: data[key]['labels'],
        datasets: lossData
    };
  }

  const getLearningRateData = (key: string) =>{
    let learningRateData = [
        {
            label:"Training",
            borderColor:"#1A50BA",
            data:data[key]['data'].map( (item: any) =>{
                return [item.epoch,item.lr]
            })
        }
    ];

    return {
        labels: data[key]['labels'],
        datasets: learningRateData
    };
  }

  return (
    <div className="sliderWrapper">
        <Slider {...sliderSettings}>
          {
            Object.keys(data).map((key: string, i) => (
              <div className="prgrsWrap chartWrap">
                <h3>{key.replace('RN', 'Round ')}</h3>
                  <div className="chartItem">
                      <Line options={accuracyLineOptions} data={getAccuracyLineData(key)} />
                  </div>
                  <div className="chartItem">
                      <Line options={lossLineOptions} data={getLossLineData(key)} />
                  </div>
                  <div className="chartItem">
                      <Line options={learningRateLineOptions} data={getLearningRateData(key)} />
                  </div>
              </div>
            ))
          }

        </Slider>

    </div>
  );
}

export default LineGraphCarousel;
