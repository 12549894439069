import React, {useContext} from "react";
import { TWizardBodyProps } from "./WizardBody.types";
import WizardTabView from "../WizardTabView/WizardTabView";
import WizardNormalView from "../WizardNormalView/WizardNormalView";
import {TWizardBodyType} from "../Wizard.types";
import {WizardContext} from "../Wizard";

const WizardBody = (props: TWizardBodyProps) => {
    const TabContext = useContext(WizardContext)
    const bodies = props.body;


    return (
        <>
        {
            bodies.map(function (body, index) {
                const className = index === TabContext.activeTab ? "wizardBodyIn" : "wizardBodyIn hidden";
                return (<div key={index} className={className}>
                    {body.type == TWizardBodyType.tab && <WizardTabView body={body.content}/>}
                    {body.type == TWizardBodyType.normal && <WizardNormalView body={body.content}/>}
                </div>)
            })
        }
        </>
    )
}

export default WizardBody;
