import React from "react";
import { TDashboardCardProps } from "./DashboardCard.types";
import "./DashboardCard.css"

const DashboardCard = (props: TDashboardCardProps) => {
    return (
        <div className="dashCardWrap">
            <span className={'statusCard ' + props.statusClass}>{props.status}</span>
            <span className="noDatatxt">{props.blankTxt}</span>
        </div>
    )
}
export default DashboardCard;
