import React, { useEffect, useState, useContext, useRef } from "react";
import { TCollectModalProps } from "./CollectModal.types";
import recordIconWhite from "../../../../../assets/images/record.png"
import playgreyIconWhite from "../../../../../assets/images/play-grey.svg"
import stopIcon from "../../../../../assets/images/stop.png"
import { CollectModalContext } from '../Collect';
import { AudioRecorder, useAudioRecorder } from 'react-audio-voice-recorder';

import Recorder from "recorder-js";

declare global {
  interface Window {
    webkitAudioContext: typeof AudioContext;
  }
}

interface RecorderConfig {
  onAnalysed?: (data: any) => void;
  numChannels?: number;
}

const audioContext = new (window.AudioContext || window.webkitAudioContext)({
  sampleRate: 16000,

});


const CollectModal = (props: TCollectModalProps) => {
  //=============================
  // STATE
  //=============================

  const [isRecording, setIsRecording] = useState(false);
  const recorderRef = React.useRef<Recorder | null>(null);

  const recorderControls = useAudioRecorder()

  const modalContext = useContext(CollectModalContext);

  const [isChecked, setIsChecked] = useState(false);

  const [modalStep, setModalStep] = useState(1);

  const [sampleNum, setSampleNum] = useState(1)

  const [audio, setAudio] = useState("")

  const [blob, setBlob] = useState(null)

  //=============================
  // HOOKS
  //=============================
  useEffect(() => {
    // Cleanup function
    return () => {
      if (timeoutId) {
        //@ts-ignore
        clearTimeout(timeoutId.current);
      }
    };
  }, []);

  const timeoutId = useRef<number | null>(null);

  const streamRef = useRef<MediaStream | null>(null);

  //=============================
  // HANDLERS
  //=============================

  const submitStep1 = (event: React.FormEvent) => {
    event.preventDefault();
    if (modalContext.name && isChecked) setModalStep(1);

  }

  const onSave = (event: React.FormEvent) => {
    event.preventDefault();
    if (audio) {
      modalContext.setUtterances([...modalContext.utterances, { sampleNum, audio, blob }])
      setAudio("")
      //@ts-ignore
      setBlob(null)
      if (sampleNum === modalContext.numSamples) {
        modalContext.setCollectModal(false)
      }
      setSampleNum(sampleNum + 1)
    }

  }

  const onDelete = (event: React.FormEvent) => {
    event.preventDefault();
    setAudio("")
    //@ts-ignore
    setBlob(null)
  }

  const addAudioElement = (blob: any) => {
    const url = URL.createObjectURL(blob);
    setAudio(url)
    setBlob(blob)
  };


const startRecording = async () => {
    const audioTrackConstraints = {
      sampleRate: 16000,
      channelCount: 1,
      volume: 1.0,
      echoCancellation: false,
    };

    try {
      streamRef.current = await navigator.mediaDevices.getUserMedia({
        audio: audioTrackConstraints,
        video: false
      });

      const audioContext = new (window.AudioContext || window.webkitAudioContext)({
        sampleRate: 16000,
      });
      await audioContext.resume();

      recorderRef.current = new Recorder(audioContext, {
        numChannels: 1,
      } as RecorderConfig);

      if (recorderRef.current && streamRef.current) {
        recorderRef.current.init(streamRef.current);
        recorderRef.current.start();
        setIsRecording(true);
        //@ts-ignore
        timeoutId.current = setTimeout(stopRecording, 3000)
      }
    }catch(err){
      console.log(err);
    }
  };

  const stopRecording = async () => {
    if (recorderRef.current) {
      const { blob } = await recorderRef.current.stop();
      const url = URL.createObjectURL(blob);
      setAudio(url)
      //@ts-ignore
      setBlob(blob)
      setIsRecording(false);
    }

    if (timeoutId.current) {
      //@ts-ignore
      clearTimeout(timeoutId.current);
      timeoutId.current = null; // reset the timeoutId
    }

    // Stop all tracks
    if(streamRef.current) {
      streamRef.current.getTracks().forEach(track => track.stop());
      streamRef.current = null; // Reset the stream reference
    }
  };



  return (
    <div>
      {/* modal Data Collection */}

      {modalStep == 0 && <div className="modalOverlay">
        <div className="modalWrapper">

          <form onSubmit={submitStep1}>
            <div className="modalWrapperHeader">
              <span>Data Collection</span>
            </div>
            <div className="modalWrapperBody">
              <div className="modalContWrapper">
                <h2>Agreement</h2>
                <p>Please read the following important information before continuing.</p>
                <p>Please read the following Agreement. You must accept the terms of this agreement before continuing.</p>
                <p>Please note that information provided by you in connection with using this tool, such as your voice or motion sensor recordings that we make (and may store on our servers) when you teach or use voice commands or motion sensor actions will be used by our company for non-commercial or commercial purposes, and you are giving the company a perpetual license to do so.</p>
              </div>
              <div className="gap30"></div>
              <div className="customRow">
                <div className="customColumn-12">
                  <input onChange={(e) => modalContext.setName(e.target.value)} type="text" className="inputTextModal" placeholder="Enter Name" />
                </div>
              </div>
              <div className="gap30"></div>
              <div className="customRow">
                <div className="customColumn-12 text-center">
                  <input onChange={(e) => setIsChecked(e.target.checked)} checked={isChecked} type="checkbox" id="termsAcpt" name="termsAcpt" value="" />
                  <label htmlFor="termsAcpt"> I accept the agreement</label>
                </div>
              </div>
              <div className="gap30"></div>
              <div className="customRow">
                <div className="customColumn-12 text-center">
                  <button type={"submit"} className="btnCustom btnGrey modalButton">Next</button>
                </div>
              </div>
            </div>

          </form>
        </div>
      </div>}

      {/* modal Data Collection */}

      {/* Recording Configuration */}

      {modalStep == 1 && <div className="modalOverlay">
        <div className="modalWrapper">
          <div>
            <div className="modalWrapperHeader">
              <span>Data Collection</span>
            </div>
            <div className="modalWrapperBody">
              <div className="modalContWrapper">
                <div className="contWrapperLabel">
                  <span>Recording Configuration</span>
                </div>
                <div style={{ textAlign: "center" }}>
                  <h3>Number of "{modalContext.keyword}" Utterances</h3>
                  <h3>{(modalContext.numSamples + 1) - sampleNum}</h3>
                </div>

              </div>
              <div className="gap30"></div>
              <div className="customRow">
                <div className="customColumn-7 ">
                  <div className="modalContWrapper">
                    <div className="contWrapperLabel">
                      <span>Record</span>
                    </div>
                    <div>
                      {
                        audio
                          ? <audio controls src={audio} />
                          : <div className="buttonIconWrap">

                            {!isRecording && <button onClick={async() => await startRecording()}><img src={recordIconWhite} alt="record" /></button>}
                            {isRecording && <button onClick={stopRecording}><img src={stopIcon} alt="play" /></button>}
                          </div>
                      }


                    </div>

                  </div>
                </div>
                <div className="customColumn-5 text-right">
                  {
                    audio && <div>
                      <button onClick={onSave} className="btnBlue btnCustom modalButton">Save</button>
                      <div style={{ margin: 10 }}></div>
                      <button onClick={onDelete} className="btnRed btnCustom modalButton">Delete</button>
                    </div>
                  }

                </div>
              </div>
              <div className="customColumn-12 text-center" style={{ marginTop: 20 }}>
                <button onClick={() => {
                    modalContext.reset()
                    modalContext.setCollectModal(false)
                 }} className="btnBlack btnCustom modalButton">Cancel</button>
              </div>
            </div>
          </div>
        </div>

      </div>}

      {/* Recording Configuration */}

      {/* Recording data timer */}



      {/* Recording data timer end */}
    </div>
  );
}

export default CollectModal;
