import { protectedClient } from "./Base/client"


// GET input data and noise data options
const getDataSource = (directoryPaths: string) => protectedClient.get("/data-source", { params: { directory_paths: directoryPaths } });
// GET Weights data
const getWeights = (path: string, mode: string) => protectedClient.get("/data-source/weights", { params: { path: path, mode:mode } });
// GET Weights data
const getIniFile = (path: string, mode: string) => protectedClient.get("/data-source/ini-file", { params: { path: path, mode:mode } });
// GET Weights data
const getTestOutput = (path: string) => protectedClient.get("/data-source/download-test-output", { params: { path: path } });
// GET Weights data
const getSessionOutput = (path: string) => protectedClient.get("/data-source/download-session-output", { params: { path: path } });
// POST data
const postUploadData = (data:unknown ) => protectedClient.post("/data-source/", data);
// POST data
const postDataCollection = (data:unknown ) => protectedClient.post("/data-source/collection", data);
// PUT Trim data
const putTrimAudio = (data:unknown ) => protectedClient.put("/data-source/trim", data);
// GET Notifications data
const getNotifications = (path: string) => protectedClient.get("/status/notifications");
// POST DATA
const deleteDatasource = (data:unknown ) => protectedClient.post("/data-source/delete-data", data);
// POST DATA
const getPresignedUrl = (data:unknown ) => protectedClient.post("/data-source/presigned-url", data);


export default {
  getDataSource,
  getWeights,
  getTestOutput,
  getSessionOutput,
  getNotifications,
  postUploadData,
  postDataCollection,
  putTrimAudio,
  deleteDatasource,
  getPresignedUrl,
  getIniFile
}
