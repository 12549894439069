import React from "react";
import { TWizardTabViewProps } from "./WizardTabView.types";

const WizardTabView = (props: TWizardTabViewProps) => {
    const content = props.body;
    return (
       content
    )
}

export default WizardTabView;
