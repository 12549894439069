import React from "react";
import pageBackImage from "../../../../assets/images/back.svg"
import passwordSetImage from "../../../../assets/images/pass-set.svg"
import { Link } from "react-router-dom";

const SupportComplete = () => {
    return (
        <div className="authPageWrap">
            <div className="authCreateWrapper">
                <img src={passwordSetImage} alt="icon" className="authTopLogo"/>
                <h2 className="registrationHead text-center m-b-0 greyTxt">Request sent</h2>
                <h4 className="registrationSubhead width70p">Your request has been sent. We will get back to you as soon as possible.</h4>
                <div className="customColumn500">


                    <div className="gap20"></div>
                    <div className="customColumn-12 text-center">
                        <div className="customColumn-12  m-b-20 text-center">

                            <Link to="/admin/support/" className="btnCustom btnYellow authButton">New Request</Link>
                        </div>
                    </div>
                    <div className="gap20"></div>
                </div>
            </div>
        </div>
    );
}

export default SupportComplete;
