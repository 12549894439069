import React from "react";
import { TForgotPasswordStep4Props } from "./ForgotPasswordStep4.types";
import pageBackImage from "../../../../assets/images/back.svg"
import passwordSetImage from "../../../../assets/images/pass-set.svg"
import { Link } from "react-router-dom";

const ForgotPasswordStep4 = (props: TForgotPasswordStep4Props) => {
    return (
        <div className="authPageWrap">
            <div className="authCreateWrapper">
                <img src={passwordSetImage} alt="icon" className="authTopLogo"/>
                <h2 className="registrationHead text-center m-b-0 greyTxt">Password reset</h2>
                <h4 className="registrationSubhead width70p">your password has been successfully reset. Click below to log in magically.</h4>
                <div className="customColumn500">
                    
                    
                    <div className="gap20"></div>
                    <div className="customColumn-12 text-center">
                        <div className="customColumn-12  m-b-20 text-center">

                            <Link to="/login" className="btnCustom btnYellow authButton">Continue</Link>
                        </div>
                    </div>
                    <div className="gap20"></div>
                    <div className="customColumn-12 text-center">
                        <div className="backPageLink text-center">
                            <Link to="/login"><img src={pageBackImage} alt="icon" /> Back to log in</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordStep4;
