import React, { useContext, useEffect } from "react";
import { CommandSequenceContext } from "./CommandSequence";

const SelectKeywordModal = () => {
    const contextValue = useContext(CommandSequenceContext);

    const { 
        keywords, 
        selectedKeyword, 
        onSelectKeyword,
        setKeywordModal, 
        setCommandsModal,
        clear,
    } = contextValue;

    const cancel = () => {
        clear();
        setKeywordModal(false) ;
    }

    const next = () => {
        if (selectedKeyword){
            setKeywordModal(false);
            setTimeout(() => {
                setCommandsModal(true);
            },500)
        }
        
    }


    return (
        <div className="modalOverlay">
      <div className="modalWrapper bgWhite">
        <div className="modalHeadTransp">
          <h3 className="modalheader">Select a Wake Word</h3>
        </div>
        <div className="modalWrapperBody">
            <div className="grid-3">
                {
                    keywords.map((word: any) => (
                        <a  
                            style={{textAlign:'center'}}
                            onClick={() => onSelectKeyword(word)}
                            className={selectedKeyword == word ? 'btn btnBlue btnCustom' : 'btn'}>
                            {word.name}
                        </a>
                    ))
                }
            </div>
        <div className="gap30"></div>
            <div className="customColumn-12 text-center">
              <button
                onClick={cancel}
                className="btnBlack btnCustom modalButton"
              >
                Cancel
              </button>
              <button 
                    onClick={next}
                    className="btnYellow btnCustom modalButton">
                Next
              </button>
            </div>
        </div>
      </div>
    </div>
    )


}

export default SelectKeywordModal;