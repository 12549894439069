import './Cutter.css'
import {useContext} from 'react';
import AudioWaveform from './AudioWaveform';
import {CollectModalContext} from '../Collect2/Collect';
import {useNavigate} from 'react-router-dom';
const Edit = ({}) => {
  const { file, setFile, cutterModal, setCutterModal, trimAudio } = useContext(CollectModalContext);

  const navigate = useNavigate();

  const onBack = () =>{
    setCutterModal(false)
    // window.location.replace('/')
  }

  const onSave = (start,end) =>{
    trimAudio(start,end)
    // setCutterModal(false)
    // let tempFiles = [...files]
    // tempFiles[index] = {...tempFiles[index], start, end, cleaned:true}
    // setFiles(tempFiles)
    // navigate('/')
  }


  return (

    <div className="modalOverlay">
      <div className="modalWrapperLarge">
        <div >
          <div className="modalWrapperHeader">
              <span>Data Cutter</span>
          </div>
          <div className="modalWrapperBody">
            <div className="modalContWrapper">
              <div>
                <AudioWaveform
                  start={0}
                  end={1}
                  onSave={(start,end)=>onSave(start,end)}
                  fileURL={file.file}/>
              </div>
            </div>
            <div className="customColumn-12 text-center" style={{marginTop:20}}>
                <button onClick={()=>{setCutterModal(false)}} className="btnBlack btnCustom modalButton">Cancel</button>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
}

export default Edit;
