import React, { useContext, useState } from "react";
import { TOverviewModalProps } from "./OverviewModal.types";

import { OverviewModalContext } from "../Overview";

const OverviewModal = (props: TOverviewModalProps) => {
  //===============================
  //STATE
  //===============================
  const [name, setName] = useState("");
  const [mode, setMode] = useState("Audio");
  const modalContext = useContext(OverviewModalContext);

  //===============================
  //HANDLERS
  //===============================
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    modalContext.handleCreateSession({ name, mode });
  };

  return (
    <div className="modalOverlay">
      <div className="modalWrapper bgWhite">
        <div className="modalHeadTransp">
          <h3 className="modalheader">Create Session</h3>
        </div>
        <div className="modalWrapperBody">
          <form onSubmit={handleSubmit}>
            <div className="width70 widthAuto">
              <div className="inpWithLabel m-b-15">
                <label htmlFor="name" className="inplabel">
                  Session Name <span className="txtRed">*</span>
                </label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  className="borderInp"
                />
              </div>
              <div className="inpWithLabel m-b-15">
                <label htmlFor="name" className="inplabel">
                  Mode <span className="txtRed">*</span>
                </label>
                <select
                  value={mode}
                  onChange={(e) => setMode(e.target.value)}
                  className="selectWrap"
                >
                  <option value="Audio">Audio</option>
                  <option value="Sensors">Sensors</option>
                  <option value="SID">Speaker ID</option>
                  <option value="Denoiser">Denoiser</option>
                </select>
              </div>
            </div>
            <div className="gap30"></div>
            <div className="customColumn-12 text-center">
              <button
                onClick={() => {
                  modalContext.setOverviewModal(false);
                }}
                className="btnBlack btnCustom modalButton"
              >
                Cancel
              </button>
              <button type="submit" className="btnYellow btnCustom modalButton">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default OverviewModal;
