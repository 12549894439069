import React, { useState} from "react";
import {TMainProps} from "./Main.types";
import menuIcon from "../../../assets/images/menu_white.svg";
import SideBar from "../navigation/SideBar/SideBar";
import TopBar from "../navigation/TopBar/TopBar";
import "./Main.css";

import Dashboard from "../dashboard/Dashboard/Dashboard";
import Collect from "../dataset/Collect2/Collect";
import Store from "../dataset/Store/Store";
import Setup from "../session/Setup/Setup";
import Monitor from "../session/Monitor/Monitor";
import Review from "../session/Review/Review";
import Output from "../output/Output/Output";
import TestSetup from "../inference/TestSetup/TestSetup";
import TestMonitor from "../inference/TestMonitor/TestMonitor";
import TestReview from "../inference/TestReview/TestReview";
import CommandSequence from "../inference/CommandSequence/CommandSequence";
import TestOverview from "../inference/TestOverview/TestOverview";
import Overview from "../session/Overview/Overview";
import Support from "../support/Support/Support";
import SupportComplete from "../support/Support/SupportComplete";
import Notifications from "../notifications/Notifications/Notifications";
import ApiData from "../chip/ApiData/ApiData";

const Main = (props: TMainProps) => {

    const [expandSidebar, setExpandSidebar] = useState(false);


    const toggleMenu= () => {
        setExpandSidebar(!expandSidebar)
    }

    let section = props.section ? props.section : 'dashboard';
    if (!section && props.page) {
        let pageArray = props.page.split("/");
        section = pageArray[0];
    }




    const getWidget = () => {
        switch (props.section) {
            case 'dashboard': {
                return (
                    <Dashboard/>
                );
                break;
            }
            case 'dataset': {
                if(props.page == 'dataset/collect')
                    return (<Collect/>);
                if(props.page == 'dataset/store')
                    return (<Store/>);

                break;
            }
            case 'session': {
                if(props.page.startsWith("session/overview"))
                    return(<Overview/>);
                if(props.page.startsWith("session/setup"))
                    return(<Setup/>);
                if(props.page.startsWith("session/monitor"))
                    return(<Monitor/>);
                if(props.page.startsWith("session/review"))
                    return(<Review/>);
                break;
            }
            case 'test': {
                if(props.page.startsWith("test/overview"))
                    return(<TestOverview/>);
                if(props.page.startsWith("test/setup"))
                    return(<TestSetup/>);
                if(props.page.startsWith("test/monitor"))
                    return(<TestMonitor/>);
                if(props.page.startsWith("test/review"))
                    return(<TestReview/>);
                if(props.page.startsWith("test/command-sequence"))
                    return(<CommandSequence/>);
                if(props.page.startsWith("test/api-data"))
                    return(<ApiData/>);

                break;
            }
            case 'output': {
                   return (<Output/>);
                break;
            }
            case 'support': {
              if(props.page.startsWith("support/request/"))
                  return(<Support/>);
              if(props.page.startsWith("support/request-sent/"))
                  return (<SupportComplete/>);
              break;
            }
            case 'notifications': {
                return (
                    <Notifications/>
                );
                break;
            }
            default: {

                break;
            }
        }
    };


    return (
        <div>

            <div className="aonWrapper">
                <div className={expandSidebar ? 'sidebarHolder expand' : 'sidebarHolder'}>
                    <div className="sidebarSmOverlay" onClick={() => toggleMenu()}></div>
                    <div className="sidebarWrap">
                        <SideBar section={section} page={props.page}/>
                    </div>
                </div>
                <div className="mainBodyWrap">
                    <div className="headerWrap">
                        <a href="#" className="smMenuCaller" onClick={() => toggleMenu()}>
                            <img src={menuIcon} alt=""/>
                        </a>
                        <TopBar/>
                    </div>
                    <div className="bodyWrap">
                        <div className="linkMapWrap">
                            <a href="#">{props.page}</a>
                        </div>
                        {getWidget()}
                    </div>
                </div>

            </div>

        </div>

    );
}

export default Main;
