import { protectedClient } from "./Base/client";

// Test Main

// GET all test sessions
const getTestSessions = (skip: number) => protectedClient.get("/test", { params: { skip: skip } });

// GET all queued Test
    // unimplemented api source
// POST create test
const postTest = (data: unknown) => protectedClient.post("/test", data);
// Test Setup

// GET test info
const getTest = (testId: string) => protectedClient.get(`/test/${testId}`);

// GET review session info
const getMonitorTest = (testId: string) => protectedClient.get(`/test/monitor/${testId}`);

// GET review session info
const getReviewTest = (testId: string) => protectedClient.get(`/test/review/${testId}`);

// GET test in setup stage
const getSetupTests = () => protectedClient.get("/test/list?type=setup");

// POST save test Setup
const putSaveTest = (testId: string, data: unknown) => protectedClient.put(`/test/${testId}`, data);

// DELETE test
const deleteTest = (testId: string) => protectedClient.delete(`/test/${testId}`);

// DELETE review test
const deleteReviewTest = (testId: string, name: string) => protectedClient.delete(`/test/review/${testId}?name=${name}`);

// POST start test
const postStartTest = (testId: string, data:unknown) => protectedClient.post(`/test/start/${testId}`, data);

// Test Monitor

// GET test in monitor stage
const getMonitorTests = () => protectedClient.get("/test/list?type=monitor");

// Test review

// GET test in review stage
const getReviewTests = () => protectedClient.get("/test/list?type=review");

// POST start test
const postGenerateCommandSequence = (path: string, mode: string, data:unknown) => protectedClient.post(`/test/generate-command-sequence`, data, { params: { path, mode }});

const postGenerateApiData = (data:unknown) => protectedClient.post(`/test/generate-api-data`, data);

export default {
  getTestSessions,
  getTest,
  getMonitorTest,
  getReviewTest,
  getSetupTests,
  postTest,
  putSaveTest,
  deleteTest,
  deleteReviewTest,
  postStartTest,
  getMonitorTests,
  getReviewTests,
  postGenerateCommandSequence,
  postGenerateApiData
}
