

export const millisToTimeString = (millis: number): string => {
  let hours: number | string = Math.floor(millis / 3600000);
  let minutes: number | string = Math.floor((millis % 3600000) / 60000);
  let seconds: number | string = Math.floor((millis % 60000) / 1000);


  return `${hours} ${hours === 1 ? 'hr' : 'hrs'} ${minutes} ${minutes === 1 ? 'min' : 'mins'}`;
}

export const sortTSDKStats = (stats:Array<any>): any =>{
  let labelCount = 1;
  let statsObject: { [key: string]: any } = {};

  //loop through stats
  for (let i = 0; i < stats.length; i++) {
    let stat = stats[i];
    let key: string = stat['round'];

    if(!statsObject[key]){
      labelCount = 1;
      statsObject[key] = { data:[], labels:[]};
    }

    statsObject[key]['data'].push(stat);
    statsObject[key]['labels'].push(labelCount.toString());
    labelCount++;
  }

  const sortedObj = Object.keys(statsObject)
  .sort((a, b) => b.localeCompare(a))
  .reduce((acc, key) => ({
    ...acc,
    [key]: statsObject[key]
  }), {});
  return sortedObj;
}

export const base64ToFile = (base64:string, type: string): string =>{
  const binaryString = window.atob(base64);
  const blob = new Blob([binaryString], { type: type });
  const fileUrl = URL.createObjectURL(blob);
  return fileUrl;
}

export const getDuration = (start:Date | null, end:Date | null): number =>{
  const startTime = start ? new Date(start) : new Date();
  const endTime = end ? new Date(end) : new Date();
  return endTime.getTime() - startTime.getTime();
}

export const getTimeAgo = (date: Date): string => {
  const seconds = Math.floor((Date.now() - date.getTime()) / 1000);
  let interval = Math.floor(seconds / 31536000);

  if (interval >= 1) {
    return interval + "y";
  }

  interval = Math.floor(seconds / 2592000);
  if (interval >= 1) {
    return interval + "mo";
  }

  interval = Math.floor(seconds / 86400);
  if (interval >= 1) {
    return interval + "d";
  }

  interval = Math.floor(seconds / 3600);
  if (interval >= 1) {
    return interval + "hr";
  }

  interval = Math.floor(seconds / 60);
  if (interval >= 1) {
    return interval + "m";
  }

  return Math.floor(seconds) + "s";
}


const twentyFiveMB = 25 * 1024 * 1024

export const createString = (size = twentyFiveMB) => {
  return "x".repeat(size);
}

