import React from "react";
import {TConfusionMatrixProps} from "./ConfusionMatrix.types";
import './ConfusionMatrix.css'
import ColorSteps from "./ColorSteps";

const ConfusionMatrix = (props: TConfusionMatrixProps) => {

    const rawMatrixData = props.data;
    // const rawMatrixData = {
    //     'actual': {
    //         'class_a': {'predicted': {'class_a': 20, 'class_b': 5, 'class_c': 0,'class_d':15,'class_e':16}, 'count': 25},
    //         'class_b': {'predicted': {'class_a': 10, 'class_b': 20, 'class_c': 5,'class_d':15,'class_e':16}, 'count': 35},
    //         'class_c': {'predicted': {'class_a': 0, 'class_b': 3, 'class_c': 17,'class_d':15,'class_e':16}, 'count': 20},
    //         'class_d': {'predicted': {'class_a': 0, 'class_b': 3, 'class_c': 17,'class_d':15,'class_e':16}, 'count': 20},
    //         'class_e': {'predicted': {'class_a': 0, 'class_b': 3, 'class_c': 17,'class_d':15,'class_e':16}, 'count': 20},
    //     }, 'count': {'class_a': 25, 'class_b': 35, 'class_c': 20,class_d: 22, class_e:10}
    // };


    const sort_unique = (arr:Array<number>)=> {
        if (arr.length === 0) return arr;
        arr = arr.sort(function (a, b) { return a*1 - b*1; });
        let ret = [arr[0]];
        for (let i = 1; i < arr.length; i++) { //Start loop at 1: arr[0] can never be a duplicate
            if (arr[i-1] !== arr[i]) {
                ret.push(arr[i]);
            }
        }
        return ret;
    }

    let matrixIndexes:Array<string> = [];
    let valueArrays:Array<number> = [];
    for (const property in rawMatrixData) {  // const k: string
        if (property == "actual") {
            const data:any = rawMatrixData[property];
            for (const classes in data) {
                const values = data[classes]['predicted'];
                for(const valueIndexes in values)
                {
                    valueArrays.push(values[valueIndexes]);
                    //console.log(values[valueIndexes]);
                }

            }
        }
        if (property == "count") {
            const indexCounts = rawMatrixData[property];
            for (const indexes in indexCounts) {
                matrixIndexes.push(indexes);
            }
        }
    }
    valueArrays = sort_unique(valueArrays);
    //console.log(valueArrays);
    const actualData:any = rawMatrixData.actual;
    const yTicks = matrixIndexes.length*2+1; // how many ticks in the side bar
    const min = valueArrays[0]; // min value of the side bar
    let max = valueArrays[valueArrays.length-1] + 10; // max value of the side bar
    //console.log((max-min)/yTicks);
    const eachTick = parseInt(((max-min)/yTicks).toFixed(0));
    //console.log(min,max,yTicks,eachTick);
    max = eachTick*yTicks;
    const colorSteps = (max-min)+2; //both included just for safety
    let scales:Array<any>=[];
    for(let i=yTicks;i>=0;i--)
    {
        scales.push(min+(i*eachTick));
    }


   // console.log(actualData);
    let colors = ColorSteps.getColorSteps('#08205C', '#f7c82e', colorSteps);
    //console.log(colors);

    return (
        <div className="conMatrixWrap">
            <div className="conMatLeft">
                <span className="conLeftHead">Ground <br/> Truth</span>
            </div>
            <div className="conMatMiddle">
                <div className="conMidTop"><span>{props.title ? props.title : ""}</span></div>
                <div className="conMidBoxWrap">
                    {
                        matrixIndexes.map((key, index) => {

                            return (
                                <div className="cbRow" key={"column_" + key}>
                                    <div className="cbCell firstCell"><span>{key} &#8212;</span></div>
                                    {
                                        matrixIndexes.map((rowKey, rowindex) => {
                                            const cellValue = actualData[key]['predicted'][rowKey];
                                            const colorIndex = cellValue-min;
                                            const cellColor = colors[colorIndex];
                                            return (<div key={"row_" + rowKey} className="cbCell"
                                                         style={{backgroundColor: cellColor}}><span> {cellValue}</span></div>);
                                        })
                                    }
                                </div>
                            );

                        })
                    }
                    <div className="cbRow lastRow">
                        <div className="cbCell firstCell"></div>
                        {
                            matrixIndexes.map((key, index) => {
                                return (
                                    <div key={"legend_" + key} className="cbCell"><span>&#124;<br/>{key}</span></div>);
                            })
                        }
                    </div>
                </div>
                <div className="conMidBottom"><span>Predicted</span></div>
            </div>
            <div className="conMatRight">
                <div className="conMatIndexBar"  style={{height: matrixIndexes.length*100+"px"}}></div>
                <div className="conIndValWrap" style={{height: matrixIndexes.length*100+"px"}}>
                    {
                        scales.map((scaleValue)=>{
                            return (<span key={"scale_"+scaleValue}>&#8212; {scaleValue}</span>)
                        })
                    }
                </div>
            </div>
        </div>
    );
}

export default ConfusionMatrix;
