import React, { useEffect, useState } from "react";
import { FaLineData, TRocCurvesProps } from "./RocCurves.types";
import { Line } from "react-chartjs-2";

const RocCurves = (props: TRocCurvesProps) => {
  const data = props.data;
  const maxFAR = props.max;
  //const maxFAR = props.data.max ? props.data.max : 0;
  delete data.max;
  const faLineOptions = {
    responsive: true,
    height: 400,
    width: 610,
    scales: {
      y: {
        title: {
          display: true,
          text: "False Reject Rate %",
        },
      },

      x: {
        title: {
          display: true,
          text: "False Alarm",
        },
        ticks: {
          stepSize: 5, // To show each label instead of every nth label
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: props.title ? props.title : "",
        font: {
          size: 16,
        },
      },
      legend: {
        position: "bottom" as const,
      },
      zoom: {
        zoom: {
          wheel: {
            enabled: false,
          },
          pinch: {
            enabled: false,
          },
          mode: "xy" as "xy",
        },
      },

      tooltip: {
        callbacks: {
          label: function (context: any) {
            let label = context.dataset.label.split("_");
            label = label[0] + "_" + label[1] + label[2];
            return (
              label +
              ": " +
              "FA=" +
              context.chart.data.datasets[context.datasetIndex].data[
                context.dataIndex
              ][0] +
              " FR=" +
              Math.floor(
                context.chart.data.datasets[context.datasetIndex].data[
                  context.dataIndex
                ][1]
              ) +
              " TH=" +
              context.chart.data.datasets[context.datasetIndex].data[
                context.dataIndex
              ][2]
            );
          },
        },
      },
    },
  };

  const labels = [];
  for (let i = 0; i <= maxFAR; i++) {
    labels.push(i);
  }

  let lineData = [];
  for (const key in data) {
    const value: Array<FaLineData> = data[key as keyof typeof data];

    const chartData = value.map((item) => {
      return [item.FAR, item.FRR * 100, item.threshold];
    });

    const labelData = value.map((item) => {
      return item.threshold;
    });
    lineData.push({
      label: key,
      borderColor:
        "#" + (0x1000000 + Math.random() * 0xffffff).toString(16).substr(1, 6),
      data: chartData,
    });

    // ~~~~~~ Element implicitly has an 'any' type
    //        because type ... has no index signature
  }
  const faLineData = {
    labels: labels,
    datasets: lineData,
  };

  return (
    <div>
      <Line options={faLineOptions} data={faLineData} />
    </div>
  );
};

export default RocCurves;
