import './Collect.css'
import React, {useEffect,createContext,useState} from "react";
import {TCollectProps} from "./Collect.types";
import Wizard from "../../common/Wizard/Wizard";
import {WizardNavigationTabStates} from "../../common/Wizard/WizardNavigation/WizardNavigation.types";
import {TWizardBodyType} from "../../common/Wizard/Wizard.types";
import checkedGreen from "../../../../assets/images/checked-green-fill.svg";
import playIcon from "../../../../assets/images/play-white.svg";
import TabView from "../../common/TabView/TabView";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import CodeEditor from "../../common/CodeEditor/CodeEditor";

import zipIconWhite from "../../../../assets/images/zip.svg"
import whitePlayIconWhite from "../../../../assets/images/play-white.svg"
import speakerIconWhite from "../../../../assets/images/speaker-outline.svg"
import linkIconWhite from "../../../../assets/images/link-outline.svg"
import playIconWhite from "../../../../assets/images/play-outline.svg"
import pauseIconWhite from "../../../../assets/images/pause-outline.svg"
import deleteIconWhite from "../../../../assets/images/delete-outline.svg"
import colspeakerIconWhite from "../../../../assets/images/collect-speaker.svg"
import recordIconWhite from "../../../../assets/images/record.svg"
import playgreyIconWhite from "../../../../assets/images/play-grey.svg"
import CollectModal from "./CollectModal/CollectModal";
import Cutter from "../Cutter/Cutter";
import { toast } from 'react-toastify';
import DeleteModal from "../../common/DeleteModal/DeleteModal";

import LaunchingModal from "../../session/Setup/LaunchingModal/LaunchingModal";

import dataSourcesApi from "../../../../services/data-sources";
import useApi from "../../../../services/Base/useApi";

export const CollectModalContext = createContext({
    collectModal:false,
    setCollectModal:(state:boolean)=>{},
    cutterModal:false,
    setCutterModal:(state:boolean)=>{},
    numSamples:0,
    name:"",
    setName:(name:string)=>{},
    setUtterances:(utterances:any)=>{},
    utterances:[],
    file:{file:"",blob:""},
    setFile:(file:any) => {},
    trimAudio:(start:number, end:number)=> {},
    keyword: "",
    reset:()=>{}
});

const Collect = (props: TCollectProps) => {
  //=======================================
  //STATE
  //=======================================
    const [loading,setLoading] = useState(false);
    const [deleteModal,setDeleteModal] = useState(false);
    const [cutterModal,setCutterModal]=useState(false);
    const [collectModal,setCollectModal]=useState(false);
    const [folder,setFolder] = useState("")
    const [name,setName] = useState("");
    const [keyword,setKeyword] = useState("");
    const [keywordType,setKeywordType] = useState("Normal Keyword");
    const [roomSize,setRoomSize] = useState("Small");
    const [field,setField] = useState("Near");
    const [numSamples,setNumSamples] = useState(0);
    const [conditions,setConditions] = useState("");
    const [gender,setGender] = useState("");
    const [utterances,setUtterances] = useState([]);

    const [isChecked, setIsChecked] = useState(false);

    const [modalStep, setModalStep] = useState(0);

    const[file,setFile] = useState({file:"",blob:"",index:0});
    //=======================================
    //HOOKS
    //=======================================
    const collectApi = useApi(dataSourcesApi.postDataCollection);
    const trimApi = useApi(dataSourcesApi.putTrimAudio);

    useEffect(() => {
      if(trimApi.loading === false && !trimApi.error && trimApi.data){
        let temp = [...utterances];
        //@ts-ignore
        const blob = base64ToBlob(trimApi.data.audio)
        const url = URL.createObjectURL(blob);
        //@ts-ignore
        temp[file.index] = {...temp[file.index],audio:url, blob};
        setUtterances(temp)
        setCutterModal(false);
        setFile({file:"",blob:"",index:0})
        toast.success("Saved changes")
      }
    }, [trimApi.loading, trimApi.data, trimApi.error]);


    useEffect(()=>{
      if(collectApi.loading === false && !collectApi.error && collectApi.data){
        reset();
        setLoading(false);
        toast.success("Your data was successfully uploaded")
      }
    },[collectApi.loading, collectApi.data, collectApi.error])

    //=======================================
    //HANDLERS
    //=======================================

    const uploadData = () =>{
      const formData = new FormData();
      if (utterances) {
        for (let i = 0; i < utterances.length; i++) {
          formData.append('files', utterances[i]['blob'], "sample_"+i+".wav");
        }
        formData.append('folder',folder);
        formData.append('name',name);
        formData.append('keyword',keyword);
        formData.append('keywordType',keywordType);
        formData.append('roomSize',roomSize);
        formData.append('field',field);
        formData.append('conditions',conditions);
        formData.append('gender',gender);
        collectApi.request(formData);
        setLoading(true);
      }
    }

    const startCollection =(event:React.FormEvent)=>{
        event.preventDefault();
        let message = ""
        if(!keyword){
          message = "Keyword is required"
        }else if(!field){
          message = "Field is required"
        }else if(!conditions){
          message = "Conditions is required"
        }else if(!gender){
          message = "Gender is required"
        }else if(numSamples <= 0 || !numSamples){
          message = "Number of utterances must be greater than 0"
        }

        message ? toast.error(message) : setCollectModal(true);
    }

    const onDelete = (index: number) =>{
      let temp = [...utterances];
      temp.splice(index,1);
      setUtterances(temp);
      toast.success("Saved changes")
    }

    const onEdit = (index: number) =>{
      let blob = utterances[index]['blob']
      const reader = new FileReader();
      reader.readAsDataURL(blob);
      reader.onloadend = function() {
          let base64data = reader.result;
          //@ts-ignore
          setFile({file:base64data,blob,index})
      }
      setCutterModal(true)
    }

    const trimAudio = (start:number, end: number) =>{
      const data = new FormData();
      data.append('file',file.blob);
      data.append('start',start.toString());
      data.append('end',end.toString())
      trimApi.request(data);
    }

    const base64ToBlob = (base64:string) =>{
      const binaryString = atob(base64);
      const len = binaryString.length;
      const bytes = new Uint8Array(len);
      for (let i = 0; i < len; i++) {
          bytes[i] = binaryString.charCodeAt(i);
      }
      return new Blob([bytes.buffer], { type: 'audio/wav' });
   }

    const reset = () => {
      setFolder("")
      setKeyword("")
      setNumSamples(0)
      setRoomSize("Small")
      setField("Near")
      setKeywordType("Normal Keyword")
      setConditions("")
      setGender("")
      setUtterances([])
      setDeleteModal(false)
    }

    const submitStep1 = (event: React.FormEvent) => {
      event.preventDefault();
      if (name && isChecked) setModalStep(1);

    }

    const contextValue = {
        collectModal: collectModal,
        setCollectModal: setCollectModal,
        name: name,
        setName: setName,
        setUtterances: setUtterances,
        numSamples: numSamples,
        utterances:utterances,
        file: file,
        setFile: setFile,
        cutterModal: cutterModal,
        setCutterModal: setCutterModal,
        trimAudio: trimAudio,
        keyword: keyword,
        reset: reset
    };

    return (
      <CollectModalContext.Provider value={contextValue}>
        <div>
        {modalStep == 0 && <div className="modalOverlay">
          <div className="modalWrapper">

            <form onSubmit={submitStep1}>
              <div className="modalWrapperHeader">
                <span>Data Collection</span>
              </div>
              <div className="modalWrapperBody">
                <div className="modalContWrapper">
                  <h2>Agreement</h2>
                  <p>Please read the following important information before continuing.</p>
                  <p>Please read the following Agreement. You must accept the terms of this agreement before continuing.</p>
                  <p>Please note that information provided by you in connection with using this tool, such as your voice or motion sensor recordings that we make (and may store on our servers) when you teach or use voice commands or motion sensor actions will be used by our company for non-commercial or commercial purposes, and you are giving the company a perpetual license to do so.</p>
                </div>
                <div className="gap30"></div>
                <div className="customRow">
                  <div className="customColumn-12">
                    <input onChange={(e) => setName(e.target.value)} type="text" className="inputTextModal" placeholder="Enter Name" />
                  </div>
                </div>
                <div className="gap30"></div>
                <div className="customRow">
                  <div className="customColumn-12 text-center">
                    <input onChange={(e) => setIsChecked(e.target.checked)} checked={isChecked} type="checkbox" id="termsAcpt" name="termsAcpt" value="" />
                    <label htmlFor="termsAcpt"> I accept the agreement</label>
                  </div>
                </div>
                <div className="gap30"></div>
                <div className="customRow">
                  <div className="customColumn-12 text-center">
                    <button type={"submit"} className="btnCustom btnGrey modalButton">Next</button>
                  </div>
                </div>
              </div>

            </form>
          </div>
        </div>}

          {
            utterances.length > 0 && <div className="sectionHead">
                   <div className="flex-align-center"></div>
                   <div className="btnHolder flex-align-center">
                     <a onClick={uploadData}className="btnCustom btnGrey">+ Upload</a>
                     <a onClick={()=>setDeleteModal(true)} className="btnCustom btnRed">Delete</a>
                   </div>

                  </div>
          }

            <div className="customRow align-top">
              {
                loading
                  ?<div style={{width:'100%'}}>
                    <LaunchingModal title={'Uploading Data'} subtitle={'Your data is uploading. This will take a few seconds.'}/>
                  </div>
                  : utterances.length > 0
                    ?<div className="customColumn-12">
                      <div className="cardWhite">
                          <div className="cardWhiteTitleWrap">
                              <h3 style={{margin:0,marginBottom:5}}>{folder}</h3>
                              <h4 style={{margin:0,color:'#777'}}>{keyword}</h4>
                          </div>
                          <div className="cardWhiteBodyWrap padding-0">
                              <div className="activeCardContent">
                                  <div className="activeCardHead">
                                      <div className="activeCardHeadBox">
                                          <h5>Title</h5>
                                      </div>
                                      <div className="activeCardHeadBox">
                                          <h5>Audio</h5>
                                      </div>
                                      <div className="activeCardHeadBox">
                                          <h5>Edit</h5>
                                      </div>
                                  </div>
                                  <div className="activeCardBody collectTableBody">

                                      {
                                         utterances.map((acData:any,index)=>{
                                             return (
                                                 <div key={index} className="activeCardRow">
                                                      <div className="activeCardBodyBox">
                                                          <h6>{"Sample_"+ acData.sampleNum}</h6>
                                                      </div>
                                                      <div className="activeCardBodyBox activeCardBodyIconBox">
                                                          <audio controls src={acData.audio}/>
                                                      </div>
                                                      <div className="activeCardBodyBox activeCardBodyIconBox">
                                                          <button onClick={()=>onEdit(index)}className="buttonIconBox">
                                                              <img src={linkIconWhite} alt="speaker" />
                                                          </button>
                                                          <button onClick={()=>onDelete(index)}className="buttonIconBox">
                                                              <img src={deleteIconWhite} alt="speaker" />
                                                          </button>
                                                      </div>
                                                 </div>
                                             )
                                         })
                                      }
                                  </div>
                              </div>
                          </div>
                      </div>
                      {deleteModal && <DeleteModal title="Collection" subtitle="Are you sure you want to delete this collection? Doing this will delete all your recordings." onCancel={()=>setDeleteModal(false)} onDelete={reset}/>}
                  </div>
                  :<div className="customColumn-12">
                    <div className="cardWhite">
                        <div className="cardWhiteTitleWrap">
                            <h3 className="cardWhiteHeading">Setup</h3>
                        </div>
                        <div className="cardWhiteBodyWrap">
                            <form onSubmit={startCollection}>

                            <div className="setupCardContent">
                              <div className="customRow m-b-20">
                                  <div className="customColumn-4 ">
                                      <span className="formLabel">Folder</span>
                                  </div>
                                  <div className="customColumn-8">
                                  <input onChange={(e)=>setFolder(e.target.value)} value={folder} type="text" className="inputText" />
                                  </div>
                              </div>
                              <div className="customRow m-b-20">
                                  <div className="customColumn-4 ">
                                      <span className="formLabel">Keyword Type</span>
                                  </div>
                                  <div className="customColumn-8">
                                      <select onChange={(e)=>setKeywordType(e.target.value)} value={keywordType} name="" id="" className="selectWrap">
                                          <option value="Audio">Normal Keyword</option>
                                          <option value="Video">Normal Keyword</option>
                                      </select>
                                  </div>
                              </div>
                              <div className="customRow m-b-20">
                                  <div className="customColumn-4 ">
                                      <span className="formLabel">Keyword</span>
                                  </div>
                                  <div className="customColumn-8">
                                  <input onChange={(e)=>setKeyword(e.target.value)} value={keyword} type="text" className="inputText" />
                                  </div>
                              </div>
                              <div className="customRow m-b-20">
                                  <div className="customColumn-4 ">
                                      <span className="formLabel">Room Size</span>
                                  </div>
                                  <div className="customColumn-8">
                                      <select onChange={(e)=>setRoomSize(e.target.value)} value={roomSize} name="" id="" className="selectWrap">
                                          <option value="Small">Small</option>
                                          <option value="Mediumn">Mediumn</option>
                                          <option value="Large">Large</option>
                                      </select>
                                  </div>
                              </div>
                              <div className="customRow m-b-20">
                                  <div className="customColumn-4 ">
                                      <span className="formLabel">Field</span>
                                  </div>
                                  <div className="customColumn-8">
                                      <select onChange={(e)=>setField(e.target.value)} value={field} name="" id="" className="selectWrap">
                                          <option value="Near">Near</option>
                                      </select>
                                  </div>
                              </div>
                              <div className="customRow m-b-20">
                                  <div className="customColumn-4 ">
                                      <span className="formLabel">Utterances</span>
                                  </div>
                                  <div className="customColumn-8">
                                      <input onChange={(e)=>setNumSamples(parseInt(e.target.value))} value={numSamples} type="number" className="inputText inputTextfocus" placeholder="10" />
                                  </div>
                              </div>
                              <div className="customRow m-b-20">
                                  <div className="customColumn-4 ">
                                      <span className="formLabel">Conditions</span>
                                  </div>
                                  <div className="customColumn-8">
                                      <div className="radioWrapper">
                                          <input onChange={(e)=>setConditions(e.target.value)} checked={conditions == 'Clean'}type="radio" id="clean" name="backhroundField" value="Clean"/>
                                          <label htmlFor="clean">Clean</label>
                                          <input onChange={(e)=>setConditions(e.target.value)} checked={conditions == 'Noisy'} type="radio" id="noisy" name="backhroundField" value="Noisy"/>
                                          <label htmlFor="noisy">Noisy</label>
                                      </div>
                                  </div>
                              </div>
                              <div className="customRow m-b-20">
                                  <div className="customColumn-4 ">
                                      <span className="formLabel">Gender</span>
                                  </div>
                                  <div className="customColumn-8">
                                      <div className="radioWrapper">
                                          <input onChange={(e)=>setGender(e.target.value)} checked={gender == 'Male'} type="radio" id="male" name="genderField" value="Male"/>
                                          <label htmlFor="male">Male</label>
                                          <input onChange={(e)=>setGender(e.target.value)} checked={gender == 'Female'} type="radio" id="female" name="genderField" value="Female"/>
                                          <label htmlFor="female">Female</label>
                                      </div>
                                  </div>
                              </div>

                                <div className="formLine text-right">
                                    <button type="submit" className="btnCustom btnGreen"><img src={whitePlayIconWhite} alt="icon" /> Start</button>
                                </div>

                            </div>
                            </form>
                        </div>
                    </div>
                </div>
              }



            </div>


            { collectModal && <CollectModal/> }
            { cutterModal && <Cutter/> }

        </div>
      </CollectModalContext.Provider>
    );
}

export default Collect;
