import React,{useState,useEffect} from "react";
import { TOutputProps } from "./Output.types";
import CodeEditor from "../../common/CodeEditor/CodeEditor";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import TabView from "../../common/TabView/TabView";
import DataTable from 'react-data-table-component';
import zipIconWhite from "../../../../assets/images/zip.svg"
import downloadIconGrey from '../../../../assets/images/download_grey.svg'
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import dataSourcesApi from "../../../../services/data-sources";
import useApi from "../../../../services/Base/useApi";
import { toast } from 'react-toastify';

const Output = (props: TOutputProps) => {

  //==================================
  //STATE
  //===================================
  const [dataOptions, setDataOptions] = useState({
    OUTPUT:[],
    TEST_OUTPUT:[]
  });

  //==================================
  //HOOKS
  //===================================
  const getDataOptionsApi = useApi(dataSourcesApi.getDataSource);
  const getSessionOutputApi = useApi(dataSourcesApi.getSessionOutput);
  const getTestOutputApi = useApi(dataSourcesApi.getTestOutput);

  useEffect(()=>{
    getDataOptionsApi.request("aws_s3_directories_output")
  },[])

  useEffect(() =>{
    //set data options
    if(getDataOptionsApi.loading === false && !getDataOptionsApi.error && getDataOptionsApi.data){
      setDataOptions(getDataOptionsApi.data)
    }

  },[getDataOptionsApi.loading, getDataOptionsApi.error, getDataOptionsApi.data ])

  useEffect(() =>{
    //set data options
    if(getTestOutputApi.loading === false && !getTestOutputApi.error && getTestOutputApi.data){
      // Create a temporary link element
      const objectUrl = 'data:application/zip;base64,'+ getTestOutputApi['data']['isdkOutput']
      const link = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);

      // Set the link's URL and download attribute
      link.href = objectUrl
      link.setAttribute("download", getTestOutputApi['data']['isdkName']);

      // Trigger a click on the link to start the download
      link.click();

      // Clean up the temporary link element and object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(objectUrl);

    }else if(getTestOutputApi.loading === false && getTestOutputApi.error){
      toast.error("An unexpected error occured while downloading your data.")
      getTestOutputApi.clearError()
    }

  },[getTestOutputApi.loading, getTestOutputApi.error, getTestOutputApi.data ])

  useEffect(() =>{
    //set data options
    if(getSessionOutputApi.loading === false && !getSessionOutputApi.error && getSessionOutputApi.data){
      // Create a temporary link element
      const objectUrlTsdk = 'data:application/zip;base64,'+ getSessionOutputApi['data']['tsdkOutput']
      const link = document.createElement("a");
      link.style.display = "none";
      document.body.appendChild(link);

      // Set the link's URL and download attribute
      link.href = objectUrlTsdk
      link.setAttribute("download", getSessionOutputApi['data']['tsdkName']);

      // Trigger a click on the link to start the download
      link.click();

      // Clean up the temporary link element and object URL
      document.body.removeChild(link);
      window.URL.revokeObjectURL(objectUrlTsdk);

      if(getSessionOutputApi['data']['isdkOutput']){
        const objectUrlIsdk = 'data:application/zip;base64,'+ getSessionOutputApi['data']['isdkOutput']
        const link2 = document.createElement("a");
        link2.style.display = "none";
        document.body.appendChild(link2);
        // Set the link's URL and download attribute
        link2.href = objectUrlIsdk
        link2.setAttribute("download", getSessionOutputApi['data']['isdkName']);

        // Trigger a click on the link to start the download
        link2.click();


        window.URL.revokeObjectURL(objectUrlIsdk);
        document.body.removeChild(link2);
      }

    }else if(getSessionOutputApi.loading === false && getSessionOutputApi.error){
      toast.error("An unexpected error occured while downloading your data.")
      getSessionOutputApi.clearError()
    }

  },[getSessionOutputApi.loading, getSessionOutputApi.error, getSessionOutputApi.data ])


  const onDownload = (path: string, tab: string) =>{
    if(tab === 'test'){
      getTestOutputApi.request(path)
    }else if(tab === 'session'){
      getSessionOutputApi.request(path)
      toast.success("Your data is downloading, please wait a few seconds.")
    }
  }

  let columns:any = [
      {
          name: 'Name',
          selector: (row: any) => row.name,
      },
      {
          name: 'Last Modified',
          selector: (row: any) => row.modified,
      },
  ];

    const outputSessionDT = () => {
      let c = [...columns,
            {
                name: 'Download',
                button: true,
                cell: (row:any) => (
                    <div className="tblIconUtlWrap">
                        <button onClick={(e) => onDownload(row.name,'session')} className="tblUtlIcon"><img src={downloadIconGrey} alt="edit grey" /></button>
                    </div>
                )
            }
          ]

        return (
                <DataTable
                    columns={c}
                    data={dataOptions.OUTPUT}
                    customStyles={DTStyles}
                />
        )
    }

    const testOutputSessionDT = () => {
      let c = [...columns,
            {
                name: 'Download',
                button: true,
                cell: (row:any) => (
                    <div className="tblIconUtlWrap">
                        <button onClick={(e) => onDownload(row.name,'test')} className="tblUtlIcon"><img src={downloadIconGrey} alt="edit grey" /></button>
                    </div>
                )
            }
          ]
        return (
                <DataTable
                    columns={c}
                    data={dataOptions.TEST_OUTPUT}
                    customStyles={DTStyles}
                />
        )
    }

    const headers = [{
        title: "Session"
    },
    {
        title: "Test"
    },

];

    const body = [
        <div>{outputSessionDT()}</div>,
        <div>{testOutputSessionDT()}</div>,
    ];

if(!getDataOptionsApi.loading){
  return (
      <div className="globalCard">
          <TabView
            footer={false}
            header={headers}
            body={body}/>
      </div>
  )
}else{
  return <div className="loading">
      <div className="loading-spinner"></div>
    </div>
}


    // return <>Output</>;
}

export default Output;
