import React, { useRef, useEffect } from "react";

import { toast } from 'react-toastify';

import authApi from "../../../../services/authentication";
import useApi from "../../../../services/Base/useApi";

import { Link,useNavigate } from 'react-router-dom';

const Support = () => {
  const loginFormRef = useRef() as React.MutableRefObject<HTMLFormElement>;
  const emailInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const subjectInputRef = useRef() as React.MutableRefObject<HTMLInputElement>
  const contentInputRef = useRef() as React.MutableRefObject<HTMLTextAreaElement>

  const supportApi = useApi(authApi.postSupportEmail)
  const navigate = useNavigate()

  useEffect(()=>{
    if(supportApi.loading === false){
      if(supportApi.data){
        navigate("/admin/support/request-sent")
      }else if(supportApi.error){
        toast.error(supportApi.error)
        supportApi.clearError()
      }
    }
  },[supportApi.loading,supportApi.error,supportApi.data])

  const submitHandler = async (event:React.FormEvent) => {
      event.preventDefault();


      const enteredEmail = emailInputRef.current.value;
      const enteredSubject = subjectInputRef.current.value;
      const enteredContent = contentInputRef.current.value;

      // optional: Add validation and call API to perform login
      if(!enteredEmail || !enteredSubject || !enteredContent) return;
      const data = { email: enteredEmail, subject: enteredSubject, content: enteredContent};
      supportApi.request(data);

  };

    return (
        <div className="authPageWrap">
            <div className="authCreateWrapper">
                <h2 className="registrationHead p-t-40 text-center">What can we help you with?</h2>
                <form ref={loginFormRef} onSubmit={submitHandler}>
                <div className="customRow">
                    <div className="customColumn-12">

                            <div className="customRow">
                                <div className="customColumn-12  m-b-20">
                                    <label className="formLabel font-bold">Email Address<span>*</span></label>
                                    <div className="gap10"></div>
                                    <input ref={emailInputRef} type="text" className="inputText authInput" placeholder="name@company.com"/>
                                </div>
                            </div>

                    </div>
                    <div className="customColumn-12">

                            <div className="customRow">
                                <div className="customColumn-12  m-b-20">
                                    <label  className="formLabel font-bold">Subject <span>*</span></label>
                                    <div className="gap10"></div>
                                    <input ref={subjectInputRef} type="text" className="inputText authInput" placeholder="I would like help with ..."/>
                                </div>
                            </div>

                    </div>

                    <div className="customColumn-12">

                            <div className="customRow">
                                <div className="customColumn-12  m-b-20">
                                    <label  className="formLabel font-bold">Content<span>*</span></label>
                                    <div className="gap10"></div>
                                    <textarea ref={contentInputRef} rows={14} style={{width:'100%'}} className="textArea" placeholder="Write your questions here"></textarea>
                                </div>
                            </div>

                    </div>
                </div>
                <div className="customRow m-b-20">
                    <div className="customColumn-12 text-center">
                        <div className="gap30"></div>
                    </div>
                    <div className="customColumn-12 text-center">
                        <div className="customColumn-12  m-b-20 text-center">
                            <button type="submit" className="btnCustom btnYellowGradient authButton">Submit Request</button>
                        </div>
                    </div>
                    <div className="customColumn-12 text-center">
                        <div className="gap30"></div>
                    </div>
                </div>
              </form>
            </div>
        </div>
    );
}

export default Support;
