import React, { useContext } from "react";
import { TSideBarProps } from "./SideBar.types";
import "./SideBar.css";
import logo from "../../../../assets/images/aon-logo.png";
import dashboardIcon from "../../../../assets/images/dashboard-blue.svg";
import datasetIcon from "../../../../assets/images/dataset-blue.svg";
import sessionIcon from "../../../../assets/images/session-blue.svg";
import testIcon from "../../../../assets/images/test-blue.svg";
import outputIcon from "../../../../assets/images/output-blue.svg";
import documentIcon from "../../../../assets/images/document-icon.svg";
import supportIcon from "../../../../assets/images/support-icon.svg";
import logoutIcon from "../../../../assets/images/logout-icon.svg";
import copyrightLogo from "../../../../assets/images/sidebar-footer-logo.svg";
import { Link } from "react-router-dom";
import AuthContext from "../../../../store/auth-context";
import { useNavigate } from "react-router-dom";

const SideBar = (props: TSideBarProps) => {
  let section = props.section;
  let page = props.page;
  let navigate = useNavigate();
  const authCtx = useContext(AuthContext);

  const logout = () => {
    authCtx.logout();
    navigate("/login", { replace: true });
  };

  return (
    <div className="sidebarIn">
      <div className="topLogo">
        <img src={logo} className="logoImg" />
      </div>
      <div className="sidenavTop">
        <div className="navItemWrap">
          {/*
                     <div className="navItem">
                         <Link to="/admin/dashboard"
                               className={page == 'dashboard' ? "navItemLink active" : "navItemLink"}>
                             <img className="topSImg" src={dashboardIcon} alt="dashboard"/>
                             <span>Dashboard</span>
                         </Link>
                     </div>
                     */}
          <div className="navItem">
            <Link
              to="/admin/dataset"
              className={
                section == "dataset" ? "navItemLink activeGroup" : "navItemLink"
              }
            >
              <img className="topSImg" src={datasetIcon} alt="dataset" />
              <span>Dataset</span>
            </Link>
            {section == "dataset" && (
              <div className="navChildren">
                {
                  /*
                  <Link
                    to="/admin/dataset/collect"
                    className={
                      page == "dataset/collect"
                        ? "navItemLink active navChildItem"
                        : "navItemLink navChildItem"
                    }
                  >
                    <img className="topSImg" src={datasetIcon} alt="collect" />
                    <span>Collect</span>
                  </Link>
                  */
                }

                <Link
                  to="/admin/dataset/store"
                  className={
                    page == "dataset/store"
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={datasetIcon} alt="store" />
                  <span>Store</span>
                </Link>
              </div>
            )}
          </div>
          <div className="navItem">
            <Link
              to="/admin/session"
              className={
                section == "session" ? "navItemLink activeGroup" : "navItemLink"
              }
            >
              <img className="topSImg" src={sessionIcon} alt="session" />

              <span>Session</span>
            </Link>
            {section == "session" && (
              <div className="navChildren">
                <Link
                  to="/admin/session/setup"
                  className={
                    page.startsWith("session/setup")
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={sessionIcon} alt="Setup" />
                  <span>Setup</span>
                </Link>
                <Link
                  to="/admin/session/monitor"
                  className={
                    page.startsWith("session/monitor")
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={sessionIcon} alt="Monitor" />
                  <span>Monitor</span>
                </Link>
                <Link
                  to="/admin/session/review"
                  className={
                    page.startsWith("session/review")
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={sessionIcon} alt="Review" />
                  <span>Review</span>
                </Link>
              </div>
            )}
          </div>
          <div className="navItem">
            <Link
              to="/admin/test"
              className={
                section == "test" ? "navItemLink activeGroup" : "navItemLink"
              }
            >
              <img className="topSImg" src={testIcon} alt="Test" />
              <span>Test</span>
            </Link>
            {section == "test" && (
              <div className="navChildren">
                <Link
                  to="/admin/test/setup"
                  className={
                    page.startsWith("test/setup")
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={testIcon} alt="Setup" />
                  <span>Setup</span>
                </Link>
                <Link
                  to="/admin/test/monitor"
                  className={
                    page.startsWith("test/monitor")
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={testIcon} alt="Monitor" />
                  <span>Monitor</span>
                </Link>
                <Link
                  to="/admin/test/review"
                  className={
                    page.startsWith("test/review")
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={testIcon} alt="Review" />
                  <span>Review</span>
                </Link>
                <Link
                  to="/admin/test/command-sequence"
                  className={
                    page.startsWith("test/command-sequence")
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={testIcon} alt="Review" />
                  <span>Command Sequence</span>
                </Link>
                <Link
                  to="/admin/test/api-data"
                  className={
                    page.startsWith("test/api-data")
                      ? "navItemLink active navChildItem"
                      : "navItemLink navChildItem"
                  }
                >
                  <img className="topSImg" src={testIcon} alt="Review" />
                  <span>Api Data</span>
                </Link>
              </div>
            )}
          </div>
          <div className="navItem">
            <Link
              to="/admin/output"
              className={
                section == "output" ? "navItemLink activeGroup" : "navItemLink"
              }
            >
              <img className="topSImg" src={outputIcon} alt="dds" />
              <span>Output</span>
            </Link>
          </div>
        </div>
      </div>
      <div className="sidenavBottom">
        <h3 className="sidenavHeadiung">GETTING STARTED</h3>
        <div className="navItemWrap">
          <div className="navItem">
            <a
              href="https://aondevices.com/documentations/"
              target="_blank"
              className="navItemLink navBtmLink"
            >
              <img className="btmSImg" src={documentIcon} alt="dds" />
              <span>Documentations</span>
            </a>
          </div>
          <div className="navItem">
            <Link
              to="/admin/support/"
              className={
                section == "support" ? "navItemLink activeGroup" : "navItemLink"
              }
            >
              <img className="btmSImg" src={supportIcon} alt="dds" />
              <span>Support</span>
            </Link>
          </div>
          <div className="navItem">
            <Link to="" onClick={logout} className="navItemLink navLogoutLink">
              <img src={logoutIcon} alt="dds" />
              <span>Logout</span>
            </Link>
          </div>
        </div>
        <img className="footerLogo" src={copyrightLogo} />
      </div>
    </div>
  );
};

export default SideBar;
