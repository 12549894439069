import React, {useRef,useEffect} from "react";
import { TConsoleWindowProps } from "./ConsoleWindow.types";
import './ConsoleWindow.css'

const ConsoleWindow = (props: TConsoleWindowProps) => {
    const messagesEndRef = useRef(null);
    const scrollToBottom = () => {
       //@ts-ignore
       messagesEndRef.current.scrollIntoView({ behavior: "smooth" })
     }

     useEffect(() => {
          scrollToBottom()

     }, [messagesEndRef.current]);


    // return (
    //     <div className="consoleWrap">
    //         <div className="cWIndowHolder">
    //             <pre className="cWIndow" >{props.text}
    //             </pre>
    //         </div>
    //     </div>);
    return (
      <>
        <pre className="cWIndow" >{props.text}
          <div style={{ float:"left", clear: "both" }}
               ref={messagesEndRef}>
        </div>
        </pre>
      </>
    );

}
export default ConsoleWindow;
