import React, {useState} from "react";
import {TTabViewProps} from "./TabView.types";

const TabView = (props: TTabViewProps) => {
    const header = props.header;
    const body = props.body;
    const onNext = props.onNext;
    const onBack = props.onBack;
    const title = props.title;
    let active = props.activeIndex ? props.activeIndex : 0;
    let footer = props.footer === false ? false : true
    if (active >= header.length || active >= body.length) {
        active = 0;
    }
    const [activeTab, setActiveTab] = useState(active);

    const changeTab = (index: number) => {
        if (index <= header.length && index <= body.length) {
            setActiveTab(index);
        }
    }

    return (<div className="tabWrapper">
      <h3 className='tabTitle'>{title}</h3>
        <div className="tabHead">
            {
                header.map(function (headerItem, i) {

                    const classname = i == activeTab ? "tabHeadItem active" : "tabHeadItem";
                    return (<div onClick={() => changeTab(i)} key={headerItem.title}
                                 className={classname}> {headerItem.title}</div>)
                })
            }
        </div>
        <div className="tabBody">
            <div className="tabBodyItemWrap">
                {body.map(function (tabItem, i) {
                    const classname = i == activeTab ? "tabBodyItem" : "tabBodyItem hidden";
                    return (
                        <div key={i} className={classname}>{tabItem}</div>)
                })}
            </div>
            {
              footer
                &&
                <div className="flex-justify-end">
                    <div className="flex-align-center">
                        <div className="btnHolder flex-align-center">
                            <a onClick={onBack} className="btnCustom btnBordered">
                                Back
                            </a>
                            <a onClick={onNext} className={onNext ? "btnCustom btnBlue" : "btnCustom btnDisabled"} >
                                Next
                            </a>
                        </div>
                    </div>
                </div>
            }
        </div>
    </div>)
}

export default TabView;
