import React, { useState, useEffect, useRef } from 'react';
import { toast } from "react-toastify";
import testsApi from "../../../../services/tests";
import useApi from "../../../../services/Base/useApi";
import downloadIcon from "../../../../assets/images/download-white.svg";
import LaunchingModal from '../../session/Setup/LaunchingModal/LaunchingModal';

const modeOptions = ["Audio", "AudioBuffer", "None"]
const vadOptions = ["Disabled", "Enable"]
const vadThresholdOptions = ["2","2.0625","2.125","2.1875","2.25","2.3125","2.375","2.4375","2.5","2.5625","3","3.0625", "3.125", "3.1875","3.25","3.3125", "3.375", "3.4375", "3.5"]
const audioBufferEnableOptions = ["Disabled", "Enable"];
const audioBufferTypeOptions = ["Mono", "Stereo"]
const audioBufferModeOptions = ["Single", "Continuous"]
const audioBufferStartOptions = ["Machine", "Manual"]
const gainOptions = ["0", "6", "12", "18", "24", "32"];

const ApiData = () => {
 //=======================================
 //STATE
 //=======================================
 const [ch0State, setCh0State] = useState<{mode: string, weight_file?: File, classification_file?: File}>({mode: "Audio"});
 const [ch1State, setCh1State] = useState<{mode: string, weight_file?: File, classification_file?: File}>({mode: "Audio"});
 const [vadEnable, setVadEnable] = useState("Disabled");
 const [vadThreshold, setVadThreshold] = useState("2.5");
 const [audioBufferEnable, setAudioBufferEnable] = useState("Disabled");
 const [audioBufferType, setAudioBufferType] = useState("Mono");
 const [audioBufferMode, setAudioBufferMode] = useState("Single");
 const [audioBufferStart, setAudioBufferStart] = useState("Machine");
 const [audioBufferEpilog, setAudioBufferEpilog] = useState(16000);
 const [gain, setGain] = useState("24")
 const [output, setOutput] = useState("");
 const [generating,setGenerating] = useState(false);
 const ch0WeightFileRef = useRef(null);
 const ch0ClassificationFileRef = useRef(null);
 const ch1WeightFileRef = useRef(null);
 const ch1ClassificationFileRef = useRef(null);
 const generateApiDataApi = useApi(testsApi.postGenerateApiData);

 //=======================================
//HOOKS
//=======================================
useEffect(() => {
    if (generateApiDataApi.error){
        toast.error("There was an error generating your API Data")
        generateApiDataApi.clearError()
        setGenerating(false)
    }else if (
      !generateApiDataApi.error &&
      generateApiDataApi.loading == false &&
      generateApiDataApi.data
    ) {
        setOutput(
            "data:application/zip;base64,"+generateApiDataApi.data['data']);
        generateApiDataApi.clearData()
        setGenerating(false)
        toast.success("API data created")
    }
  }, [generateApiDataApi.error, generateApiDataApi.loading, generateApiDataApi.data]);

//=======================================
//HANDLERS
//=======================================
const handleFileSelect = (event: any, channel: number, file_type: string) => {
    const file = event.target.files[0];
    if (file) {
        if (channel == 0){
            setCh0State({ ... ch0State, [file_type]: file});
        }else if (channel == 1){
            setCh1State({ ... ch1State, [file_type]: file});
        }
    }
  };

const modifyFileName = (file: File, newName: string) => {
    return new File([file], newName, {
        type: file.type,
        lastModified: file.lastModified,
    });
}

const clearData = () => {
    setOutput("");
    setCh0State({mode:"Audio", weight_file: undefined, classification_file: undefined});
    setCh1State({mode:"Audio", weight_file: undefined, classification_file: undefined});
    setVadEnable("Disabled");
    setVadThreshold("2.5");
    setAudioBufferEnable("Disabled");
    setAudioBufferType("Mono");
    setAudioBufferMode("Single");
    setAudioBufferStart("Machine");
    setAudioBufferEpilog(16000);
}   


const generateData = (e:any) =>{
    e.preventDefault();
    const formData = new FormData();

    if (ch0State.mode == "None" && ch1State.mode == "None"){
        toast.error("At least one channel must be active");
        return;
    }

    if (ch0State.mode != "None" && (!ch0State.weight_file || !ch0State.classification_file)){
        toast.error("Please select a weight and classification map file for channel 0");
        return
    }else if (ch0State.weight_file && ch0State.classification_file){
        formData.append("files", modifyFileName(ch0State.weight_file, "CH0_Coefficient.txt"))
        formData.append("files", modifyFileName(ch0State.classification_file, "CH0_Classification_Map.txt"))
    }

    if (ch1State.mode != "None" && (!ch1State.weight_file || !ch1State.classification_file)){
        toast.error("Please select a weight and classification map file for channel 1");
        return;
    }else if (ch1State.weight_file && ch1State.classification_file){
        formData.append("files", modifyFileName(ch1State.weight_file, "CH1_Coefficient.txt"))
        formData.append("files", modifyFileName(ch1State.classification_file, "CH1_Classification_Map.txt"))
    }

    if (ch0State.mode == "AudioBuffer" || ch1State.mode == "AudioBuffer"){
        if (!audioBufferEpilog || audioBufferEpilog < 0 || audioBufferEpilog > 48000){
            toast.error("Audio buffer epilog must be between 0 - 48000");
            return;
        }
    }

    formData.append("CH0", ch0State.mode);
    formData.append("CH1", ch1State.mode);

    formData.append("VAD", vadEnable);
    formData.append("VAD_THRESHOLD", vadThreshold);

    formData.append("AUDIO_BUFFER_ENABLE", audioBufferEnable);
    formData.append("AUDIO_BUFFER_TYPE",audioBufferType);
    formData.append("AUDIO_BUFFER_MODE", audioBufferMode);
    formData.append("AUDIO_BUFFER_START", audioBufferStart);
    formData.append("AUDIO_BUFFER_EPILOG", audioBufferEpilog.toString());

    formData.append("GAIN", gainValue());


    generateApiDataApi.request(formData);

    setGenerating(true);

}

const gainValue = () =>{
    switch(gain){
        case "0":
            return "5"
        case "6":
            return "4"
        case "12":
            return "0"
        case "18":
            return "1"
        case "24":
            return "2"
        case "32":
            return "3"
        default:
            return "5"
    }
}

if (generating){
    return (
        <LaunchingModal
              title={"Generating API Data"}
              subtitle={
                "Please do not close or refresh the page, doing so will stop your progress."
              }
            />
      )
}else if (output){
    return (
        <div className="flex-justify-center btnWrap statusbtnWrap">
            <a
                href={output}
                download={`api_data.zip`}
                onClick={clearData}
                className="btnCustom btnfadeBlue btnBig"
                >
                <img src={downloadIcon} alt="download" />
                Download API Data
            </a>
        </div>
    )
}

    return(
        <div className="sOverviewWrap">
        <div className="sectionHead">
              <div className="flex-align-center"></div>
          </div>
          <div className="tableCard" style={{paddingBottom:30}}>
            <div className="flex-beween-cennter tableHeadWrap">
                <h2 className="sectionHeading faddedTxt">API Data Generator</h2>
            </div>
              <div className="tabBody">
                <form onSubmit={generateData}>
            
                 <h3>Channel 0</h3>

                 <div className='grid-5'>
                
                <div>
                    <label className="inplabel">Select Mode</label>
                    <select onChange={(e) => setCh0State({ ...ch0State, mode: e.target.value})} className="customSelect m-b-15" name="mode">
                        {modeOptions.map((option: string) => (<option key={option} value={option} selected={ch0State.mode === option}> {option} </option> ))}
                    </select>
                </div>
    
                {
                    ch0State.mode != "None"
                        &&
                        <div>
                          <div className="inpWithLabel m-b-15">
                            <label htmlFor="name" className="inplabel"> Select Weight File <span className="txtRed">*</span></label>
                            <input ref={ch0WeightFileRef} type="file" accept=".txt" onChange={(e) => handleFileSelect(e, 0, 'weight_file')} style={{ display: "none" }}/>
                            
                            {
                                //@ts-ignore
                                ch0State.weight_file? ( <p>{ch0State.weight_file.name}</p>) : (
                                //@ts-ignore
                                <button type="button" className='customSelect m-b-15' onClick={() => ch0WeightFileRef.current.click()}>Select File</button>)
                            }
                        </div>
                        </div>
                }
                {
                    ch0State.mode != "None"
                        &&
                    <div>
                        <div className="inpWithLabel m-b-15">
                            <label htmlFor="name" className="inplabel"> Select Classification File <span className="txtRed">*</span></label>
                            <input ref={ch0ClassificationFileRef} type="file" accept=".txt" onChange={(e) => handleFileSelect(e, 0, 'classification_file')} style={{ display: "none" }}/>
                            
                            {
                                //@ts-ignore
                                ch0State.classification_file ? ( <p>{ch0State.classification_file.name}</p>) : (
                                //@ts-ignore
                                <button type="button"  className='customSelect m-b-15' onClick={() => ch0ClassificationFileRef.current.click()}>Select File</button>)
                            }
                        </div>
                    </div>
                }
            </div>
             <hr/>
             <h3>Channel 1</h3>
              <div className='grid-5'>
                <div>
                <label className="inplabel">Select Mode</label>
                <select onChange={(e) => setCh1State({ ...ch1State, mode: e.target.value})} className="customSelect m-b-15" name="mode">
                    {modeOptions.map((option: string) => (<option key={option} value={option} selected={ch1State.mode === option}> {option} </option> ))}
                </select>
                </div>
                 
    
                {
                    ch1State.mode != "None"
                        &&
                        <div>
                    
                          <div className="inpWithLabel m-b-15">
                            <label htmlFor="name" className="inplabel"> Select Weight File <span className="txtRed">*</span></label>
                            <input ref={ch1WeightFileRef} type="file" accept=".txt" onChange={(e) => handleFileSelect(e, 1, 'weight_file')} style={{ display: "none" }}/>
                            
                            {
                                //@ts-ignore
                                ch1State.weight_file ? ( <p>{ch0State.weight_file.name}</p>) : (
                                //@ts-ignore
                                <button type="button"  className='customSelect m-b-15' onClick={() => ch1WeightFileRef.current.click()}>Select File</button>)
                            }
                        </div>
                        </div>
                }
                {
                    ch1State.mode != "None"
                        &&
                    <div>
                        <div className="inpWithLabel m-b-15">
                            <label htmlFor="name" className="inplabel"> Select Classification File <span className="txtRed">*</span></label>
                            <input ref={ch1ClassificationFileRef} type="file" accept=".txt" onChange={(e) => handleFileSelect(e, 1, 'classification_file')} style={{ display: "none" }}/>
                            
                            {
                                //@ts-ignore
                                ch1State.classification_file ? ( <p>{ch1State.classification_file.name}</p>) : (
                                //@ts-ignore
                                <button type="button" className='customSelect m-b-15' onClick={() => ch1ClassificationFileRef.current.click()}>Select File</button>)
                            }
                        </div>
                    </div>
                }
            </div>
            <hr/>
             <h3>Feature Configuration</h3>
             <div className='grid-5'>
                <div>
                    <label className="inplabel">VAD</label>
                    <select onChange={(e) => setVadEnable(e.target.value)} className="customSelect m-b-15" name="mode">
                        {vadOptions.map((option: string) => (<option key={option} value={option} selected={vadEnable === option}> {option} </option> ))}
                    </select>
                </div>
                {
                    vadEnable == "Enable"
                        &&
                        <div>
                            <label className="inplabel">VAD Threshold</label>
                            <select onChange={(e) => setVadThreshold(e.target.value)} className="customSelect m-b-15" name="mode">
                                {vadThresholdOptions.map((option: string) => (<option key={option} value={option} selected={vadThreshold === option}> {option} </option> ))}
                            </select>
                        </div>
                }
                {
                    /*
                    (ch0State.mode !== "Sensor" || ch1State.mode !== "Sensor")
                        &&
                    <div>
                        <label className="inplabel">Gain (dB)</label>
                        <select onChange={(e) => setGain(e.target.value)} className="customSelect m-b-15" name="mode">
                            {gainOptions.map((option: string) => (<option key={option} value={option} selected={gain === option}> {option} </option> ))}
                        </select>
                    </div>
                    */
                }
            </div>
    
            
               
                <div>
                    <hr/>
                    
                    {
                        (ch0State.mode == "AudioBuffer" || ch1State.mode == "AudioBuffer")
                            &&
                            <div>
                                <h3>Audio Buffer Configuration</h3>

                                <div className='grid-5 m-b-20'>

                                
                                        <div>
                                            <label className="inplabel">Type</label>
                                            <select onChange={(e) => setAudioBufferType(e.target.value)} className="customSelect m-b-15" name="type">
                                                {audioBufferTypeOptions.map((option: string) => (<option key={option} value={option} selected={audioBufferType === option}> {option} </option> ))}
                                            </select>
                                        </div>


                                    
                                        
                                        <div>
                                            <label className="inplabel">Mode</label>
                                            <select onChange={(e) => setAudioBufferType(e.target.value)} className="customSelect m-b-15" name="type">
                                                {audioBufferModeOptions.map((option: string) => (<option key={option} value={option} selected={audioBufferMode === option}> {option} </option> ))}
                                            </select>
                                        </div>
                                    
                                    
                                        <div>
                                            <label className="inplabel">Start Type</label>
                                            <select onChange={(e) => setAudioBufferStart(e.target.value)} className="customSelect m-b-15" name="type">
                                                {audioBufferStartOptions.map((option: string) => (<option key={option} value={option} selected={audioBufferStart === option}> {option} </option> ))}
                                            </select>
                                        </div>
                                    

                                
                                        <div>
                                            <label className="inplabel">Epilog</label>
                                            <input type="number" value={audioBufferEpilog} onChange={(e) => setAudioBufferEpilog(parseInt(e.target.value))} className="borderInp"/>
                                        </div>

                                    
                                </div>
                            </div>
                    }
                    
        
                </div>
              

                <div style={{textAlign: "center"}}>
                    <button type="submit" className="btnYellow btnCustom modalButton" style={{marginTop: 20}}>
                        Save
                    </button>
                </div>
                  </form>
              </div>
          </div>
          
    
      </div>
     )


    
 
}

export default ApiData;