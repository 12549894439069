import React, {createContext, useState, useEffect} from "react";
import { TTestOverviewProps, TOverviewTest, TOverviewCreateTest } from "./TestOverview.types";
import playIcon from '../../../../assets/images/play-white.svg'
import powerIcon from '../../../../assets/images/power-white.svg'
import trashWIcon from '../../../../assets/images/trash-full-white.svg'
import shortIcon from '../../../../assets/images/short-white.svg'
import playBordIcon from '../../../../assets/images/play-bordered.svg'
import powerBordIcon from '../../../../assets/images/power-grey-bordered.svg'
import trashGreyIcon from '../../../../assets/images/trash-grey-bordered.svg'
import editBordIcon from '../../../../assets/images/edit-bordered.svg'
import copyBordIcon from '../../../../assets/images/copy-bordered.svg'
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import DataTable from 'react-data-table-component';
import TestOverviewModal from "./TestOverviewModal/TestOverviewModal";
import {OverviewModalContext} from "../../session/Overview/Overview";

import testsApi from "../../../../services/tests";
import useApi from "../../../../services/Base/useApi";
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { getTimeAgo } from '../../../../utils/helpers';

export const TestOverviewModalContext = createContext({
    testOverviewModal:false,
    setTestOverviewModal:(state:boolean)=>{},
    handleCreateTest:(data:TOverviewCreateTest)=>{}
});

const TestOverview = (props: TTestOverviewProps) => {
  //======================================
  //STATE
  //======================================
    const [testData, setTestData]  = useState<Array<TOverviewTest>>([]);
    const [testOverviewModal,setTestOverviewModal]=useState(false);
  //======================================
  //HOOKS
  //======================================
    const getTestApi = useApi(testsApi.getTestSessions);
    const createTestApi = useApi(testsApi.postTest);
    const navigate = useNavigate();

    useEffect(() => {
      getTestApi.request(0);
    }, []);

    useEffect(() => {
      if(getTestApi.loading === false && !getTestApi.error && getTestApi.data){
          setTestData(formatTableData(getTestApi.data));
      }
    }, [getTestApi.loading, getTestApi.data, getTestApi.error]);

    useEffect(() => {
      //set new session results
      if(createTestApi.loading === false){
        if(createTestApi.error){
          toast.error(createTestApi.error)
          createTestApi.clearError()
        }else if(createTestApi.data){
          const userName = localStorage.getItem('name');
          let newTest = {
            _id: createTestApi['data']['_id'],
            name: createTestApi['data']['name'],
            mode: createTestApi['data']['mode'],
            createdAt: createTestApi['data']['createdAt'],
            user:{name:userName},
            route: 'setup',
            status: <span className="statusTag grey">Setup</span>,
          }

          setTestData((prevState: any) => {
            return [newTest, ...prevState];
          });

          setTestOverviewModal(false)
          toast.success('Session created')
        }
      }

    },[createTestApi.loading, createTestApi.data, createTestApi.error]);

    //======================================
    //HANDLERS
    //======================================

    const formatTableData = (tests: Array<TOverviewTest>): Array<TOverviewTest>  =>{
      const getStatus= (status: number) =>{
        switch(status)
        {
          case 0:
            return {route:'setup',status:<span className="statusTag grey">Setup</span>};
          case 1:
            return {route:'setup', status:<span className="statusTag yellow">Launching</span>};
          case 2:
            return {route:'monitor', status:<span className="statusTag yellow">Downloading</span>};
          case 3:
            return {route:'monitor', status:<span className="statusTag yellow">In Progress</span>};
          case 4:
            return {route:'review', status:<span className="statusTag green">Completed</span>};
          case 5:
            return {route:'review', status:<span className="statusTag red">Failed</span>};
          default:
            return {route:'setup',status:<span className="statusTag grey">Setup</span>};
        }
      }

      if(tests){
        tests.map((test: TOverviewTest, index: number) =>{
          const {route, status} = getStatus(test.status)
          test.status = status
          test.route = route
        });
        return tests;
      }
      return []

    }

    //create Test
    const handleCreateTest = (data: TOverviewCreateTest) => {
      createTestApi.request(data)
    }

    const openCreateTestModal =(event:React.FormEvent)=>{
        event.preventDefault();
        setTestOverviewModal(true);

    }

    const contextValue = {
        testOverviewModal: testOverviewModal,
        setTestOverviewModal: setTestOverviewModal,
        handleCreateTest: handleCreateTest
    };



    const onEdit = (id: string, route: string) =>{
      const path = `/admin/test/${route}?id=${id}`
      navigate(path)
    }

    const testOverviewTable = () => {
      const columnsForTest = [
          {
              name: 'Name',
              selector: (row: any) => row.name,
          },
          {
              name: 'Status',
              selector: (row: any) => row.status,
          },
          {
            name: 'Mode',
            selector: (row: any) => row.mode,
          },
          {
              name: 'User',
              selector: (row: any) => row.user.name,
          },
          {
              name: 'Last Modified',
              selector: (row: any) => getTimeAgo(new Date(row.createdAt)),
          },
          {
              name: 'Edit',
              button: true,
              cell: (row:any) => (
                  <div className="tblIconUtlWrap">
                      <button onClick={(e) => onEdit(row._id, row.route)} className="tblUtlIcon"><img src={editBordIcon} alt="edit grey" /></button>
                      {
                        /*
                        <button className="tblUtlIcon"><img src={playBordIcon} alt="play grey" /></button>
                        <button className="tblUtlIcon"><img src={trashGreyIcon} alt="trash grey" /></button>
                        <button className="tblUtlIcon"><img src={copyBordIcon} alt="copy grey" /></button>
                        <button className="tblUtlIcon"><img src={powerBordIcon} alt="power grey" /></button>
                        */
                      }
                  </div>
              ),
          },
      ];

        return (
            <div>
                <DataTable
                    columns={columnsForTest}
                    data={testData}
                    customStyles={DTStyles}
                    selectableRows= {false}
                />
            </div>
        )
    }

  if(!getTestApi.loading){
    return (
        <TestOverviewModalContext.Provider value={contextValue}>
        <div className="sOverviewWrap">
          <div className="sectionHead">
           {
             /*
             <div className="flex-align-center">
                 <a href="#" className="btnCustom btnGreen"> <img src={playIcon} alt="play" /> Start</a>
                 <div className="flex-align-center smUtlBtnWrap">
                     <a href="#" className="smallUtlBtn btnGrey"><img src={powerIcon} alt="power" /></a>
                     <a href="#" className="smallUtlBtn btnGrey"><img src={trashWIcon} alt="trash" /></a>
                 </div>
             </div>
              */
           }

                 <div className="flex-align-center"></div>
                  <a onClick={openCreateTestModal} className="btnCustom btnYellow">+ Create Test</a>
              </div>
            <div className="tableCard">
              <div className="flex-beween-cennter tableHeadWrap">
                  <h2 className="sectionHeading faddedTxt">Tests</h2>
                  {
                    /*
                     <a href="#" className="smallUtlBtn btnGrey"><img src={shortIcon} alt="short" className="shortIcon" /></a>
                    */
                  }

              </div>
                <div>
                    {testOverviewTable()}
                </div>
            </div>

            {testOverviewModal && <TestOverviewModal/>}
        </div>
        </TestOverviewModalContext.Provider>
    );
  }else{
    return (
      <div className="loading">
          <div className="loading-spinner"></div>
      </div>
    )
  }

}

export default TestOverview;
