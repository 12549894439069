import React, { useRef,useEffect } from "react";
import { TForgotPasswordStep1Props } from "./ForgotPasswordStep1.types";
import ForgetIconImage from "../../../../assets/images/forget-logo.svg"
import pageBackImage from "../../../../assets/images/back.svg"
import { Link,useNavigate } from "react-router-dom";

import authApi from "../../../../services/authentication";
import useApi from "../../../../services/Base/useApi";

const ForgotPasswordStep1 = (props: TForgotPasswordStep1Props) => {

    const emailInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;

    const forgotPasswordApi = useApi(authApi.putForgotPassword);

    const navigate = useNavigate();

    useEffect(()=>{
      if(!forgotPasswordApi.error && !forgotPasswordApi.loading && forgotPasswordApi.data){
        navigate("/forgot-password/forgot-password-step2")
      }
    },[forgotPasswordApi.error,forgotPasswordApi.loading,forgotPasswordApi.data])

    const submitHandler = () => {

      const enteredEmail = emailInputRef.current.value;

      const data = { "username": enteredEmail }

      forgotPasswordApi.request(data);
    }

    return (
        <div className="authPageWrap">
            <div className="authCreateWrapper">
                <img src={ForgetIconImage} alt="icon" className="authTopLogo"/>
                <h2 className="registrationHead text-center m-b-0 greyTxt">Forget password?</h2>
                <h4 className="registrationSubhead">No worries, we'll send you reset instructions.</h4>
                <div className="customColumn500">
                    <div>
                        <label className="formLabel font-bold">Email</label>
                        <div className="gap10"></div>
                        <input type="text" ref={emailInputRef} className="inputText authInput" placeholder="name@example.com"/>
                    </div>
                    <div className="gap20"></div>
                    <div className="customColumn-12 text-center">
                        <div className="customColumn-12  m-b-20 text-center">
                            <button onClick={submitHandler} className="btnCustom btnYellow authButton">Reset password</button>
                        </div>
                    </div>
                    <div className="gap20"></div>
                    <div className="customColumn-12 text-center">
                        <div className="backPageLink text-center">
                            <Link to="/login"><img src={pageBackImage} alt="icon" /> Back to log in</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordStep1;
