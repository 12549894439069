export const DTStyles= {
    rows: {
        style: {
            minHeight: '60px',
            fontSize: '14px',
            color: '#4A4A4A',
            textAlign: 'center',
            justifyContent: 'center',
            '&:hover': {
                backgroundColor: 'rgba(26, 80, 186, 0.06);'
            }
        },
    },
    headCells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            fontWeight: '600',
            fontSize: '16px',
            color: '#4A4A4A',
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
    cells: {
        style: {
            paddingLeft: '8px',
            paddingRight: '8px',
            textAlign: 'center',
            justifyContent: 'center',
        },
    },
};