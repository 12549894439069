import React, { useState, useEffect } from "react";
import { TTestMonitorProps } from "./TestMonitor.types";
import CodeEditor from "../../common/CodeEditor/CodeEditor";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import TabView from "../../common/TabView/TabView";
import deleteIconWhite from "../../../../assets/images/trash-white.svg";
import crossIcon from "../../../../assets/images/cross-white.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import clockIcon from "../../../../assets/images/clock-white.svg";

import testsApi from "../../../../services/tests";
import useApi from "../../../../services/Base/useApi";
import { toast } from "react-toastify";
import DeleteModal from "../../common/DeleteModal/DeleteModal";
import LaunchingModal from "../../session/Setup/LaunchingModal/LaunchingModal";

import { millisToTimeString, getDuration } from "../../../../utils/helpers";

import { useNavigate, useSearchParams } from "react-router-dom";

const TestMonitor = (props: TTestMonitorProps) => {
  //=====================================
  //STATE
  //======================================
  const [deleteModal, setDeleteModal] = useState(false);
  const [testId, setTestId] = useState("");
  const [monitorTests, setMonitorTests] = useState([]);
  const [testData, setTestData] = useState<any>({});
  const [testLogs, setTestLogs] = useState({ isdk_progress: "" });
  const [loading, setLoading] = useState(true);

  //=====================================
  //HOOKS
  //======================================

  const getTestDataApi = useApi(testsApi.getMonitorTest);
  const getMonitorTestsApi = useApi(testsApi.getMonitorTests);
  const deleteTestApi = useApi(testsApi.deleteReviewTest);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (testId) {
      setLoading(true);
      getTestDataApi.request(testId);
    }
  }, [testId]);

  useEffect(() => {
    getMonitorTestsApi.request();
  }, []);

  useEffect(() => {
    //set data results
    if (getTestDataApi.error) {
      toast.error("Test not found.");
      navigate("/admin/test/overview");
    } else if (
      getTestDataApi.loading === false &&
      !getTestDataApi.error &&
      getTestDataApi.data
    ) {
      setTestData(getTestDataApi.data["test"]);
      setTestLogs(getTestDataApi.data["data"]);
      setLoading(false);
    }
  }, [getTestDataApi.loading, getTestDataApi.error, getTestDataApi.data]);

  useEffect(() => {
    //set setup sessions dropdown options
    if (
      getMonitorTestsApi.loading === false &&
      !getMonitorTestsApi.error &&
      getMonitorTestsApi.data
    ) {
      setMonitorTests(getMonitorTestsApi.data);
      if (searchParams.get("id")) {
        //@ts-ignore
        setTestId(searchParams.get("id"));
      } else if (getMonitorTestsApi.data[0]) {
        setTestId(getMonitorTestsApi.data[0]["_id"]);
        navigate(`/admin/test/monitor?id=${getMonitorTestsApi.data[0]["_id"]}`);
      } else {
        setLoading(false);
      }
    }
  }, [
    getMonitorTestsApi.loading,
    getMonitorTestsApi.error,
    getMonitorTestsApi.data,
  ]);

  useEffect(() => {
    if (deleteTestApi.loading === false) {
      if (deleteTestApi.error) {
        toast.error(deleteTestApi.error);
        deleteTestApi.clearError();
      } else {
        toast.success("Test deleted");
        navigate("/admin/test/overview");
      }
    }
  }, [deleteTestApi.loading, deleteTestApi.error, deleteTestApi.data]);

  //=====================================
  //HANDLERS
  //======================================

  // delete test
  const deleteTest = () => {
    deleteTestApi.request(testId, testData.name);
  };

  const headers = [
    {
      title: "Status",
    },
    // {
    //     title: "Console"
    // },
  ];
  let percentage =
    testData.status === 4 || testData.status === 5
      ? 100
      : Math.floor(parseInt(testLogs.isdk_progress) / 10 - 0.1);
  percentage = percentage < 0 ? 0 : percentage;
  let timeElapsed = getDuration(testData.startTime, testData.endTime);

  const body = [
    <div className="prgHolder">
      <div className="prgrsWrap">
        <p className="prgTxt">Processing ..</p>
        <div className="prgChartWrap" style={{ width: 200, height: 200 }}>
          <CircularProgressbar
            value={percentage}
            text={`${percentage}%`}
            strokeWidth={13}
            styles={buildStyles({
              textColor: "#FFC300",
              pathColor: "#FFC300",
              trailColor: "#989898",
            })}
          />
        </div>
      </div>
      <a href="#" className="bigBadge badgeBlue m-t-50">
        <img src={clockIcon} alt="clock" />
        Time Elapsed : {millisToTimeString(timeElapsed)}
      </a>
    </div>,
  ];

  if (testId) {
    return (
      <div>
        <div className="sectionHead">
          <div className="flex-align-center">
            <h2 className="sectionHeading">Test : </h2>
            <select
              onChange={(e) => {
                setTestId(e.target.value);
                navigate(`/admin/test/monitor?id=${e.target.value}`);
              }}
              className="customSelect m-l-10"
              name="sessionName"
              id=""
            >
              {monitorTests.map((test: { _id: string; name: string }) => (
                <option
                  key={test._id}
                  value={test._id}
                  selected={test._id === testId}
                >
                  {test.name}
                </option>
              ))}
            </select>
          </div>
          <div className="btnHolder flex-align-center">
            <a
              onClick={() => setDeleteModal(true)}
              className="btnCustom btnRed"
            >
              <img src={deleteIconWhite} alt="delete" />
              Delete
            </a>
          </div>
        </div>
        {!loading ? (
          testData.status === 3 ? (
            <div className="globalCard">
              <TabView footer={false} header={headers} body={body} />
              {deleteModal && (
                <DeleteModal
                  title="Test"
                  subtitle="Are you sure you want to delete this test? Doing this will permenatly destroy your progress."
                  onCancel={() => setDeleteModal(false)}
                  onDelete={deleteTest}
                />
              )}
            </div>
          ) : (
            <>
              <LaunchingModal
                title="Downloading Files"
                subtitle={
                  "Your test is currently downloading files, this will take a few minutes."
                }
              />
              {deleteModal && (
                <DeleteModal
                  title="Test"
                  subtitle="Are you sure you want to delete this test? Doing this will permenatly destroy your progress."
                  onCancel={() => setDeleteModal(false)}
                  onDelete={deleteTest}
                />
              )}
            </>
          )
        ) : (
          <div className="loading">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    );
  } else if (!loading) {
    return (
      <div className="no-items">
        <h2>No Tests in Monitor</h2>
        <p>Tests that are in monitor will appear here.</p>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default TestMonitor;
