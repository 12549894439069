import React, { useEffect, useState } from "react";
import { getTimeAgo } from '../../../../utils/helpers';
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import DataTable from 'react-data-table-component';

import notificationsApi from "../../../../services/data-sources";
import useApi from "../../../../services/Base/useApi";

const Notifications = () =>{

  //===============================
  //STATE
  //===============================
  const [notifications,setNotifications] = useState([])

  //===============================
  //HOOKS
  //===============================
  const getNotificationsApi = useApi(notificationsApi.getNotifications);

  useEffect(() => {
    // call api on initial render
    getNotificationsApi.request();
  }, []);


  useEffect(() => {
    // set data result
    if(getNotificationsApi.loading === false && !getNotificationsApi.error && getNotificationsApi.data)
    {
      setNotifications(getNotificationsApi.data);
    }
  }, [getNotificationsApi.loading, getNotificationsApi.data, getNotificationsApi.error]);

  //===============================
  //HANDLERS
  //===============================

  const notificationsTable = () => {
      const columns = [
          {
              name: 'Message',
              selector: (row: any) => row.message,
          },

          {
              name: 'Created',
              selector: (row: any) => getTimeAgo(new Date(row.createdAt)),
          }
      ];

      return (
          <div>
              <DataTable
                  columns={columns}
                  data={notifications}
                  customStyles={DTStyles}
                  selectableRows= {false}
              />
          </div>
      )
  }

  return (
    <div className="sOverviewWrap">
      <div className="sectionHead">
            <div className="flex-align-center"></div>
        </div>
        <div className="tableCard">
          <div className="flex-beween-cennter tableHeadWrap">
              <h2 className="sectionHeading faddedTxt">Notifications</h2>
          </div>
            <div>
                {notificationsTable()}
            </div>
        </div>

    </div>
  )
}

export default Notifications;
