import React, {useContext} from "react";
import { TTopBarProps } from "./TopBar.types";

import searcIcon from "../../../../assets/images/search-white.svg"
import bellIcon from "../../../../assets/images/bell-white.svg"
import menuIcon from "../../../../assets/images/menu_white.svg"
import "./TopBar.css"
import AuthContext from "../../../../store/auth-context";
import { Link } from 'react-router-dom';
const TopBar = (props: TTopBarProps) => {
    const authCtx = useContext(AuthContext);
    const name = authCtx.name;
    const profile = authCtx.profile;
    const created = authCtx.created;
    const evalPeriod = authCtx.evalPeriod;
    //@ts-ignore
    let difference: Number = Math.floor(Math.abs(new Date() - new Date(created)) / (1000 * 60 * 60 * 24));
    //@ts-ignore
    let daysLeft = difference < evalPeriod ? `${evalPeriod - difference} days until expiration`: 'Evaluation period expired'
    
    return (
        <div className="headeInner">
            <div className="headerLeft">
              <p style={{color:'white', fontWeight:700}}>{daysLeft}</p>
              {
                /*
                <div className="searchWrapper">
                    <input className="searchInp" type="text" placeholder="Search"/>
                    <img className="searchIcon" src={searcIcon} alt="search" />
                </div>

                */
              }

            </div>
            <div className="headerRight">

                  <div className="notifWrap">
                      <Link to='/admin/notifications'className="notifLink">
                          <img className="bellIcon" src={bellIcon} alt="bell" />
                      </Link>
                  </div>


                <div className="profileUtile">
                    <div className="profNameWrap">
                    {
                      /*
                        <div className="profImg"><img src={profile} alt="profile image" /></div>
                      */
                    }

                        <span className="profName">{name}</span>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default TopBar;
