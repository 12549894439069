import { client,protectedClient } from "./Base/client"

//Login

// POST login
const postLogin = (data: unknown) => client.post("/login", data);

// Register

// POST Admin
const postRegisterAdmin = (data: unknown) => client.post("/register", data);

// POST User
const postRegisterUser = (data: unknown) => client.post("/register/user", data);

// Password reset

// PUT forgot password
const putForgotPassword = (data: unknown) => client.put("/forgot-password", data);

// PUT reset password
const putResetPassword = (data: unknown) => client.put("/reset-password", data);

// POST support Emial
const postSupportEmail = (data: unknown) => protectedClient.post("/status/support", data)

export default {
  postLogin,
  postRegisterAdmin,
  postRegisterUser,
  putForgotPassword,
  putResetPassword,
  postSupportEmail
}
