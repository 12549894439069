import React, { useContext, useEffect } from "react";
import { CommandSequenceContext } from "./CommandSequence";

const SelectCommandsModal = () => {
    const contextValue = useContext(CommandSequenceContext);

    const { 
        commands,
        selectedKeyword, 
        selectedCommands,
        onSelectCommand,
        setKeywordModal, 
        setCommandsModal,
        onCreateSequence,
        clear,
    } = contextValue;
    

    const cancel = () => {
        clear()
        setCommandsModal(false) 
    }

    const save = () => {
      if (selectedCommands.length > 0){
        setCommandsModal(false);
        onCreateSequence();
      }
        
    }


    return (
        <div className="modalOverlay">
      <div className="modalWrapper bgWhite">
        <div className="modalHeadTransp">
          <h3 className="modalheader">Select up to 3 Commands</h3>
        </div>
        <div className="modalWrapperBody">
        <div className="grid-3">
                {
                    commands.map((word: any) => (
                        <a  
                            style={{textAlign:'center'}}
                            onClick={() => onSelectCommand(word)}
                            //@ts-ignore
                            className={selectedCommands.includes(word) ? 'btn btnBlue btnCustom' : 'btn'}>
                            {word.name}
                        </a>
                    ))
                }
            </div>
        <div className="gap30"></div>
            <div className="customColumn-12 text-center">
              <button
                onClick={cancel}
                className="btnBlack btnCustom modalButton"
              >
                Cancel
              </button>
              <button 
                    onClick={save}
                    className="btnYellow btnCustom modalButton">
                Save
              </button>
            </div>
        </div>
      </div>
    </div>
    )


}

export default SelectCommandsModal;