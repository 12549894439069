import React from "react";
import {TCardMatter, TDashboardProps} from "./Dashboard.types";
import "./Dashboard.css"
import DashboardCard from "../DashboardCard/DashboardCard";

import {customAlert} from "../../common/AlertModal/AlertModal";


const Dashboard = (props: TDashboardProps) => {

    const openErrorAlert = async () => {
        const result = await customAlert("I am an error alert!", "error");
        alert("button response:"+result);
    }

    const openConfirmAlert = async () => {
        const result = await customAlert("I am a confirmation alert!", "confirm");
        alert("button response:"+result);
    }

    const openDownloadAlert = async () => {
        const result = await customAlert("I am a download alert!", "download");
        alert("button response:"+result);
    }

    const openDeleteAlert = async () => {
        const result = await customAlert("I am a delete confirmation alert!", "delete")
        alert("button response:"+result);
    }

    const cardMatter: Array<TCardMatter> = [
        {
            section: "Sessions",
            data: [
                {

                    status: 'In progress',
                    blankTxt: 'No Session in progress',
                    statusClass: 'yellow'
                },
                {

                    status: 'completed',
                    blankTxt: 'No Completed Session',
                    statusClass: 'green'
                },
                {

                    status: 'Queued',
                    blankTxt: 'No Queued Session',
                    statusClass: 'grey'
                },
            ]
        },
        {
            section: "Tests",
            data: [
                {

                    status: 'In progress',
                    blankTxt: 'No Test in progress',
                    statusClass: 'yellow'
                },
                {

                    status: 'completed',
                    blankTxt: 'No Completed Test',
                    statusClass: 'green'
                },
                {

                    status: 'Queued',
                    blankTxt: 'No Queued Test',
                    statusClass: 'grey'
                },
            ]
        }
    ]
    const sectionTemplate = (section: TCardMatter, index: number) => {

        return (
            <div className="sectionWrap" key={section.section}>
                <div className="sectionHead">
                    <h2 className="sectionHeading">{section.section}</h2>
                    {index == 0 && <a onClick={()=>{openErrorAlert()}} className="btnCustom btnYellow">+ Create Session</a>}
                </div>
                <div className="sectionBody">
                    <div className="dashCardGrid">

                        {
                            section['data'].map(function (card, i) {
                                return (<div key={JSON.stringify(card)} className="dashGridItem"><DashboardCard status={card.status}
                                                                                     blankTxt={card.blankTxt}
                                                                                     statusClass={card.statusClass}/>
                                </div>);
                            })
                        }
                    </div>
                </div>

            </div>
        );
    }
    return (
        <div className="pageInner">
            {cardMatter.map(function (object, i) {
                return sectionTemplate(object, i);
            })}
            {/* Below div is just to demonstrate the alert modals, remove when not needed*/}
            <div className="sectionHead">
                <h2 className="sectionHeading">Demo section remove before use</h2>
            </div>
            <div className="sectionBody">
                <a onClick={()=>{openErrorAlert()}} className="btnCustom btnYellow">Error Alert</a>&nbsp;
                <a onClick={()=>{openConfirmAlert()}} className="btnCustom btnYellow">Confirm Alert</a>&nbsp;
                <a onClick={()=>{openDownloadAlert()}} className="btnCustom btnYellow">Download Alert</a>&nbsp;
                <a onClick={()=>{openDeleteAlert()}} className="btnCustom btnYellow">Delete Alert</a>&nbsp;
            </div>
        </div>

    )
}

export default Dashboard;
