import React, {useContext} from "react";
import {TWizardNavigationProps, TWizardNavigationTabItems, WizardNavigationTabStates} from "./WizardNavigation.types";

import "./WizardNavigation.css"
import clockImg from "../../../../../assets/images/clock-fill.svg"
import refreshImg from "../../../../../assets/images/refrsh-fill.svg"
import checkedIcon from "../../../../../assets/images/checked-fill.svg"
import clockError from "../../../../../assets/images/clock-red.svg"
import {WizardContext} from "../Wizard";

const WizardNavigation = (props: TWizardNavigationProps) => {
    const TabContext = useContext(WizardContext)
    let items = props.items;
    const createMarkup = (text:string)=>{ return {__html: text}; };

    const changeActiveItem = (itemIndex:number)=>{
        if(itemIndex<=items.length)
        {
            const thisItem = items[itemIndex];
            if(thisItem.jump === undefined || !thisItem.jump)
            {
                if(thisItem.state===WizardNavigationTabStates.completed)
                {
                    TabContext.changeTab(itemIndex);
                    TabContext.changeHeader(itemIndex,thisItem.state)
                }
            }
            else
            {
                TabContext.changeTab(itemIndex);
                TabContext.changeHeader(itemIndex,thisItem.state)
            }
        }


    }

    const getData = (item: TWizardNavigationTabItems) => {
        switch (item.state) {
            case WizardNavigationTabStates.default: {
                return {
                    icon: clockImg,
                    text: "Pending",
                    className: "wizardItem",
                    bulletClassName: "wBulletItem"
                };
                break;
            }
            case WizardNavigationTabStates.completed: {
                return {
                    icon: checkedIcon,
                    text: "Completed",
                    className: "wizardItem completed",
                    bulletClassName: "wBulletItem completed"
                };
                break;
            }
            case WizardNavigationTabStates.current: {
                return {
                    icon: refreshImg,
                    text: "In progress",
                    className: "wizardItem current",
                    bulletClassName: "wBulletItem current"
                };
                break;
            }
            case WizardNavigationTabStates.failed: {
                return {
                    icon: clockError,
                    text: "Failed",
                    className: "wizardItem failed",
                    bulletClassName: "wBulletItem failed"
                };
                break;
            }
            case WizardNavigationTabStates.error: {
                return {
                    icon: clockError,
                    text: "Error",
                    className: "wizardItem failed",
                    bulletClassName: "wBulletItem failed"
                };
                break;
            }
            default: {
                return {
                    icon: clockImg,
                    text: "Pending",
                    className: "wizardItem",
                    bulletClassName: "wBulletItem"
                };
                break;
            }
        }

    };

    // onClick={()=>{changeActiveItem(i)}}

    return (
        <div className="wizardWrap">
            <div className="wizardBulletWrap">
                {
                    items.map(function (item, i) {
                    return (<div key={JSON.stringify(item)} className={getData(item).bulletClassName}></div>)
                    })
                }
            </div>
            <div className="wizardItemWrap">
                {
                    items.map(function (item, i) {
                        const itemData = getData(item);
                        return(
                            <div  key={JSON.stringify(item)} className={itemData.className}>
                                <div className="wiHead">
                                    <img className="wHdIcon" src={itemData.icon} alt="clock"/>
                                    <span className="wStepName">STEP {i+1}</span>
                                </div>
                                <h4 className="wizardName" dangerouslySetInnerHTML={createMarkup(item.title)} />
                                
                                <span className="wizardStatus">{itemData.text}</span>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}

export default WizardNavigation;
