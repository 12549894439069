import React, {useEffect, useRef, useState} from "react";
import {TAlertModalProps} from "./AlertModal.types";
import ConfirmModalIconWhite from "../../../../assets/images/confirm-modal.svg"
import deleteModalIconWhite from "../../../../assets/images/delete-modal.svg"
import DownloadModalIconWhite from "../../../../assets/images/download-modal.svg"
import ErrorModalIconWhite from "../../../../assets/images/error-modal.svg"
import ReactDOM from 'react-dom/client';

let returnResponse: (value: boolean) => void;
const AlertModal = (props: TAlertModalProps) => {

    const root = useRef<HTMLDivElement | null>();

    useEffect(() => {
        let div = document.getElementById(rootID) as HTMLDivElement;
        root.current = div;
    }, [])



    let modal ={
        className:"",
        icon:"",
        alt:"",
        buttonTitle:"",
        buttonClass:"",
        type:""
    };

    const confirm = ()=>{
        returnResponse(true);
        close();
    }

    const cancel = ()=>{
        returnResponse(false);
        close();
    }

    const close = () =>{
            root.current?.remove();
    }




    switch (props.type) {
        case 'confirm': {
            modal = {
                    className: "customeModalWrapper confirmModalBg",
                    icon: ConfirmModalIconWhite,
                    alt: "confirm dialog icon",
                    buttonTitle: "Confirm",
                    buttonClass: "btnOrange btnCustom",
                    type: props.type
                };

            break;
        }
        case 'delete': {
            modal = {className : "customeModalWrapper deleteModalBg",
            icon : deleteModalIconWhite,
            alt : "delete dialog icon",
            buttonTitle : "Delete",
            buttonClass : "btnRed btnCustom",
            type: props.type
        };

            break;
        }
        case 'download': {
            modal = {
            className:"customeModalWrapper downloadModalBg",
            icon:DownloadModalIconWhite,
            alt : "download dialog icon",
            buttonTitle : "Download",
            buttonClass : "btnGreen btnCustom",
            type: props.type
            };
            break;
        }
        case 'error': {
            modal = {
            className : "customeModalWrapper errorModalBg",
            icon : ErrorModalIconWhite,
            alt : "error dialog icon",
            buttonTitle : "OK",
            buttonClass: "btnYellow btnCustom",
            type: props.type
        };
            break;
        }
        default: {
            /*setModal({
                className:"",
                icon:"",
                alt:"",
                buttonTitle:"",
                buttonClass:"",
                type:""
            });*/
        }
    }

    return (
        modal.type!==""?
        <div>
            {/* Modal */}
            <div className="modalOverlay">
                <div className={modal.className}>
                    <div className="customeModalWrapperBody">
                        <div className="customeModalContent">
                            <img src={modal.icon} alt={modal.alt}/>
                            <span>{props.message}</span>
                        </div>
                    </div>
                    <div className="customeModalWrapperFooter">
                        <button onClick={()=>{cancel()}} className="btnGrey btnCustom">Cancel</button>
                        <button onClick={()=>{confirm()}}  className={modal.buttonClass}>{modal.buttonTitle}</button>
                    </div>
                </div>
            </div>
            {/* Modal end */}
        </div>:<></>
    );
}
const rootID = "alert-dialog";
const createAlert = (message:string,type:string) =>{
    let div = document.getElementById(rootID);
    if (!div) {
        div = document.createElement("div");
        div.id = rootID;
        document.body.appendChild(div);
    }
    const root = ReactDOM.createRoot(
        document.getElementById(rootID) as HTMLElement
    );
    root.render(
        <AlertModal
            message={message}
            type={type}
        />
    );

};
export function customAlert(message: string, type:string) {
    // pass in type
    createAlert(message, type);
    // set our promise for resolve on input
    return new Promise<boolean>(resolve => {
        returnResponse = resolve;
    });
}

export default AlertModal;