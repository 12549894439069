import React, { useRef, useEffect, useContext } from "react";
import { TRegistrationProps } from "./Registration.types";
import loginPageImage from "../../../assets/images/login-page-img.jpg";
import authLogoImage from "../../../assets/images/auth-logo.svg";
import hidePasswordImage from "../../../assets/images/hide.svg";
import showPasswordImage from "../../../assets/images/show.svg";
import { Link } from "react-router-dom";
import AuthContext from "../../../store/auth-context";
import { toast } from "react-toastify";

import authApi from "../../../services/authentication";
import useApi from "../../../services/Base/useApi";

const Registration = (props: TRegistrationProps) => {
  const loginFormRef = useRef() as React.MutableRefObject<HTMLFormElement>;
  const fnameInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const lnameInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const emailInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const passwordInputRef = useRef() as React.MutableRefObject<HTMLInputElement>;
  const customerIdInputRef =
    useRef() as React.MutableRefObject<HTMLInputElement>;

  const authCtx = useContext(AuthContext);

  const registerApi = useApi(authApi.postRegisterUser);

  useEffect(() => {
    if (registerApi.loading === false) {
      if (registerApi.error) {
        toast.error(registerApi.error);
        registerApi.clearError();
      } else if (registerApi.data) {
        let expirationTime = new Date();
        expirationTime.setDate(expirationTime.getDate() + 2);

        authCtx.login(
          registerApi.data["token"],
          registerApi.data['companyId'],
          expirationTime.toISOString(),
          registerApi.data["user"]["name"],
          "https://aondevices.com/wp-content/uploads/2021/05/ARRUNA.png",
          registerApi.data['created'], 
          registerApi.data['evalPeriod']
        );
        window.location.replace("/admin");
        console.log(registerApi.data);
        //   navigate('/admin',{ replace: true });
      }
    }
  }, [registerApi.loading, registerApi.error, registerApi.data]);

  const submitHandler = async (event: React.FormEvent) => {
    event.preventDefault();

    const enteredEmail = emailInputRef.current.value;
    const enteredPassword = passwordInputRef.current.value;
    const enteredFName = fnameInputRef.current.value;
    const enteredLName = lnameInputRef.current.value;
    const enteredCustomerID = customerIdInputRef.current.value;

    // optional: Add validation and call API to perform login
    if (
      !enteredEmail ||
      !enteredPassword ||
      !enteredCustomerID ||
      !enteredFName ||
      !enteredLName
    )
      return;
    if (enteredPassword.length < 8) {
      toast.error("Password must be at least 8 characters.");
    } else {
      const data = {
        username: enteredEmail,
        fname: enteredFName,
        lname: enteredLName,
        password: enteredPassword,
        companyId: enteredCustomerID,
      };
      registerApi.request(data);
    }
  };

  return (
    <div className="authPageWrap">
      <div className="authPageWrap">
        <div className="authFormLeft">
          <div className="authFormWrapper">
            <h2 className="registrationHead">Create new account</h2>
            <form ref={loginFormRef} onSubmit={submitHandler}>
              <div className="customRow">
                <div className="customColumn-12  m-b-20">
                  <label className="formLabel font-bold">Company ID</label>
                  <div className="gap10"></div>
                  <input
                    ref={customerIdInputRef}
                    type="text"
                    className="inputText authInput"
                    placeholder="776626829"
                  />
                </div>
                <div className="customColumn-12  m-b-20">
                  <label className="formLabel font-bold">First Name</label>
                  <div className="gap10"></div>
                  <input
                    ref={fnameInputRef}
                    type="text"
                    className="inputText authInput"
                    placeholder="John"
                  />
                </div>
                <div className="customColumn-12  m-b-20">
                  <label className="formLabel font-bold">Last Name</label>
                  <div className="gap10"></div>
                  <input
                    ref={lnameInputRef}
                    type="text"
                    className="inputText authInput"
                    placeholder="Smith"
                  />
                </div>
                <div className="customColumn-12  m-b-20">
                  <label className="formLabel font-bold">Email</label>
                  <div className="gap10"></div>
                  <input
                    ref={emailInputRef}
                    type="text"
                    className="inputText authInput"
                    placeholder="name@example.com"
                  />
                </div>
                <div className="customColumn-12  m-b-20 ">
                  <label className="formLabel font-bold">Password</label>
                  <div className="gap10"></div>
                  <div className="passwordDiv">
                    <input
                      ref={passwordInputRef}
                      type="password"
                      className="inputText authInput"
                      placeholder="at least 8 characters"
                    />
                  </div>
                </div>
                <div className="customRow m-b-20">
                  <div className="customColumn-12 authTerms">
                    <div className="flex-align-center">
                      <label htmlFor="termsAcpt">
                        {" "}
                        By clicking Sign Up, you agree with AONCloud ‘s{" "}
                        <a href="terms-and-conditions">Terms,</a>
                        <a href="privacy-policy"> Privacy Policy</a>
                      </label>
                    </div>
                  </div>
                </div>
                <div className="customColumn-12  m-b-20 text-center">
                  <button className="btnCustom btnYellowGradient authButton">
                    Sign up
                  </button>
                </div>

                <div className="customColumn-12  m-b-20 text-center">
                  <div className="regdLoginDiv">
                    <span>Already have an account ?</span>
                    {/* <a href="#">Log in</a> */}
                    <Link to="/login">login</Link>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div className="authImgRight">
          <div className="authImageWrap">
            <img src={loginPageImage} alt="login image" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Registration;
