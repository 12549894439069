import React, {useState} from "react";
import {TCodeEditorProps} from "./CodeEditor.types";
import './CodeEditor.css'

import Editor from "react-simple-code-editor";

import Prism, {Grammar, highlight, languages} from 'prismjs'
//import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-javascript";// Language
import "prismjs/themes/prism.css";// Theme

import spinnerIcon from '../../../../assets/images/spinner.svg'


const highlightWithLineNumbers = (input:string, grammar:Grammar, language:string) =>
    highlight(input, grammar, language)
        .split("\n")
        .map((line, i) => `<span class='editorLineNumber'>${i + 1}</span>${line}`)
        .join("\n");


const CodeEditor = (props: TCodeEditorProps) => {
    const code = props.code ? props.code : `{
    "dropout" : 30,
    "12_regularization" : 0,
    "Patience" : 4,
    "cpu_cores_train" : 2,
    "training_speed1" : 0,
    "training_speed2" : 0,
    "training_speed3" : 0,
  }
  `;
  const setCodeValue = props.onCodeChange;




    return (
        <div className="codeEditorWrap">
            {/* to enable loader, open this code */}
            {/* <div className="spinnerWrap">
                <p className="spinnerText">Waiting for the training logs</p>
                <img className="spinnerImg" src={spinnerIcon} alt="spinner" />
            </div> */}
            <Editor
                value={code}
                onValueChange={newCode => setCodeValue(newCode)}
                highlight={code => highlightWithLineNumbers(code, languages.plaintext,'javascript')}
                padding={10}
                textareaId="codeArea"
                className="editor"
                textareaClassName="greenText"
                style={{
                    fontFamily: '"Fira code", "Fira Mono", monospace',
                    fontSize: 18,
                    outline: 0,
                    color: '#009900',
                }}
            />
        </div>
    );
}

export default CodeEditor;
