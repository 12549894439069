import React, { createContext, useState, useEffect } from "react";
import {
  TOverviewProps,
  TOverviewSession,
  TOverviewCreateSession,
} from "./Overview.types";
import { toast } from "react-toastify";
import playIcon from "../../../../assets/images/play-white.svg";
import powerIcon from "../../../../assets/images/power-white.svg";
import trashWIcon from "../../../../assets/images/trash-full-white.svg";
import shortIcon from "../../../../assets/images/short-white.svg";
import playBordIcon from "../../../../assets/images/play-bordered.svg";
import powerBordIcon from "../../../../assets/images/power-grey-bordered.svg";
import trashGreyIcon from "../../../../assets/images/trash-grey-bordered.svg";
import editBordIcon from "../../../../assets/images/edit-bordered.svg";
import copyBordIcon from "../../../../assets/images/copy-bordered.svg";
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import DataTable from "react-data-table-component";
import OverviewModal from "./OverviewModal/OverviewModal";

import sessionsApi from "../../../../services/sessions";
import useApi from "../../../../services/Base/useApi";
import { useNavigate } from "react-router-dom";
import { getTimeAgo } from "../../../../utils/helpers";

export const OverviewModalContext = createContext({
  overviewModal: false,
  setOverviewModal: (state: boolean) => {},
  handleCreateSession: (data: TOverviewCreateSession) => {},
});

const Overview = (props: TOverviewProps) => {
  //======================================
  //STATE
  //======================================
  const [overviewModal, setOverviewModal] = useState(false);
  const [sessionData, setSessionData] = useState<Array<TOverviewSession>>([]);
  const [queuedData, setQueuedData] = useState<Array<TOverviewSession>>([]);

  //======================================
  //HOOKS
  //======================================
  const getSessionsApi = useApi(sessionsApi.getSessions);
  const createSessionApi = useApi(sessionsApi.postSession);
  const deleteSessionApi = useApi(sessionsApi.deleteSession);
  const navigate = useNavigate();

  useEffect(() => {
    // call api on initial render
    getSessionsApi.request(0);
  }, []);

  useEffect(() => {
    // set data result
    if (
      getSessionsApi.loading === false &&
      !getSessionsApi.error &&
      getSessionsApi.data
    ) {
      const { sessions, queued } = getSessionsApi.data;
      setSessionData(formatTableData(sessions));
      setQueuedData(formatTableData(queued));
    }
  }, [getSessionsApi.loading, getSessionsApi.data, getSessionsApi.error]);

  useEffect(() => {
    //set new session results
    if (createSessionApi.loading === false) {
      if (createSessionApi.error) {
        toast.error(createSessionApi.error);
        createSessionApi.clearError();
      } else if (createSessionApi.data) {
        const userName = localStorage.getItem("name");
        let newSession = {
          _id: createSessionApi["data"]["_id"],
          name: createSessionApi["data"]["name"],
          mode: createSessionApi["data"]["mode"],
          user: { name: userName },
          createdAt: createSessionApi["data"]["createdAt"],
          route: "setup",
          status: <span className="statusTag grey">Setup</span>,
        };

        setSessionData((prevState: any) => {
          return [newSession, ...prevState];
        });
        setOverviewModal(false);
        toast.success("Session created");
      }
    }
  }, [createSessionApi.loading, createSessionApi.data, createSessionApi.error]);

  //======================================
  //HANDLERS
  //======================================

  const formatTableData = (
    sessions: Array<TOverviewSession>
  ): Array<TOverviewSession> => {
    const getStatus = (status: number) => {
      switch (status) {
        case 0:
          return {
            route: "setup",
            status: <span className="statusTag grey">Setup</span>,
          };
        case 1:
          return {
            route: "setup",
            status: <span className="statusTag yellow">Launching</span>,
          };
        case 2:
          return {
            route: "monitor",
            status: <span className="statusTag yellow">Downloading</span>,
          };
        case 3:
          return {
            route: "monitor",
            status: <span className="statusTag yellow">In progress</span>,
          };
        case 4:
          return {
            route: "review",
            status: <span className="statusTag green">Completed</span>,
          };
        case 5:
          return {
            route: "review",
            status: <span className="statusTag red">Failed</span>,
          };
        default:
          return {
            route: "setup",
            status: <span className="statusTag grey">Setup</span>,
          };
      }
    };

    if (sessions) {
      sessions.map((session: TOverviewSession, index: number) => {
        const { route, status } = getStatus(session.status);
        session.status = status;
        session.route = route;
      });
      return sessions;
    }
    return [];
  };

  // delete session
  const deleteSession = (sessionId: string) => {
    deleteSessionApi.request(sessionId);
  };

  //create session
  const handleCreateSession = (data: TOverviewCreateSession) => {
    createSessionApi.request(data);
  };

  const openCreateSessionModal = (event: React.FormEvent) => {
    event.preventDefault();
    setOverviewModal(true);
  };

  const contextValue = {
    overviewModal: overviewModal,
    setOverviewModal: setOverviewModal,
    handleCreateSession: handleCreateSession,
  };

  const onEdit = (id: string, route: string) => {
    const path = `/admin/session/${route}?id=${id}`;
    navigate(path);
  };

  const overviewSessionTable = () => {
    const columnsForSession = [
      {
        name: "Name",
        selector: (row: any) => row.name,
      },
      {
        name: "Status",
        selector: (row: any) => row.status,
      },
      {
        name: "Mode",
        selector: (row: any) => row.mode,
      },
      {
        name: "User",
        selector: (row: any) => row.user.name,
      },
      {
        name: "Created",
        selector: (row: any) => getTimeAgo(new Date(row.createdAt)),
      },
      {
        name: "Edit",
        button: true,
        cell: (row: any) => (
          <div className="tblIconUtlWrap">
            <button
              onClick={(e) => onEdit(row._id, row.route)}
              className="tblUtlIcon"
            >
              <img src={editBordIcon} alt="edit grey" />
            </button>
            {/*
                          <button className="tblUtlIcon"><img src={playBordIcon} alt="play grey" /></button>
                          <button className="tblUtlIcon"><img src={trashGreyIcon} alt="trash grey" /></button>
                          <button className="tblUtlIcon"><img src={copyBordIcon} alt="copy grey" /></button>
                          <button className="tblUtlIcon"><img src={powerBordIcon} alt="power grey" /></button>
                          */}
          </div>
        ),
      },
    ];

    return (
      <div>
        <DataTable
          columns={columnsForSession}
          data={sessionData}
          customStyles={DTStyles}
          selectableRows={false}
        />
      </div>
    );
  };

  const overviewQueuedTable = () => {
    const columnsForQueued = [
      {
        name: "Name",
        selector: (row: any) => row.name,
      },
      {
        name: "Status",
        selector: (row: any) => row.status,
      },
      {
        name: "Created",
        selector: (row: any) => row.createdAt,
      },
      // {
      //     name: 'Edit',
      //     selector: (row: any) => row.edit,
      // },
    ];

    return (
      <div>
        <DataTable
          columns={columnsForQueued}
          data={queuedData}
          customStyles={DTStyles}
          selectableRows={true}
        />
      </div>
    );
  };

  if (!getSessionsApi.loading) {
    return (
      <OverviewModalContext.Provider value={contextValue}>
        <div className="sOverviewWrap">
          <div className="sectionHead">
            {/*
              <div className="flex-align-center">
                  <a href="#" className="btnCustom btnGreen"> <img src={playIcon} alt="play" /> Start</a>
                  <div className="flex-align-center smUtlBtnWrap">
                      <a href="#" className="smallUtlBtn btnGrey"><img src={powerIcon} alt="power" /></a>
                      <a href="#" className="smallUtlBtn btnGrey"><img src={trashWIcon} alt="trash" /></a>
                  </div>
              </div>
               */}

            <div className="flex-align-center"></div>
            <a onClick={openCreateSessionModal} className="btnCustom btnYellow">
              + Create Session
            </a>
          </div>
          <div className="tableCard">
            <div className="flex-beween-cennter tableHeadWrap">
              <h2 className="sectionHeading faddedTxt">Sessions</h2>
              {/*
                      <a href="#" className="smallUtlBtn btnGrey"><img src={shortIcon} alt="short" className="shortIcon" /></a>
                     */}
            </div>

            <div>{overviewSessionTable()}</div>
          </div>
          {/*
             <div className="tableCard">
                 <div className="flex-beween-cennter tableHeadWrap">
                     <h2 className="sectionHeading faddedTxt">Queued</h2>
                 </div>

                 <div>
                     {overviewQueuedTable()}
                 </div>
             </div>
             */}

          {overviewModal && <OverviewModal />}
        </div>
      </OverviewModalContext.Provider>
    );
  } else {
    return (
      <div className="loading">
        <div className="loading-spinner"></div>
      </div>
    );
  }
};

export default Overview;
