import React from 'react';
import {useContext} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import logo from './logo.svg';
import './App.css';
import Login from "./components/prelogin/Login/Login";
import AuthContext from './store/auth-context';
import Main from "./components/postlogin/Main/Main";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import Registration from "./components/prelogin/Registration/Registration";
import ForgotPasswordStep1 from "./components/prelogin/ForgotPassword/ForgotPasswordStep1/ForgotPasswordStep1";
import CompanyRegistration from "./components/prelogin/CompanyRegistration/CompanyRegistration";
import ForgotPasswordStep2 from './components/prelogin/ForgotPassword/ForgotPasswordStep2/ForgotPasswordStep2';
import ForgotPasswordStep3 from './components/prelogin/ForgotPassword/ForgotPasswordStep3/ForgotPasswordStep3';
import ForgotPasswordStep4 from './components/prelogin/ForgotPassword/ForgotPasswordStep4/ForgotPasswordStep4';
import Terms from './terms';
import Privacy from './privacy';
import ApiData from './components/postlogin/chip/ApiData/ApiData';

//test

//<Route path="*" element={<NoMatch />} />

function App() {
    const authCtx = useContext(AuthContext);

    return (
      <>
        <Routes>

            /* Define root path to go to either post login or login */
            <Route path='/' element={!authCtx.isLoggedIn ? <Navigate to='/login' replace/> :
                <Navigate to='/admin' replace/>}/>
            <Route path='/login' element={!authCtx.isLoggedIn ? <Login/> : <Navigate to='/admin' replace/>}/>
            <Route path='/register' element={!authCtx.isLoggedIn ? <Registration/> : <Navigate to='/admin' replace/>}/>
            <Route path='/company-registration' element={!authCtx.isLoggedIn ? <CompanyRegistration/> : <Navigate to='/admin' replace/>}/>
            <Route path='/terms-and-conditions' element={<Terms/>}/>
            <Route path='/privacy-policy' element={<Privacy/>}/>
            <Route path='/api-data-generator' element={<div><ApiData/></div>}/>
            <Route path='/forgot-password'>
                <Route index element={<Navigate to='forgot-password-step1' replace/>} />
                <Route path="forgot-password-step1" element={<ForgotPasswordStep1 />} />
                <Route path="forgot-password-step2" element={<ForgotPasswordStep2 />} />
                <Route path="forgot-password-step3/:token" element={<ForgotPasswordStep3 />} />
                <Route path="forgot-password-step4" element={<ForgotPasswordStep4 />} />
            </Route>

            /* all the post login routes go below */
            <Route path='/admin' element={<ProtectedRoute />}>
                <Route index element={<Navigate to='session' replace/>} />
                {/*<Route path="dashboard" element={<Main section="dashboard" page="dashboard" />} /> />*/}
                <Route path="dataset">
                    <Route index element={<Navigate to='store' replace/>} />
                    {
                      
                        <Route path="collect" element={<Main section="dataset" page="dataset/collect" />} />
                      
                    }

                    <Route path="store" element={<Main section="dataset" page="dataset/store" />} />
                </Route>
                <Route path="session">
                    <Route index element={<Navigate to='overview' replace/>} />

                    <Route path="setup">
                        <Route index element={<Main section="session" page="session/setup/" />}/>
                    </Route>

                    <Route path="overview" element={<Main section="session" page="session/overview" />}/>

                    <Route path="monitor">
                        <Route index element={<Main section="session" page="session/monitor/" />}/>
                    </Route>
                    <Route path="review">
                        <Route index element={<Main section="session" page="session/review/" />}/>
                    </Route>
                </Route>

                <Route path="test">
                    <Route index element={<Navigate to='overview' replace/>} />

                    <Route path="setup">
                        <Route index element={<Main section="test" page="test/setup/" />}/>
                    </Route>

                    <Route path="overview" element={<Main section="test" page="test/overview" />}/>

                    <Route path="monitor">
                        <Route index element={<Main section="test" page="test/monitor/" />}/>
                    </Route>
                    <Route path="review">
                        <Route index element={<Main section="test" page="test/review/" />}/>
                    </Route>

                    <Route path="command-sequence">
                        <Route index element={<Main section="test" page="test/command-sequence/" />}/>
                    </Route>
                    <Route path="api-data">
                        <Route index element={<Main section="test" page="test/api-data/" />}/>
                    </Route>
                </Route>

                <Route path="support">
                    <Route index element={<Navigate to='request' replace/>} />
                    <Route path='request' element={<Main section="support" page="support/request/" />}/>
                    <Route path="request-sent" element={<Main section="support" page="support/request-sent/" />}/>
                </Route>

                <Route path="notifications" element={<Main section="notifications" page="notifications" />} />

                <Route path="output">
                    <Route index element={<Navigate to='session' replace/>} />
                    <Route path="session" element={<Main section="output"  page="output/session" />}/>
                    <Route path="test" element={<Main section="output" page="output/test" />}/>
                </Route>
            </Route>
            /* end of post login routes */

            /* catch all route, if nothing matches go to home screen */
            <Route path="*" element={<Navigate to='/' replace />}/>
        </Routes>
        <ToastContainer bodyClassName="toastBody"/>
      </>
    );
}

export default App;
