import React, {ReactNode, useContext} from "react";
import {
    Navigate,
    Outlet,
} from 'react-router-dom';
import Main from "../postlogin/Main/Main";
import AuthContext from "../../store/auth-context";
import {Path} from "@remix-run/router/history";

interface ProtectedRouteProps {
    redirectPath?:Partial<Path>,
    children?: ReactNode
}

const ProtectedRoute = (props:ProtectedRouteProps) => {
    const authCtx = useContext(AuthContext);
    if (!authCtx.isLoggedIn) {
        let redirectPath:Partial<Path> = (props.redirectPath ? props.redirectPath:'/login') as Partial<Path>;
        return <>{
            <Navigate to={redirectPath} replace />
        }</>
    }
    return <>{
        props.children ? props.children : <Outlet />
    }</>

};

export default ProtectedRoute;
