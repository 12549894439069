import React, { useContext, useState, useRef } from "react";
import { TStoreUploadModal } from "../Store.types";

import { UploadModalContext } from "../Store";

const UploadModal = (props: TStoreUploadModal) => {
  //===============================
  //STATE
  //===============================
  const [name, setName] = useState("");
  const [path, setPath] = useState("");
  const [file, setFile] = useState("");
  const [files, setFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [fileType, setFileType] = useState("");
  const fileInputRef = useRef(null);
  const modalContext = useContext(UploadModalContext);

  //===============================
  //HANDLERS
  //===============================
  console.log(modalContext.mode, modalContext.datasetType);
  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file || files.length > 0) {
      modalContext.handleUploadData({ path, file, files, name, fileName, fileType });
    }
  };

  const handleFileSelect = (event: any) => {
    const file = event.target.files[0];
    if (file) {
      setFile(file);
      setFileName(file.name);
      setFileType(file.type);
    }
  };

  const handleFolderSelect = (event: any) =>{
    const files = event.target.files;
    if (!files || files.length === 0) {
      return;
    }
    setFiles(files);
    const file = files[0];
    const firstSlashIndex = file.webkitRelativePath.indexOf('/');
      if (firstSlashIndex !== -1) {
          let extractedFolderName = file.webkitRelativePath.substring(0, firstSlashIndex);
          setFileName(extractedFolderName);
      }
  }

  return (
    <div className="modalOverlay">
      <div className="modalWrapper bgWhite">
        <div className="modalHeadTransp">
          <h3 className="modalheader">
            Upload {props.path.replace("_", " ")} Data
          </h3>
        </div>
        <div className="modalWrapperBody">
          <form onSubmit={handleSubmit}>
            <div className="width70 widthAuto">
              {/*
                          <div className="inpWithLabel m-b-15">
                              <label htmlFor="name" className="inplabel">Folder Name <span className="txtRed">*</span></label>
                              <input
                                type="text"
                                value={name}
                                onChange={(e)=>setName(e.target.value)}
                                className="borderInp" />
                          </div>
                          */}

              <div className="inpWithLabel m-b-15">
                {/*
                                <label htmlFor="name" className="inplabel">Path<span className="txtRed">*</span></label>
                                <select
                                  value={path}
                                  onChange={(e)=>setPath(e.target.value)}
                                  className="selectWrap">
                                    <option value="Input">Input</option>
                                    <option value="Noise">Noise</option>
                                    <option value="Hit_Rate">Hit Rate</option>
                                    <option value="False_Alarm">False_Alarm</option>
                                    <option value="Detections">Detections</option>
                                    <option value="Weights">Weights</option>
                                </select>
                                */}
              </div>
              <div className="inpWithLabel m-b-15">
                <label htmlFor="name" className="inplabel">
                   {true  ? "Folder" : "Zip file"}
                  <span className="txtRed">*</span>
                </label>
                {
                  true
                    //@ts-ignore
                    ?<input ref={fileInputRef} type="file" directory="" webkitdirectory="" onChange={handleFolderSelect} style={{ display: "none" }}/>
                    :<input
                        ref={fileInputRef}
                        type="file"
                        accept=".zip,application/zip,application/x-zip,application/x-zip-compressed"
                        onChange={handleFileSelect}
                        style={{ display: "none" }}
                      />
                }
                {fileName ? (
                  <p>{fileName}</p>
                ) : (
                  <button
                    onClick={() =>
                      //@ts-ignore
                      fileInputRef.current.click()
                    }
                  >
                    {true ? "Select Folder" : "Select Zip File"}
                  </button>
                )}
              </div>
            </div>
            <div className="gap30"></div>
            <div className="customColumn-12 text-center">
              <button
                onClick={() => {
                  modalContext.setUploadModal(false);
                }}
                className="btnBlack btnCustom modalButton"
              >
                Cancel
              </button>
              <button type="submit" className="btnYellow btnCustom modalButton">
                Save
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UploadModal;
