import React from "react";
import { TWizardNormalViewProps } from "./WizardNormalView.types";
import './WizardNormalView.css'


const WizardNormalView = (props: TWizardNormalViewProps) => {
    const content = props.body;
    return (
       content
    )
}

export default WizardNormalView;
