import {TWizardNavigationTabItems} from "./WizardNavigation/WizardNavigation.types";
import {TWizardBody} from "./WizardBody/WizardBody.types";
export enum TWizardBodyType  {
    normal,
    tab
}
export type TWizardStateProps = {
    header:Array<TWizardNavigationTabItems>,
    body:Array<TWizardBody>,
    activeIndex?:number
};

export type TWizardDispatchProps = {};

export type TWizardProps = TWizardStateProps & TWizardDispatchProps;

export type TWizardHook = {};
