import React, { useEffect, useState } from "react";
import { TMonitorProps } from "./Monitor.types";
import Wizard from "../../common/Wizard/Wizard";
import { WizardNavigationTabStates } from "../../common/Wizard/WizardNavigation/WizardNavigation.types";
import { TWizardBodyType } from "../../common/Wizard/Wizard.types";
import { toast } from "react-toastify";
import checkedGreen from "../../../../assets/images/checked-green-fill.svg";
import playIcon from "../../../../assets/images/play-white.svg";
import TabView from "../../common/TabView/TabView";
import ConsoleWindow from "../../common/ConsoleWindow/ConsoleWindow";
import CodeEditor from "../../common/CodeEditor/CodeEditor";
import clockIcon from "../../../../assets/images/clock-white.svg";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import saveIcon from "../../../../assets/images/save.svg";
import deleteIconWhite from "../../../../assets/images/trash-white.svg";
import crossIcon from "../../../../assets/images/cross-white.svg";
import LaunchingModal from "../Setup/LaunchingModal/LaunchingModal";
import DeleteModal from "../../common/DeleteModal/DeleteModal";

import LineGraphCarousel from "../LineGraphCarousel/LineGraphCarousel";

import sessionsApi from "../../../../services/sessions";
import useApi from "../../../../services/Base/useApi";
import { useNavigate, useSearchParams } from "react-router-dom";

import {
  millisToTimeString,
  sortTSDKStats,
  getDuration,
} from "../../../../utils/helpers";

const Monitor = (props: TMonitorProps) => {
  //===================================
  //STATE
  //===================================
  const [deleteModal, setDeleteModal] = useState(false);
  const [sessionId, setSessionId] = useState("");
  const [loading, setLoading] = useState(true);
  const [activeIndex, setActiveIndex] = useState(0);
  const [monitorSessions, setMonitorSessions] = useState([]);
  const [sessionData, setSessionData] = useState<any>({
    dakTime: { start: new Date(), end: new Date() },
  });
  const [sessionLogs, setSessionLogs] = useState({
    dak_progress: "",
    dak_logs: "",
    tsdk_logs: "",
    isdk_progress: "",
  });
  const [tsdkStats, setTsdkStats] = useState<any>({});

  //===================================
  //HOOKS
  //===================================
  const getSessionDataApi = useApi(sessionsApi.getMonitorSession);
  const getMonitorSessionsApi = useApi(sessionsApi.getMonitorSessions);
  const deleteSessionApi = useApi(sessionsApi.deleteReviewSession);

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  useEffect(() => {
    if (sessionId) {
      setLoading(true);
      getSessionDataApi.request(sessionId);
    }
  }, [sessionId]);

  useEffect(() => {
    getMonitorSessionsApi.request();
  }, []);

  useEffect(() => {
    //set data results
    if (getSessionDataApi.error) {
      toast.error("Session not found.");
      navigate("/admin/session/overview");
    } else if (
      getSessionDataApi.loading === false &&
      !getSessionDataApi.error &&
      getSessionDataApi.data
    ) {
      setSessionData(getSessionDataApi.data["session"]);
      setSessionLogs(getSessionDataApi.data["data"]);
      setTsdkStats(
        sortTSDKStats(getSessionDataApi.data["session"]["outputStats"])
      );
      findActiveIndex(getSessionDataApi.data["session"]);
      setLoading(false);
    }
  }, [
    getSessionDataApi.loading,
    getSessionDataApi.error,
    getSessionDataApi.data,
  ]);

  useEffect(() => {
    //set setup sessions dropdown options
    if (
      getMonitorSessionsApi.loading === false &&
      !getMonitorSessionsApi.error &&
      getMonitorSessionsApi.data
    ) {
      setMonitorSessions(getMonitorSessionsApi.data);
      if (searchParams.get("id")) {
        //@ts-ignore
        setSessionId(searchParams.get("id"));
      } else if (getMonitorSessionsApi.data[0]) {
        setSessionId(getMonitorSessionsApi.data[0]["_id"]);
        navigate(
          `/admin/session/monitor?id=${getMonitorSessionsApi.data[0]["_id"]}`
        );
      } else {
        setLoading(false);
      }
    }
  }, [
    getMonitorSessionsApi.loading,
    getMonitorSessionsApi.error,
    getMonitorSessionsApi.data,
  ]);

  //===================================
  //HANDLERS
  //===================================

  useEffect(() => {
    if (deleteSessionApi.loading === false) {
      if (deleteSessionApi.error) {
        toast.error(deleteSessionApi.error);
        deleteSessionApi.clearError();
      } else {
        toast.success("Session deleted");
        navigate("/admin/session/overview");
      }
    }
  }, [deleteSessionApi.loading, deleteSessionApi.error, deleteSessionApi.data]);

  // delete session
  const deleteSession = () => {
    deleteSessionApi.request(sessionId, sessionData.name);
  };

  const findActiveIndex = (session: any) => {
    const { dakStatus, tsdkStatus, isdkStatus } = session;
    let index = 0;
    if (tsdkStatus === 1 || tsdkStatus === 3) {
      index = 1;
    } else if (isdkStatus > 0) {
      index = 2;
    }
    setActiveIndex(index);
  };

  const wizardNavigationTabState = (status: number) => {
    switch (status) {
      case 0:
        return WizardNavigationTabStates.default;
      case 1:
        return WizardNavigationTabStates.current;
      case 2:
        return WizardNavigationTabStates.completed;
      case 3:
        return WizardNavigationTabStates.failed;
      default:
        return WizardNavigationTabStates.default;
    }
  };

  const items = [
    {
      state: wizardNavigationTabState(sessionData.dakStatus),
      title: "<b>Augment</b>",
      jump: false,
    },
    {
      state: wizardNavigationTabState(sessionData.tsdkStatus),
      title: "<b>Train </b>",
      jump: false,
    },
  ];

  if (sessionData.runIsdk) {
    items.push({
      state: wizardNavigationTabState(sessionData.isdkStatus),
      title: "<b>Test</b>",
      jump: false,
    });
  }

  const getFirstStepTabView = () => {
    const headers = [
      {
        title: "Status",
      },
      {
        title: "Console",
      },
    ];

    const percentage =
      sessionData.dakStatus === 2 || sessionData.dakStatus === 3
        ? 100
        : parseInt(sessionLogs.dak_progress);
    let logs =
      sessionData.dakConfig.NC == "1" ? "CLEANING NOISE DATA ... \n" : "";
    logs = logs + sessionLogs.dak_logs.replace(/\\n/g, "\n");
    let timeElapsed = getDuration(
      sessionData.dakStartTime,
      sessionData.dakEndTime
    );

    const body = [
      <div className="prgHolder">
        <div className="prgrsWrap">
          <p className="prgTxt">Processing ..</p>
          <div className="prgChartWrap" style={{ width: 200, height: 200 }}>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              strokeWidth={13}
              styles={buildStyles({
                textColor: "#FFC300",
                pathColor: "#FFC300",
                trailColor: "#989898",
              })}
            />
          </div>
        </div>
        <a href="#" className="bigBadge badgeBlue m-t-50">
          <img src={clockIcon} alt="clock" />
          Time Elapsed : {millisToTimeString(timeElapsed)}
        </a>
      </div>,
      <ConsoleWindow text={logs} />,
    ];

    const { dakStatus, tsdkStatus } = sessionData;

    return (
      <TabView
        title="Step 1: Augment"
        header={headers}
        body={body}
        onNext={
          dakStatus === 2 && tsdkStatus >= 1
            ? () => {
                setActiveIndex(1);
              }
            : null
        }
      />
    );
  };

  const getSecondStepTabView = () => {
    const headers = [
      {
        title: "Metrics",
      },
      // {
      //     title: "Console"
      // }
    ];

    const logs = sessionLogs.tsdk_logs.replace(/\\n/g, "\n");
    let timeElapsed = getDuration(
      sessionData.tsdkStartTime,
      sessionData.tsdkEndTime
    );

    const body = [
      <div className="prgHolder pt-20">
        {/* <CodeEditor code="{foo: 'bar'}"/> */}
        <div>
          <LineGraphCarousel data={tsdkStats} />
          <a href="#" className="bigBadge badgeBlue m-t-50">
            <img src={clockIcon} alt="clock" />
            Time Elapsed : {millisToTimeString(timeElapsed)}
          </a>
        </div>

        {/*  <p className="prgTxt">Post processing ..</p>
                  <div className="prgChartWrap" style={{ width: 200, height: 200 }}>
                      <CircularProgressbar value={percentage} text={`${percentage}%`}
                      strokeWidth={13}
                      styles={buildStyles({
                          textColor: "#FFC300",
                          pathColor: "#FFC300",
                          trailColor: "#989898"
                      })}
                      />
                  </div> */}
      </div>,
      // <ConsoleWindow text={logs}/>,
    ];

    const { tsdkStatus, isdkStatus } = sessionData;

    return (
      <TabView
        title="Step 2: Train"
        header={headers}
        body={body}
        onBack={() => setActiveIndex(0)}
        onNext={
          tsdkStatus === 2 && isdkStatus >= 1
            ? () => {
                setActiveIndex(2);
              }
            : null
        }
      />
    );
  };

  const getThirdStepTabView = () => {
    const headers = [
      {
        title: "Status",
      },
    ];

    let percentage =
      sessionData.isdkStatus === 2 || sessionData.isdkStatus === 3
        ? 100
        : Math.floor(parseInt(sessionLogs.isdk_progress) / 10 - 0.1);
    percentage = percentage < 0 ? 0 : percentage;
    let timeElapsed = getDuration(
      sessionData.isdkStartTime,
      sessionData.isdkEndTime
    );

    const body = [
      <div className="prgHolder">
        <div className="prgrsWrap">
          <p className="prgTxt">Processing ..</p>
          <div className="prgChartWrap" style={{ width: 200, height: 200 }}>
            <CircularProgressbar
              value={percentage}
              text={`${percentage}%`}
              strokeWidth={13}
              styles={buildStyles({
                textColor: "#FFC300",
                pathColor: "#FFC300",
                trailColor: "#989898",
              })}
            />
          </div>
        </div>
        <a href="#" className="bigBadge badgeBlue m-t-50">
          <img src={clockIcon} alt="clock" />
          Time Elapsed : {millisToTimeString(timeElapsed)}
        </a>
      </div>,
    ];

    return (
      <TabView
        title="Step 3: Test"
        header={headers}
        body={body}
        onBack={() => setActiveIndex(1)}
      />
    );
  };

  let totalBody: any = [];

  if (sessionData.status === 3) {
    const firstStepBody = {
      type: TWizardBodyType.tab,
      content: getFirstStepTabView(),
    };
    const secondStepBody = {
      type: TWizardBodyType.tab,
      content: getSecondStepTabView(),
    };
    const thirdStepBody = {
      type: TWizardBodyType.tab,
      content: getThirdStepTabView(),
    };
    totalBody = [firstStepBody, secondStepBody, thirdStepBody];
  }

  if (sessionId) {
    return (
      <div>
        <div className="sectionHead">
          <div className="flex-align-center">
            <h2 className="sectionHeading">Session : </h2>
            <select
              onChange={(e) => {
                setSessionId(e.target.value);
                navigate(`/admin/session/monitor?id=${e.target.value}`);
              }}
              className="customSelect m-l-10"
              name="sessionName"
              id=""
            >
              {monitorSessions.map((session: { _id: string; name: string }) => (
                <option
                  key={session._id}
                  value={session._id}
                  selected={session._id === sessionId}
                >
                  {session.name}
                </option>
              ))}
            </select>
          </div>
          <div className="btnHolder flex-align-center">
            <a
              onClick={() => setDeleteModal(true)}
              className="btnCustom btnRed"
            >
              <img src={deleteIconWhite} alt="delete" />
              Delete
            </a>
          </div>
        </div>
        {!loading ? (
          sessionData.status === 3 ? (
            <>
              <Wizard
                header={items}
                body={totalBody}
                activeIndex={activeIndex}
              />
              {deleteModal && (
                <DeleteModal
                  title="Session"
                  subtitle="Are you sure you want to delete this session? Doing this will permenatly destroy your progress."
                  onCancel={() => setDeleteModal(false)}
                  onDelete={deleteSession}
                />
              )}
            </>
          ) : (
            <>
              <LaunchingModal
                title="Downloading Files"
                subtitle={
                  "Your session is currently downloading files, this will take a few minutes."
                }
              />
              {deleteModal && (
                <DeleteModal
                  title="Session"
                  subtitle="Are you sure you want to delete this session? Doing this will permenatly destroy your progress."
                  onCancel={() => setDeleteModal(false)}
                  onDelete={deleteSession}
                />
              )}
            </>
          )
        ) : (
          <div className="loading">
            <div className="loading-spinner"></div>
          </div>
        )}
      </div>
    );
  } else if (!loading) {
    return (
      <div className="no-items">
        <h2>No Sessions in Monitor</h2>
        <p>Sessions that are in monitor will appear here.</p>
      </div>
    );
  } else {
    return <div></div>;
  }
};

export default Monitor;
