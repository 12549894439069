import React, { createContext, useState, useRef, useEffect } from "react";
import FileUploadButtonProps from "../../common/FileUploadButton/FileUploadButton";
import { toast } from "react-toastify";
import DataTable from "react-data-table-component";
import trashGreyIcon from "../../../../assets/images/trash-grey-bordered.svg";
import { DTStyles } from "../../common/datatableStyle/DatatableStyle";
import SelectCommandsModal from "./SelectCommandsModal";
import SelectKeywordModal from "./SelectKeywordModal";
import testsApi from "../../../../services/tests";
import sessionsApi from "../../../../services/sessions";
import dataSourcesApi from "../../../../services/data-sources";
import useApi from "../../../../services/Base/useApi";
import downloadIcon from "../../../../assets/images/download-white.svg";

interface ISequence {
  id: number;
  keyword: any;
  commands: Array<any>;
}

export const CommandSequenceContext = createContext({
  keywordModal: false,
  commandsModal: false,
  keywords: [],
  commands: [],
  selectedKeyword: "",
  selectedCommands: [],
  onSelectKeyword: (keyword: any) => {},
  onSelectCommand: (command: any) => {},
  setKeywordModal: (state: boolean) => {},
  setCommandsModal: (state: boolean) => {},
  onCreateSequence: () => {},
  clear: () => {},
});

const CommandSequence = () => {
  //==============================
  //STATE
  //==============================
  const [loading, setLoading] = useState<boolean>(true);
  const [generating,setGenerating] = useState<boolean>(false);
  const [session, setSession] = useState<{path: string, mode: string}>({path: "", mode: ""});
  const [ini, setIni] = useState<string>("");
  const [classes, setClasses] = useState<Array<any>>([]);
  const [keywords, setKeywords] = useState<Array<any>>([]);
  const [commands, setCommands] = useState<Array<any>>([]);
  const [sequences, setSequences] = useState<Array<ISequence>>([]);
  const [selectedKeyword, setSelectedKeyword] = useState<any>("");
  const [selectedCommands, setSelectedCommands] = useState<Array<any>>([]);
  const [keywordModal, setKeywordModal] = useState<boolean>(false);
  const [commandsModal, setCommandsModal] = useState<boolean>(false);
  const [threshold, setThreshold] = useState<string>("0x6E");
  const [sessions, setSessions] = useState<Array<{name: string, mode: string}>>([]);
  const [output, setOutput] = useState<string>("");
  //==============================
  //HOOKS
  //==============================
  const getSessionDataApi = useApi(sessionsApi.getCompletedSessions);
  const getIniFileApi = useApi(dataSourcesApi.getIniFile);
  const generateCommandSequencesApi = useApi(testsApi.postGenerateCommandSequence);
//   const fileInput = useRef<HTMLInputElement>(null);

  useEffect(()=>{
    setLoading(true);
    getSessionDataApi.request()
  },[]);

  useEffect(() => {
    if (
      !getSessionDataApi.error &&
      getSessionDataApi.loading == false &&
      getSessionDataApi.data
    ) {
      setSessions(getSessionDataApi.data);
    }
     setLoading(false);
  }, [
    getSessionDataApi.error,
    getSessionDataApi.loading,
    getSessionDataApi.data,
  ]);

  useEffect(() => {
    if (getIniFileApi.error){
        toast.error("No weights were found for this session")
        getIniFileApi.clearError()
    }else if (
      !getIniFileApi.error &&
      getIniFileApi.loading == false &&
      getIniFileApi.data
    ) {
      setIni(getIniFileApi.data);
      parseIniFile(getIniFileApi.data);
    }
  }, [getIniFileApi.error, getIniFileApi.loading, getIniFileApi.data]);


  useEffect(() => {
    if (generateCommandSequencesApi.error){
        toast.error("There was an error generating your command sequences")
        generateCommandSequencesApi.clearError()
        setGenerating(false)
    }else if (
      !generateCommandSequencesApi.error &&
      generateCommandSequencesApi.loading == false &&
      generateCommandSequencesApi.data
    ) {
        setOutput(
            "data:application/zip;base64,"+generateCommandSequencesApi.data['rtl']);
        generateCommandSequencesApi.clearData()
        setGenerating(false)
        toast.success("Command sequence created")
    }
  }, [generateCommandSequencesApi.error, generateCommandSequencesApi.loading, generateCommandSequencesApi.data]);

  //==============================
  //HANDLERS
  //==============================
  const getSessionIniFile = (path: string, mode: string) => {
    setSession({path, mode})
    getIniFileApi.request(path, mode);
  };

  const parseIniFile = (ini: string) => {
        const lines = ini.split(/\r\n|\n/);
        const pattern = /UNKNOWN|BKG/;
        let tempClasses: Array<any> = []

        lines.map((line: string) => {
            if (line.includes("class")){
                const className = line.split("=")[1];
                if (!pattern.test(className)){
                    tempClasses.push({
                      name: line.split("=")[1],
                      number: line.split("=")[0].split("class")[1]
                    })
                }
            }else if(line.includes("Frame2_Threshold")){
                setThreshold(line.split("=")[1]);
            }
        });

        setClasses(tempClasses);
  }

  // const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //     const file = event.target.files?.[0];
  //     if (file) {
  //         setFile(file.name);
  //         const reader = new FileReader();

  //         reader.onload = (e: ProgressEvent<FileReader>) => {
  //             const content = e.target?.result as string;

  //             if (content){
  //                 const lines = content.split(/\r\n|\n/);
  //                 const pattern = /UNKNOWN|BKG/;
  //                 let tempClasses: Array<string> = []

  //                 lines.map((line: string) => {
  //                     if (line.includes("class")){
  //                         const className = line.split("=")[1];
  //                         if (!pattern.test(className)){
  //                             tempClasses.push(line.split("=")[1])
  //                         }
  //                     }else if(line.includes("Frame2_Threshold")){
  //                         setThreshold(line.split("=")[1]);
  //                     }
  //                 });

  //                 setClasses(tempClasses);
  //             }
  //         };

  //         reader.onerror = () => {
  //             toast.error("Error reading file. Please try again.")
  //         };

  //             // Read the file as a text string
  //             reader.readAsText(file);
  //     }
  // }

  const downloadBase64 = (data: string) =>{
    const link = document.createElement('a');
    link.href = data;
    link.setAttribute('download', `${session.path}_command_sequence_rtl.zip`); //or whatever filename you want
    document.body.appendChild(link);
    link.click();
  }

  const downloadFile = (
    content: string,
    fileName: string,
    contentType: string
  ) => {
    const file = new Blob([content], { type: contentType });

    const downloadLink = document.createElement("a");

    downloadLink.href = URL.createObjectURL(file);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);

    downloadLink.click();

    document.body.removeChild(downloadLink);
  };

  const generateCommandSequences = () => {
    if (sequences.length <= 0){
      toast.error("At least one sequence is required");
      return;
    }
    setOutput("")
    setGenerating(true);
    const { used, unused } = seperateCommands();
    let fileContent = "";
    //threshold
    fileContent +=
      "[Threshold]\n" +
      "Frame1_Threshold=0x40\n" +
      "Frame2_Threshold=" +
      threshold +
      "\n\n";

    //group1
    fileContent += "[Group1]\n" + "Eval_block=0x1\n" + "Eval_win=0x1\n";
    keywords.map((keyword: any, index: number) => {
      let number = index + 1;
      fileContent +=
        "[Out" + number + "]\n" + "class" + keyword.number + "=" + keyword.name + "\n";
    });

    //group2
    fileContent += "\n[Group2]\n" + "Eval_block=0x0\n" + "Eval_win=0x0\n";
    used.map((keyword: any, index: number) => {
      let number = index + 9;
      fileContent +=
        "[Out" + number + "]\n" + "class" + keyword.number + "=" + keyword.name + "\n";
    });

    //group3
    fileContent += "\n[Group3]\n" + "Eval_block=0x2\n" + "Eval_win=0x1\n";
    unused.map((keyword: any, index: number) => {
      let number = index + 49;
      fileContent +=
        "[Out" + number + "]\n" + "class" + keyword.number + "=" + keyword.name + "\n";
    });

    //group4
    fileContent +=
      "\n[Group4]\n" + "[Out63]\n" + "class" + (classes.length + 1) + "=UNKNOWN\n";
    fileContent += "[Out64]\n" + "class" + (classes.length + 2) + "=BKG\n";

    //DE
    fileContent += "\n[DYNEF]\n" + "DE=1\n";

    //Sequences
    fileContent += "\n[CMD_SEQUENCE]\n";
    sequences.map((sequence: ISequence, index: number) => {
      fileContent +=
        "SEQ" +
        (index + 1) +
        "=" +
        sequence.keyword.name +
        "," +
        sequence.commands.map((item:any) => item.name).join(',') +
        "\n";
    });

    console.log(fileContent)
    // // let filename = `${file.replace('.ini','').replace('.txt','')}_command_sequence.ini`;
    generateCommandSequencesApi.request(session.path, session.mode, {ini: fileContent});

    
  };

  const seperateCommands = (): {
    used: Array<any>;
    unused: Array<any>;
  } => {
    let used: any[] = [];
    let unused: any[] = [];

    sequences.map((sequence: ISequence) => {
      //@ts-ignore
      sequence.commands.map((command: string) => {
        if (!used.includes(command)) {
          used.push(command);
        }
      });
    });

    commands.map((command: string) => {
      if (!used.includes(command)) {
        unused.push(command);
      }
    });

    return { used, unused };
  };

  const onSelectKeyword = (keyword: string) => {
    setSelectedKeyword(keyword);
  };

  const onSelectKeywords = (keyword: string) => {
    const indexOfKeyword = keywords.indexOf(keyword);

    if (indexOfKeyword === -1) {
      setKeywords((prevKeywords) => [...prevKeywords, keyword]);
    } else {
      const newKeywords = keywords.filter((item, i) => i !== indexOfKeyword);
      setKeywords(newKeywords);
    }
  };

  const onSaveKeywords = () => {
    if (keywords.length > 0) {
      let tempCommands: Array<any> = [];

      classes.map((c: string) => {
        if (!keywords.includes(c)) {
          tempCommands.push(c);
        }
      });
      setCommands(tempCommands);
    } else {
      toast.error("Select at least one keyword.");
    }
  };

  const onSelectCommand = (command: string) => {
    const indexOfCommand = selectedCommands.indexOf(command);

    if (indexOfCommand === -1) {
      if (selectedCommands.length < 3) {
        setSelectedCommands((prevSelectedCommands) => [
          ...prevSelectedCommands,
          command,
        ]);
      }
    } else {
      const newSelectedCommands = selectedCommands.filter(
        (item, i) => i !== indexOfCommand
      );
      setSelectedCommands(newSelectedCommands);
    }
  };

  const onCreateSequence = () => {
    if (sequences.length >= 15){
      toast.error("You can only create up to 15 sequences");
      return;
    }
    const newSequence = {
      id: sequences.length,
      keyword: selectedKeyword,
      commands: selectedCommands,
    };

    console.log(newSequence)

    setSequences((prevSequences) => [...prevSequences, newSequence]);

    setSelectedKeyword("");
    setSelectedCommands([]);
  };

  const onDelete = (id: number) => {
    const newSequences = sequences.filter((item, i) => item.id !== id);
    setSequences(newSequences);
  };

  const clear = () => {
    setSelectedKeyword("");
    setSelectedCommands([]);
  };

  const reset = () => {
    window.location.reload();
  };

  const sequencesTable = () => {
    const columnsForTest = [
      {
        name: "Wake Word",
        selector: (row: any) => row.keyword.name,
      },
      {
        name: "Commands",
        selector: (row: any) => row.commands.map((item: any) => item.name).join(', '),
      },
      {
        name: "Delete",
        button: true,
        cell: (row: any) => (
          <div className="tblIconUtlWrap">
            <button
              onClick={() => onDelete(row.id)}
              className="tblUtlIcon"
            >
              <img src={trashGreyIcon} alt="trash grey" />
            </button>
          </div>
        ),
      },
    ];

    return (
      <div>
        <DataTable
          columns={columnsForTest}
          data={sequences}
          customStyles={DTStyles}
          selectableRows={false}
        />
      </div>
    );
  };

  const downloadButton = () => {
    if(generating){
      return (
        <h5 style={{ color: "#777", marginTop: 5, textAlign: 'center' }}>
            Creating command sequences. Please do not close or refresh
            the page, doing so will stop your progress.
        </h5>
      )
    }else if(output){
      return (
        <div className="flex-justify-center btnWrap statusbtnWrap">
                <a
                  href={output}
                  download={`${session.path}_command_sequence_rtl.zip`}
                  className="btnCustom btnfadeBlue btnBig"
                  >
                  <img src={downloadIcon} alt="doanload" />
                  Download
                </a>
        </div>
      )
    }else{
      return <p></p>
    }
  }

  const contextValue = {
    keywordModal: keywordModal,
    commandsModal: commandsModal,
    keywords: keywords,
    commands: commands,
    selectedKeyword: selectedKeyword,
    selectedCommands: selectedCommands,
    onSelectKeyword: onSelectKeyword,
    onSelectCommand: onSelectCommand,
    setKeywordModal: setKeywordModal,
    setCommandsModal: setCommandsModal,
    onCreateSequence: onCreateSequence,
    clear: clear,
  };

  // const step1 = () =>{
  //     if(!session){
  //         return (
  //             <div className='no-items'>
  //                 <h3 style={{margin:0}}>Upload INI file</h3>
  //                 <p style={{marginTop:5, color:'#777'}}>To get started upload your ini file.</p>
  //                 {
  //                     file
  //                         ? <p>{file}</p>
  //                         : <FileUploadButtonProps
  //                             className="btn btnCustom btnYellow"
  //                             accept=".ini, text/plain"
  //                             handleChange={handleChange}/>
  //                 }
  //             </div>
  //         )
  //     }

  // }

  const step1 = () => {
    if (!ini) {
      return (
        <div className="no-items">
          <h3 style={{ margin: 0 }}>Select a session</h3>
          <p style={{ marginTop: 5, color: "#777" }}>
            To get started select one of your sessions.
          </p>
          {sessions.map((session: { name:string, mode: string }, index: number) => (
            <p 
                className="btn"
                onClick={()=>getSessionIniFile(session.name, session.mode)}>
                {session.name}
            </p>
          ))}
        </div>
      );
    }
  };

  const step2 = () => {
    if (ini && commands.length == 0) {
      return (
        <div className="no-items">
          <h3 style={{ margin: 0 }}>Select Wake words</h3>
          <p style={{ marginTop: 5, color: "#777" }}>
            Select all the classes you wish to classify as Wake words.
          </p>
          <div className="gap20"></div>
          {
            <div className="grid-5">
              {classes.map((word: any) => (
                <a
                  style={{ textAlign: "center" }}
                  onClick={() => onSelectKeywords(word)}
                  className={
                    keywords.includes(word) ? "btn btnBlue btnCustom" : "btn"
                  }
                >
                  {word.name}
                </a>
              ))}
            </div>
          }

          <a onClick={onSaveKeywords} className="btn btnCustom btnYellow">
            Save
          </a>
        </div>
      );
    }
  };

  const step3 = () => {
    if (commands.length > 0) {
      return (
        //@ts-ignore
        <CommandSequenceContext.Provider value={contextValue}>
          <div className="sOverviewWrap">
            <div className="sectionHead">
              <div className="flex-align-center"></div>
              <div className="btnHolder flex-align-center">
                <a
                  onClick={() => setKeywordModal(true)}
                  className="btnCustom btnYellow"
                >
                  + Create Sequence
                </a>
                <a onClick={reset} className="btnCustom btnRed">
                  Reset
                </a>
              </div>
            </div>
            <div className="tableCard">
              <div className="flex-beween-cennter tableHeadWrap">
                <h2 className="sectionHeading faddedTxt">Command Sequences</h2>
              </div>
              <div>
                {sequencesTable()}
                <div className="text-right gap30">
                  {
                    generating
                        ? <p className="m-r-10"></p>
                        : <a onClick={generateCommandSequences} className="btn">
                            Create Command Sequences
                          </a>
                  }
                </div>
              </div>
            </div>
            {keywordModal && <SelectKeywordModal />}
            {commandsModal && <SelectCommandsModal />}
          </div>
        </CommandSequenceContext.Provider>
      );
    }
  };

  if(loading){
    return (
        <div className="loading">
            <div className="loading-spinner"></div>
          </div>
    )
  }

  return (
    <div>
      {step1()}
      {step2()}
      {step3()}
      {downloadButton()}
    </div>
  );
};

export default CommandSequence;
