import React from "react";
import { TForgotPasswordStep2Props } from "./ForgotPasswordStep2.types";
import pageBackImage from "../../../../assets/images/back.svg"
import emailImage from "../../../../assets/images/email.svg"
import { Link } from "react-router-dom";

const ForgotPasswordStep2 = (props: TForgotPasswordStep2Props) => {
    return (
        <div className="authPageWrap">
            <div className="authCreateWrapper">
                <img src={emailImage} alt="icon" className="authTopLogo"/>
                <h2 className="registrationHead text-center m-b-0 greyTxt">Check your email</h2>
                <div className="gap50"></div>
                <h4 className="registrationSubhead m-0">We sent a password reset link. Look for an email from:</h4>
                <h4 className="registrationSubhead"><b>info@aondevices.com</b></h4>
                <div className="customColumn500">
                {
                  /*
                    <div className="customColumn-12 authTerms">
                        <label htmlFor="termsAcpt">Didn’t receive the email?</label>
                        <a href="#"> <b>Click to resend</b></a>
                    </div>
                  */
                }

                <div className="gap50"></div>
                <div className="customColumn-12 text-center">
                    <div className="backPageLink text-center">
                        <Link to="/login"><img src={pageBackImage} alt="icon" /> Back to log in</Link>
                    </div>
                </div>
                </div>
            </div>
        </div>
    );
}

export default ForgotPasswordStep2;
