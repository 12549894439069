import React, {useContext,useState} from "react";
import { TDeleteModalProps } from "./DeleteModal.types";


const DeleteModal = (props: TDeleteModalProps) => {
    const { onCancel, onDelete, title, subtitle, base, action } = props;

    //===============================
    //HANDLERS
    //===============================

    return (

            <div className="modalOverlay">
                <div className="modalWrapper bgWhite">
                    <div className="modalHeadTransp">
                        {
                            base
                            ? <h3 className="modalheader">{title}</h3>
                            : <h3 className="modalheader">Delete {title}?</h3>
                        }
                        
                    </div>
                    <div className="modalWrapperBody">
                        <div className="width70 widthAuto">
                        <p>{subtitle}</p>
                        </div>
                        <div className="gap30"></div>
                        <div className="customColumn-12 text-center">
                            {onCancel && <button onClick={onCancel} className="btnBlack btnCustom modalButton">Cancel</button>}
                            <button onClick={onDelete} className={ base ? "btnGreen btnCustom modalButton" : "btnRed btnCustom modalButton"}>{base ? action : "Delete"}</button>
                        </div>

                    </div>
                </div>
            </div>
    );
}

export default DeleteModal;
