import React, {createContext, useState, useEffect} from "react";
import {TWizardProps} from "./Wizard.types";
import "./Wizard.css"
import WizardBody from "./WizardBody/WizardBody";
import WizardNavigation from "./WizardNavigation/WizardNavigation";
import {WizardNavigationTabStates} from "./WizardNavigation/WizardNavigation.types";

export const WizardContext = createContext({
    activeTab:0,
    changeTab:(index:number)=>{},
    header:{},
    changeHeader:(active:number,prevItemState:WizardNavigationTabStates)=>{},
    completeTab:(index:number)=>{},
    });

const Wizard = (props: TWizardProps) => {
   const items = props.header;
   const bodies = props.body;

    let active = props.activeIndex ? props.activeIndex : 0;
    if (active >= items.length || active >= bodies.length) {
        active = 0;
    }
    const [activeTab, setActiveTab] = useState(active);
    const [header,setHeader] = useState(items);

    useEffect(()=>{
      setActiveTab(active)
    },[props.activeIndex])

    useEffect(()=>{
      setHeader(props.header)
    },[props.header])


    const changeHeader=(active:number,prevItemState?:WizardNavigationTabStates)=>{
        let newHeader=header;

        newHeader.map(function (headerItem,index){
            if(index == active)
            {
                headerItem.state=WizardNavigationTabStates.current;
            }
            else
            {
                if( headerItem.state==WizardNavigationTabStates.current)
                {
                    headerItem.state = prevItemState === undefined ? WizardNavigationTabStates.completed : prevItemState;
                }
            }
        });
        setHeader(newHeader);
    }

    const changeTab = (index: number) => {
        if (index <= items.length && index <= bodies.length) {
            setActiveTab(index);
        }
    }
    const completeTab = (index:number) =>{
        if (index <= items.length && index <= bodies.length) {

            let newHeader = header;
            newHeader[index].state = WizardNavigationTabStates.completed;

            if (index < items.length && index < bodies.length) {
                index++;
                setActiveTab(index);
                newHeader[index].state = WizardNavigationTabStates.current;
            }
        }
    }

    const contextValue = {
        activeTab: activeTab,
        changeTab: changeTab,
        header:header,
        changeHeader:changeHeader,
        completeTab:completeTab
    };


    return (
        <WizardContext.Provider value={contextValue}>
        <div className="wizardHolderWrap">
            <div className="wizardStepWrap">
                <WizardNavigation items={header}/>
            </div>
            <div className="wizardBodyWrap">
                <WizardBody body={bodies} />
            </div>
        </div>
        </WizardContext.Provider>
    )
}
export default Wizard;
